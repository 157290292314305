import axios, { AxiosResponse, AxiosError } from "axios";
const baseUrl = process.env.REACT_APP_API_BASEURL;

export const sendRequest = ({method, endpoint, callback, payload = {}, queryParams = {}, errorCallback}: {
  method: string;
  endpoint: string;
  callback: (data: AxiosResponse) => void;
  payload?: {};
  queryParams?: {};
  errorCallback?: (error: AxiosError) => void;
}) => {

  const requestConfig: {} = {
    method: method ? method : 'GET',
    baseURL: baseUrl,
    url: endpoint,
    params: queryParams,
    data: payload,
  };

  return axios(requestConfig).then(function (response: AxiosResponse) {
    callback(response);
    return response;
  }).catch(function (error) {
    // TODO handle errors 
    console.log('error in http helper', error);
    if (errorCallback) {
      errorCallback(error);
    }
  })
}
