import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { EventPageParticipantsContainer } from './containers/EventPageParticipantsContainer';
import { EventPageContainer } from './containers/EventPageContainer';
import { EventHolderContainer } from './containers/EventHolderContainer';
import { MasterPageContainer } from './containers/MasterPageContainer';
import { ProfilePageContainer } from './containers/ProfilePageContainer';
import { EventCurrentStandingsContainer } from './containers/EventCurrentStandingsContainer';
import { SponsorParticipantPageContainer } from './containers/SponsorParticipantPageContainer';
import { ParticipantConfirmEmailPageContainer } from './containers/ParticipantConfirmEmailPageContainer';
import { ContactPageContainer } from './containers/ContactPageContainer';
import { PrivacyPolicyContainer } from './containers/PrivacyPolicyContainer';
import { AboutPageContainer } from './containers/AboutPageContainer';
import { EventStatsPageContainer } from './containers/EventStatsPageContainer';
import { ParticipantSignupPageContainer } from './containers/ParticipantSignupPageContainer';
import { EventGroupPageContainer } from './containers/EventGroupPageContainer';
import { RegisterRoundsPageContainer } from './containers/RegisterRoundsPageContainer';
import { MultiParticipantSignupPageContainer } from './containers/MultiParticipantSignupPageContainer';
import { QrRequestContainer } from './containers/QrRequestContainer';
import { MultiSignupConfirmEmailPageContainer } from './containers/MultiSignupConfirmEmailPageContainer';
import { SponsorEventPageContainer } from './containers/SponsorEventPageContainer';
import { NotFoundPage } from './components/Layout/NotFoundPage';
import { SponsorTypePage } from './components/SponsorEventPage/SponsorTypePage';
import { DEFAULT_DOCUMENT_TITLE } from './variables/constants';
import i18n from './variables/i18n';
import Echo from "laravel-echo";
import { SponsorEditPageContainer } from './containers/SponsorEditPageContainer';
import { LandingPageContainer } from './containers/LandingPageContainer';
import { ScrollTop } from './components/Various/ScrollTop';
import { ErrorPage } from './components/Layout/ErrorPage';
import { FaqPage } from './components/FaqPage/FaqPage';

if (process.env.REACT_APP_WEBSOCKET_KEY) {
  window.Pusher = require("pusher-js");
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.REACT_APP_WEBSOCKET_KEY,
    wsHost: process.env.REACT_APP_WEBSOCKET_WSHOST,
    enabledTransports: ["ws", "wss"],
    wsPort: 8443,
    wssPort: 8443,
    disableStats: false,
    forceTLS: true,
    encrypted: true,
  });
}


document.title = DEFAULT_DOCUMENT_TITLE;

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<MasterPageContainer />}>
            <Route index element={<LandingPageContainer />} />
            <Route path="events" element={<EventHolderContainer />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="error-page" element={<ErrorPage />} />
            <Route path="qr/:qrHash" element={<QrRequestContainer />} />
            <Route path="qr/:qrHash/event-multi-signup" element={<MultiParticipantSignupPageContainer />} />
            <Route path="confirm/:linkHash" element={<MultiSignupConfirmEmailPageContainer />} />
            <Route path="privatlivspolitik" element={<PrivacyPolicyContainer />} />
            <Route path="about" element={<AboutPageContainer />} />
            <Route path="contact" element={<ContactPageContainer />} />
            <Route path="admin/:linkHash" element={<RegisterRoundsPageContainer />} />
            <Route path="landing" element={<LandingPageContainer />} />
            <Route path="group">
              <Route path=":groupSlug">
                <Route path=":eventSlug">
                  <Route index element={<EventPageContainer />} />
                </Route>
                <Route index element={<EventGroupPageContainer />} />
              </Route>
              <Route index element={<NotFoundPage />} />
            </Route>
            <Route path=":orgSlug">
              <Route path=":eventSlug">
                <Route path="deltagere/:participantSlug/sponsor" element={<SponsorParticipantPageContainer />} />
                <Route path="deltagere/:participantSlug" element={<ProfilePageContainer />} />
                <Route path="deltagere" element={<EventPageParticipantsContainer />} />
                <Route path="sponsor/edit/:linkHash" element={<SponsorEditPageContainer />} />
                <Route path="stats" element={<EventStatsPageContainer />} />
                <Route path="tilmelding/:linkHash" element={<ParticipantConfirmEmailPageContainer />} />
                <Route path="tilmelding" element={<ParticipantSignupPageContainer />} />
                <Route path="sponsor" element={<SponsorTypePage />} />
                <Route path="sponsor/business/:participantSlug" element={<SponsorEventPageContainer />} />
                <Route path="sponsor/business" element={<SponsorEventPageContainer />} />
                <Route path="sponsor/private" element={<SponsorEventPageContainer />} />
                <Route index element={<EventPageContainer />} />
              </Route>
              <Route index element={<NotFoundPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path=":orgSlug/:eventSlug/live" element={<EventCurrentStandingsContainer />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
