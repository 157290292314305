export const ProfileCardStat = ({ label, value }: {
  label: string,
  value: string | number | null
}) => {
  return (
    <div className="border-b border-gray-300 last:border-0 text-left">
      <div className=" text-xs md:text-sm lg:text-xs py-2 lg:py-1 xl:py-3 opacity-80 inline-block w-1/2 lg:w-9/12 xl:w-4/5 ">
        {label}
      </div>
      <div className="text-sm lg:text-xs font-medium text-right md:pt-1 md:pb-4 lg:py-1 xl:py-3 px-1 inline-block w-1/2 lg:w-3/12 xl:w-1/5">
        {value}
      </div>
    </div>
  );
}