import { useMemo, useState } from "react";
import { Button } from "../UI/Button";
import { Loading } from "../UI/Loading";
import { MultiParticipantSignupField } from "../UI/MultiParticipantSignupField";
import { InputField } from "../UI/InputField";
import { CssTriangle } from "../Various/CssTriangle";
import { Notification } from "../UI/Notification";
import { MultiSignupFormValues } from "../../../types/components/Signup";

export const MultiParticipantSignupForm = ({
    doSubmit,
    isSubmitting,
}: {
    doSubmit: (formValues: MultiSignupFormValues) => void;
    isSubmitting?: boolean;
}) => {
    const [values, setValues] = useState<MultiSignupFormValues>({ email: '', participants: [] });
    const [alreadyAddedError, setAlreadyAddedError] = useState(false);

    const submit = () => {
        doSubmit(values);
    };

    const formValid = useMemo(() => {
        return values.email.length > 0 && values.participants.length > 0;
    }, [values]);

    const addParticipant = (participant: string) => {
        setAlreadyAddedError(false);
        if (values.participants.includes(participant) || participant.length === 0) {
            return;
        }
        setValues((prev) => ({ ...prev, participants: [...prev.participants, participant] }));
    };

    const removeParticipant = (participant: string) => {
        setValues((prev) => ({ ...prev, participants: prev.participants.filter((p) => p !== participant) }));
    };

    const checkIfAlreadyAdded = (participant: string) => {
        if (values.participants.includes(participant)) {
            setAlreadyAddedError(true);
        } else {
            setAlreadyAddedError(false);
        }
    };

    const errorMessage = useMemo(() => {
        if (alreadyAddedError) {
            return "Du har allerede tilføjet denne deltager";
        }
        return null;
    }, [alreadyAddedError]);

    return (
        <form
            onSubmit={submit}
            className={`max-w-lg pt-2 ${isSubmitting ? "pointer-events-none opacity-60" : ""}`}
        >
            <p className="header-font text-lg mb-3 flex gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-primary">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>
                <span>Tilføj én eller flere deltagere</span>
            </p>
            <div className="relative">
                <MultiParticipantSignupField errorMessage={errorMessage || undefined} onChange={checkIfAlreadyAdded} onSubmit={addParticipant} />
                {values.participants.length === 0 ? (
                    <div className="absolute z-30 left-5 -bottom-7" role="tooltip">
                        <CssTriangle className="scale-50 absolute -top-3.5 z-30 left-2" color="primary" />
                        <div className="font-sans px-3 py-2 text-xs font-semibold text-white bg-primary rounded-lg shadow-md">
                            Indtast din første deltager og tryk "Tilføj"
                        </div>
                    </div>
                ) : null}
            </div>
            <div className={`mt-2 rounded bg-white px-4 py-4 shadow ${values.participants.length === 0 ? 'opacity-20' : null}`}>
                <p className="header-font text-base mb-2">Tilføjede deltagere: {values.participants.length}</p>
                {values.participants.length === 0 ? <p className="font-sans opacity-80 text-sm italic">Du har ikke tilføjet nogle deltagere endnu</p> : null}
                <ul className="flex flex-col gap-2">
                    {values.participants.map((participant, index) => (
                        <li key={index} className="flex gap-2 items-center">
                            <span className="text-sm">{participant}</span>
                            <button
                                title="Fjern deltager"
                                type="button"
                                onClick={() => removeParticipant(participant)}
                                className="text-primary hover:text-primary-dark transition-colors duration-200"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-6">
                <InputField label="Din email" type="email" name="email" onChangeMethod={(value) => setValues({ ...values, email: value as string })} />
                <Notification static className="mt-4 flex items-center gap-3" type="info">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>

                    <p className="font-sans text-sm">
                        Du kan altid komme tilbage og tilføje flere deltagere senere. Du skal blot bruge den samme email-adresse som du har brugt her.
                    </p>
                </Notification>
                <Button onClick={submit} useBtn={true} big type="button" className={`mt-8 flex gap-2 items-center ${!formValid ? 'pointer-events-none opacity-50' : null}`}>
                    {isSubmitting ? 'Sender...' : 'Send tilmelding'}
                    {isSubmitting ? <Loading widthClass="w-2" heightClass="h-6" className="ml-3 mr-1" /> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>}
                </Button>
            </div>
        </form>
    );
};