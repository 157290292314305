import { PAYMENT_METHOD_TRANSFER, PAYMENT_METHOD_MOBILEPAY, PAYMENT_METHOD_CREDIT_CARD } from '../../variables/constants';
import mobilepayLogo from '../../images/mobilepay-logo.svg';
import { InputLabel } from '../UI/InputLabel';
import { InputField } from '../UI/InputField';
import { useTranslation } from "react-i18next";

const FieldInfoBox = ({ value }: { value: string }) => {
    const { t } = useTranslation(['event_participants']);

    return <div className="text-xs px-3 py-3 rounded bg-blue-200 text-blue-800 relative overflow-hidden">
        {value === PAYMENT_METHOD_MOBILEPAY ? <img className="absolute -left-[1px] -top-5 opacity-20 z-0" src={mobilepayLogo} alt="Betal med mobile pay hos os" width="100" /> : null}
        {value === PAYMENT_METHOD_MOBILEPAY ? <span className="relative z-10">{t('event_participants:sponsor.form.mobile_pay_note')}</span> : null}
        {value === PAYMENT_METHOD_TRANSFER ? t('event_participants:sponsor.form.bank_transfer_note') : null}
        {value === PAYMENT_METHOD_CREDIT_CARD ? t('event_participants:sponsor.form.credit_card_note') : null}
    </div>
}

const PaymentMethodField = ({ value, name, label, currentValue, onChange, children }: {
    value: string,
    name: string,
    label: string,
    currentValue: string,
    onChange: (value: string) => void
    children?: React.ReactNode
}) => {
    return (
        <div className={`flex flex-row gap-1 items-center lg:mt-1 ${(currentValue !== value) ? 'opacity-70' : ''}`}>
            {children}
            <InputField
                name={name}
                className="text-center md:text-left"
                onChangeMethod={(val) => onChange(String(val))}
                type="radio"
                label={label}
                activeValue={currentValue}
                value={value}
            />
        </div>
    );
}

export const FormPaymentMethodField = ({ currentValue, onChange, allowedOptions }: {
    currentValue: string,
    allowedOptions: string[],
    onChange: (value: string) => void
}) => {
    const { t } = useTranslation(['event_participants']);

    return (
        <>
            <div className="mb-3 mt-5 md:mt-0 lg:mt-4">
                <InputLabel>{t('event_participants:sponsor.form.payment_method')}</InputLabel>
                <div className="flex flex-col lg:flex-row gap-2 mt-1 lg:gap-8">
                    {(allowedOptions.includes(PAYMENT_METHOD_MOBILEPAY)) ? <PaymentMethodField
                        name="payment_method_1"
                        onChange={(val) => onChange(val)}
                        label={t('event_participants:sponsor.form.mobile_pay')}
                        currentValue={currentValue}
                        value={PAYMENT_METHOD_MOBILEPAY}
                    >
                        <img src={mobilepayLogo} alt="Betal med mobile pay hos os" width="25" />
                    </PaymentMethodField> : null}
                    {(allowedOptions.includes(PAYMENT_METHOD_TRANSFER)) ? <PaymentMethodField
                        name="payment_method_2"
                        onChange={(val) => onChange(val)}
                        label={t('event_participants:sponsor.form.bank_transfer')}
                        currentValue={currentValue}
                        value={PAYMENT_METHOD_TRANSFER}
                    /> : null}
                    {(allowedOptions.includes(PAYMENT_METHOD_CREDIT_CARD)) ? <PaymentMethodField
                        name="payment_method_3"
                        onChange={(val) => onChange(val)}
                        label={t('event_participants:sponsor.form.credit_card')}
                        currentValue={currentValue}
                        value={PAYMENT_METHOD_CREDIT_CARD}
                    /> : null}
                </div>
            </div>
            {currentValue !== '' ? <FieldInfoBox value={currentValue} /> : null}
        </>
    );
}
