import { Container } from './Container';
import mobilepayImg from '../../images/mobilepay-btn.svg';
import lovespring from '../../images/lovespring.png';
import { useTranslation } from "react-i18next";
import { CompanyLogoWide } from '../SVG/Logos';

const FooterCol = ({ title, phone, email, cvr, website, children }: {
  title: string,
  phone?: string,
  email?: string,
  cvr?: string,
  website?: string,
  children?: React.ReactNode
}) => {
  return (
    <div className="mb-4">

      <div className="text-xs mb-3">{title}</div>
      {phone && <div className="text-xs">Tlf: {phone}</div>}
      {email && <div className="text-xs">Email: {email}</div>}
      {cvr && <div className="text-xs">CVR: {cvr}</div>}
      <a href={`https://${website}`} rel="noreferrer" target="_blank" className="text-xs block hover:underline">{website}</a>
      <div className="py-4">
        {children}
      </div>
    </div>
  );
}

export const Footer = ({ companyName, companyPhone, companyEmail, companyWebsite }: {
  companyName: string,
  companyPhone: string,
  companyEmail: string,
  companyWebsite: string
}) => {
  const { t } = useTranslation('footer');

  const extraLinks = [
    { href: "/faq", label: 'FAQ - Om løbet' },
    { href: "/privatlivspolitik", label: t('privacy_policy') },
    { href: "/#about", target: "_blank", label: t('about_us')},
    { href: "/contact", label: t('contact') },
    { href: "https://admin.init-together.dk/", label: t('log_in'), target: "_blank" },
  ]

  return (
    <>

      <footer className="pt-12 pb-5 bg-gray-900 negative-container">
        <Container>
          <div className="relative">
            <div className="pt-4 lg:grid grid-cols-2">
              <div className="flex flex-col lg:flex-row items-start justify-between gap-x-8 xl:gap-x-16">
                {companyName && <FooterCol title={companyName} phone={companyPhone} email={companyEmail} website={companyWebsite} />}
                <FooterCol title={t('company_name')} cvr="44 64 54 83" email="info@init-together.dk" website="www.init-together.dk">
                  <a href="https://www.init-together.dk" rel="noreferrer" target="_blank" className="block w-[200px]">
                    <CompanyLogoWide />
                  </a>
                </FooterCol>
                <div className="flex flex-col gap-4 mb-6">
                  <div>{t('we_work_with')}:</div>
                  <a href="https://www.lovespring.dk" rel="noreferrer" target="_blank" className="block">
                    <img src={lovespring} alt="Lovespring logo" width="200" />
                  </a>
                </div>
              </div>
              <div className="lg:text-right flex flex-col xl:flex-row gap-4 xl:gap-10 leading-none lg:items-end xl:items-start xl:justify-center mb-4">
                {extraLinks.map((link, i) => (
                  <a key={i} href={link.href} className="hover:underline">{link.label}</a>
                ))}
              </div>

            </div>
            <div className="text-center flex flex-row justify-between gap-4 items-center">
              <div className="flex flex-col items-start gap-1 mt-6">
                <span className="text-xs">{t('you_can_pay_with')}</span>
                <img className="inline-block" src={mobilepayImg} alt="Betale med Vipps MobilePay" width="100" />
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
}