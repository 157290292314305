import { RoundedImg } from '../UI/RoundedImg';

export const ProfileCardImage = ({ imgSrc, altText, children, className, anonymized }: {
    imgSrc: string | null,
    altText?: string,
    children?: React.ReactNode,
    className?: string,
    anonymized?: boolean
}) => {
    return (
        <div className={`relative overflow-hidden min-h-[100px] min-w-[100px] ` + className}>
            {imgSrc ? <RoundedImg imgSrc={imgSrc} altText={altText} className={`${anonymized ? 'blur-md' : ''}`}/> : null}
            <div className="absolute bottom-0 left-0 right-0 text-center">
                {children}
            </div>
        </div>
    )
}