import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavButton } from './NavButton';
import { NavItem } from '../../../types/Nav';

export const NavMenu = ({ open, items }: {
  open: boolean,
  items: NavItem[]
}) => {
  const [opened, setOpened] = useState(open || false);

  return (
    <nav className={`flex flex-row justify-end overflow-hidden z-10 ${opened ? 'fixed top-0 left-0 h-screen w-full bg-black/60' : ''}`}>
      {(opened && items) ? <div className="w-full" onClick={() => setOpened(false)}>
        <ul>
          {items.map((item, i) => (
            <li className="text-primary text-xl xl:text-1xl text-center border-b " key={i}>
              {(item.target && item.target === '_blank') ?
                <a className="bg-gray-100 hover:bg-gray-900 w-full py-4 px-10 block xl:py-6 hover:text-white" rel="noreferrer" href={item.href} onClick={() => setOpened(false)} target="_blank">{item.label}</a> :
                <NavLink className="w-full py-4 px-10 block bg-gray-100 hover:bg-gray-900 hover:text-white xl:py-6" onClick={() => setOpened(false)} to={item.href}>{item.label}</NavLink>
              }
            </li>
          ))}
        </ul>
      </div> : null}
      <div onClick={() => setOpened(!opened)} className={` ${opened ? 'bg-black/40' : null} relative z-10`}>
        <NavButton open={opened} />
      </div>
    </nav>
  );
}