import { Event, EventGroup } from "../../types/Event"

export const buildLink = (event: Event | EventGroup, subPath?: string) => {
    // if has events, it's an event group
    if (event.hasOwnProperty("events")) {
        const item = event as EventGroup
        return `/group/${item.slug}` + (subPath ? `/${subPath}` : "")
    }
    const item = event as Event

    return `/${item.org_slug}/${item.slug}` + (subPath ? `/${subPath}` : "")
}

export const eventSignUpLink = (event: Event | EventGroup) => {
    return buildLink(event, "tilmelding")
}

export const eventParticipantsLink = (event: Event | EventGroup) => {
    return buildLink(event, "deltagere")
}

export const eventParticipantLink = (event: Event | EventGroup, participantSlug: string) => {
    return buildLink(event, `deltagere/${participantSlug}`)
}

export const eventParticipantSponsorLink = (event: Event | EventGroup, participantSlug: string) => {
    return eventParticipantLink(event, participantSlug) + "/sponsor"
}

export const eventSponsorLink = (event: Event | EventGroup) => {
    return buildLink(event, "sponsor")
}

