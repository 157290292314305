import dayjs from "dayjs";
import { ReactComponent as LapsIcon } from './../../images/refresh.svg';
import { ReactComponent as DonationsIcon } from './../../images/donations.svg';
import { ReactComponent as RouteIcon } from './../../images/route-icon.svg';
import { useTranslation } from "react-i18next";
import { EventStatsBox as IEventStatsBox } from "../../../types/components/EventComponents";
import { FeaturedEventStat } from "./FeaturedEventStat";
import { Container } from "../UI/Container";

const GroupedStatValue = ({ icon, value }: {
  icon: React.ReactNode;
  value: string | number;
}) => {
  return (
    <div className={`bg-gray-100 rounded leading-none whitespace-nowrap py-2 px-2 flex justify-between items-center w-full text-xs text-gray-900`}>
      <div className={`flex items-center gap-1`}>
        {icon}
      </div>
      <span>{value}</span>
    </div>
  )
}

export const EventStatsBox = ({ rounds, total_meters, donations, lastUpdated, isRealTime, groups, sponsor_count, fixedLengthTotalMeters, variableCurrentDonations }: {
  rounds?: number;
  total_meters?: number;
  donations?: number;
  sponsor_count: number;
  lastUpdated?: string;
  isRealTime?: boolean;
  groups?: IEventStatsBox[],
  fixedLengthTotalMeters?: { total_meters: number, meters_progress: number },
  variableCurrentDonations?: number
}) => {
  const { t } = useTranslation('events');

  return (
    <div className={`w-full pt-12 pb-12 bg-white shadow overflow-x-scroll md:overflow-auto`}>
      <Container>
        {isRealTime ?
          (<h2 className="text-center text-2xl lg:text-4xl font-semibold mb-8 text-primary">
            {t('event_stats_box.real_time_performance')}
            {lastUpdated ? (<div className="mt-1 text-[10px] font-normal text-gray-500 italic text-center">({t('event_stats_box.last_updated')}: {dayjs(lastUpdated).format("DD-MM-YY HH:mm")})</div>) : null}
          </h2>)
          : null}

        <div className="flex flex-col md:flex-row justify-evenly gap-6 lg:gap-4">
          {donations !== undefined ? (
            <FeaturedEventStat icon="donations" value={`${new Intl.NumberFormat('da-DK', { maximumFractionDigits: 0 }).format(donations) || 0} kr`}>
              {t('event_stats_box.donations')}
            </FeaturedEventStat>
          ) : null}
          {variableCurrentDonations !== undefined ? (
            <FeaturedEventStat icon="donations" value={`${new Intl.NumberFormat('da-DK', { maximumFractionDigits: 0 }).format(variableCurrentDonations) || 0} kr`}>
              Donationer indsamlet, indtil videre <br /><small className="mt-1 font-normal inline-block leading-tight">Beløbet kan falde eller stige,<br/> alt efter deltagernes præstationer</small>
            </FeaturedEventStat>
          ) : null}
          {total_meters !== undefined ? (
            <FeaturedEventStat icon="route" value={new Intl.NumberFormat('da-DK', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 0 }).format(total_meters / 1000) || 0}>
              {t('event_stats_box.distance_ran')}
            </FeaturedEventStat>
          ) : null}
          {fixedLengthTotalMeters !== undefined ? (
            <FeaturedEventStat icon="route" value={new Intl.NumberFormat('da-DK', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 0 }).format(fixedLengthTotalMeters.meters_progress / 1000) || 0}>
              løbet ud af {new Intl.NumberFormat('da-DK', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 0 }).format(fixedLengthTotalMeters.total_meters / 1000) || 0}
            </FeaturedEventStat>
          ) : null}

          {rounds ? (
            <FeaturedEventStat icon="laps" value={rounds || 0}>
              {t('event_stats_box.laps_ran')}
            </FeaturedEventStat>
          ) : null}
          {sponsor_count ? (
            <FeaturedEventStat icon="sponsors" value={sponsor_count || 0}>
              {t('event_stats_box.sponsor_count')}
            </FeaturedEventStat>
          ) : null}
        </div>

        {(groups && groups.length > 0) ? (
          <>
            <hr className="mb-6 mt-12" />
            <h4 className="mt-1 text-center font-semibold text-lg mb-4 text-primary">{t('event_stats_box.other_groups')}</h4>
          </>
        )
          : null}

        {(groups && groups.length > 0) ? <div className="gap-x-8 gap-y-5 flex lg:grid lg:grid-cols-2 flex-col">{groups.map((item, i) => {
          return (
            <div key={i}>
              <div className="text-sm leading-none mb-1">
                {item.name}
              </div>
              <div className="flex flex-row flex-nowrap gap-1 lg:gap-2">
                <GroupedStatValue
                  value={item.rounds || 0}
                  icon={<LapsIcon className="w-[20px] h-[20px] mt-[-1px] -ml-1" />}
                />
                <GroupedStatValue
                  value={new Intl.NumberFormat('da-DK', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 0 }).format(item.total_meters / 1000) || 0}
                  icon={<RouteIcon className="w-[24px] max-h-[20px] mb-[-5px] -ml-1" />}
                />
                <GroupedStatValue
                  value={`${new Intl.NumberFormat('da-DK', { maximumFractionDigits: 0 }).format(item.donations) || 0} kr`}
                  icon={<DonationsIcon className="w-[24px] h-[15px]" />}
                />
              </div>
            </div>
          )
        })}</div>
          : null}
      </Container>
    </div>
  );
}