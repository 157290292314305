import { useMemo } from 'react';
import { STEP_DONATION_AMOUNT, STEP_CONTACT_INFO, STEP_PAYMENT_OPTIONS, STEP_SUBMIT } from '../variables/checkout_form';

export const useCheckoutFormHelpers = ({ currentStep }: {
    currentStep: string;
}) => {

    const completedSteps = useMemo(() => {
        switch (currentStep) {
            case STEP_DONATION_AMOUNT:
                return [];
            case STEP_CONTACT_INFO:
                return [STEP_DONATION_AMOUNT];
            case STEP_PAYMENT_OPTIONS:
                return [STEP_DONATION_AMOUNT, STEP_CONTACT_INFO];
            case STEP_SUBMIT:
                return [STEP_DONATION_AMOUNT, STEP_CONTACT_INFO, STEP_PAYMENT_OPTIONS];
            default:
                return [];
        }
    }, [currentStep]);

    return {
        completedSteps: completedSteps,
        startStep: STEP_DONATION_AMOUNT
    }
}