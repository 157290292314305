import { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SponsorMode } from "../variables/enum";
import { SponsorEventPrivatePage } from "../components/SponsorEventPage/SponsorEventPrivatePage";
import { SponsorEventBusinessPage } from "../components/SponsorEventPage/SponsorEventBusinessPage";
import { useEvent } from "../hooks/useEvent";
import { Loading, LoadingOverlay } from "../components/UI/Loading";
import { NotFoundPage } from "../components/Layout/NotFoundPage";
import { SponsorFormValues } from "../../types/components/SponsorParticipantPage";
import { useParticipant } from "../hooks/useParticipant";
import { useCheckoutForm } from "../hooks/useCheckoutForm";
import { SuccessSponsorPage } from "../components/Layout/SuccessSponsorPage";
import { TopBar } from "../components/Nav/TopBar";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { NOTIFICATION_TYPE_ERROR } from "../variables/constants";
import { useTranslation } from "react-i18next";
import { Notification } from "../components/UI/Notification";

export const SponsorEventPageContainer = () => {
    const { t } = useTranslation(['errors']);
    const { participantSlug } = useParams();
    const { event, eventLoaded } = useEvent();
    const { participant, participantLoaded } = useParticipant();
    const setDocumentTitle = useDocumentTitle();
    const { submitCheckoutForm, checkoutFormSuccessResponse, checkoutFormIsSubmiting, checkoutErrors, resetCheckoutErrors } = useCheckoutForm();
    const notificationMessage = useMemo(() => checkoutErrors.length > 0 ? checkoutErrors?.map(str => str).join(', ') : '', [checkoutErrors]);

    const location = useLocation();
    const sponsorMode: SponsorMode = useMemo(() => {
        const lastPath = location.pathname.split("/").pop();
        return lastPath === "private" ? SponsorMode.PRIVATE : SponsorMode.BUSINESS;
    }, [location]);

    const submitForm = (formValues: SponsorFormValues) => {
        if (!event) {
            return;
        }
        submitCheckoutForm(formValues, event);
    }

    useEffect(() => {
        if (event) {
            setDocumentTitle(`Sponsorér ${event.name}`);
        }
    }, [event, setDocumentTitle]);

    if (!eventLoaded || !participantLoaded) {
        return <Loading />
    }
    if (!event) {
        return <NotFoundPage />;
    }

    if (!participant && participantSlug) {
        return <NotFoundPage />;
    }

    if (checkoutFormSuccessResponse) {
        return <>
            <TopBar />
            <SuccessSponsorPage
                email={checkoutFormSuccessResponse.email}
                name={checkoutFormSuccessResponse.first_name}
                payment_type={checkoutFormSuccessResponse.payment_type}
                event={event}
            />
        </>
    }

    return (
        <>
            {checkoutFormIsSubmiting ? <LoadingOverlay /> : null}
            {checkoutErrors.length > 0 ? <Notification onClose={resetCheckoutErrors} type={NOTIFICATION_TYPE_ERROR} title={t('errors:an_error_happened')} text={notificationMessage} /> : null}
            {sponsorMode === SponsorMode.PRIVATE && <SponsorEventPrivatePage event={event} onSubmit={submitForm} />}
            {sponsorMode === SponsorMode.BUSINESS && <SponsorEventBusinessPage event={event} participant={participant || undefined} onSubmit={submitForm} />}
        </>
    )
}