import { Button } from "../../UI/Button";
import { Container } from "../../UI/Container";

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="bg-gradient-to-tr from-primary-900 via-primary-700 to-primary-900 py-28"
    >
      <Container>
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-normal text-3xl text-white tracking-tight sm:text-4xl">
            Skal I arrangere et løb?
          </h2>
          <p className="mt-4 text-lg text-white tracking-tight">
            Har du spørgsmål eller lyst til at lave et velgørenhedsløb, så
            kontakt os. Vi er altid klar på en uforpligtende snak.
          </p>
          <Button
            href="mailto:info@init-together.dk"
            className="mt-8"
            negative
            big
          >
            Kontakt os
          </Button>
        </div>
      </Container>
    </section>
  );
}
