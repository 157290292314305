import { useState } from "react";
import { InputField } from "./InputField";
import { InputLabel } from "./InputLabel";
import { Button } from "./Button";

export const MultiParticipantSignupField = ({ onSubmit, onChange, errorMessage }: {
    onSubmit: (value: string) => void;
    onChange: (value: string) => void;
    errorMessage?: string;
}) => {
    const [value, setValue] = useState<string>('');

    const handleChange = (value: string) => {
        setValue(value);
        onChange(value);
    };

    const handleSubmit = () => {
        onSubmit(value);
        setValue('');
    }

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    return (
        <>
            <label htmlFor="multi-participant-signup-field" className="header-font text-sm mb-2 block leading-none">
                Indtast navn, eller noget andet du kan huske deltageren på.
            </label>
            <div className={`flex rounded-md shadow`}>
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <div className={`pointer-events-none absolute inset-y-0 top-0 left-0 flex items-center pl-2 ${errorMessage ? 'text-red-500' : 'text-primary'} `}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                        </svg>
                    </div>
                    <InputField
                        id="multi-participant-signup-field"
                        name={'multi-participant-signup-field'}
                        onChangeMethod={(val) => handleChange(val as string)}
                        onKeyPress={(e) => onEnter(e)}
                        value={value}
                        type="text"
                        className={`pl-9 rounded-none rounded-l !mb-0 !mt-0 !border-r-0 shadow-none ${errorMessage ? '!text-red-500 !border-red-500 ' : ''}`}
                        placeholder="Indtast initialer, navn, eller noget andet du kan huske"
                    />
                    {value.length > 0 ? (
                        <div onClick={() => handleChange('')} className="text-gray-500 absolute flex items-center right-3 cursor-pointer top-0 -mt-1 bottom-0 z-10">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" strokeWidth={1.8} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    ) : null}
                </div>


                <Button
                    onClick={handleSubmit}
                    useBtn={true}
                    type="button"
                    className="bg-primary relative -ml-px inline-flex items-center gap-x-1.5 rounded-none rounded-r "
                >
                    Tilføj
                </Button>
            </div>
            {errorMessage ? <div className="text-red-500 font-sans text-xs italic mt-1">Fejl: {errorMessage}</div> : null}

        </>
    );
}
