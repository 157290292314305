import { useMemo } from "react";
import { Event } from "../../../types/Event";
import { Container } from "../UI/Container";
import { FancyImage } from "../UI/FancyImage";
import dayjs from "dayjs";
import { SignupForm } from "../Event/SignupForm";
import { SignupFormValues } from "../../../types/Participant";

export const ParticipantSignupPage = ({
  event,
  handleSubmission,
  isFormLoading,
  children,
  isFormEnabled,
}: {
  event: Event;
  handleSubmission: (formValues: SignupFormValues) => void;
  isFormLoading: boolean;
  children?: React.ReactNode;
  isFormEnabled?: boolean;
}) => {
  const startDate = useMemo(
    () => dayjs(event.event_start_date).format("DD-MM-YYYY HH:mm"),
    [event.event_start_date]
  );

  return (
    <Container className=" mt-10">
      <h1 className="text-4xl mb-7">
        <span className="text-2xl block mb-2 opacity-80 font-semibold">
          Tilmelding til:
        </span>
        {event.name}
      </h1>
      <div className="lg:grid-cols-3 grid gap-10">
        <div className="col-span-2 header-font">
          <div className="max-w-2xl mb-5">
            <p className="mb-4">
              Her kan du tilmelde dig til begivenheden{" "}
              <strong>{event.name}</strong>, som afholdes af {event.org_name}.
              <br />
              Begivenheden afholdes d. <strong>{startDate}</strong>.
            </p>
            <p>
              Det er vigtigt at du indtaster en gyldig email adresse, da du skal
              bekræfte din tilmelding via email.
            </p>
          </div>
          {children ? <div className="mb-5">{children}</div> : null}
          {isFormEnabled ? (
            <SignupForm
              doSubmit={handleSubmission}
              isSubmitting={isFormLoading}
            />
          ) : null}
        </div>
        <div className="max-w-2xl">
          {event.img ? <FancyImage imgSrc={`${event.img}/800x650`} /> : null}
        </div>
      </div>
    </Container>
  );
};
