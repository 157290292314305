import { Container } from "../../UI/Container";

export default function Testimonials() {
  return (
    <section
      id="about"
      aria-labelledby="results-title"
      className="bg-slate-50 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2
            id="results-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl font-normal"
          >
            At gøre en forskel i verden.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700 ">
            Vores mission er være med til at gøre en forskel i verden, gennem at gøre velgørenhed spændende, brugerinddragende, visuelt og ikke mindst sjovt.
            <br></br><br></br>
          </p>
          <h2
            id="results-title"
            className="font-display text-2xl tracking-tight text-slate-900 sm:text-2xl font-normal"
          >
            Proof of concept
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Init-Together platformen har over de sidste 2 år indsamlet donationer for over 1.2 million kr. i samarbejde med LoveSpring. Donationerne er blevet brugt til at sikre rent drikkevand til nogle af de hårdest ramte områder i Afrika.
          </p>
          <a href="https://lovespring.dk/" className="mt-4 text-lg tracking-tight text-slate-700 underline">Læs mere på Lovespring.dk</a>
        </div>

      </Container>
    </section>
  )
}
