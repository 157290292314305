import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { SponsorEditPage } from '../components/Layout/SponsorEditPage';
import { SponsorEditLinkObject, SponsorEditLinkResponseObject } from '../../types/Participant';
import { sendRequest } from '../services/http';
import { Loading } from '../components/UI/Loading';
import { NotFoundPage } from '../components/Layout/NotFoundPage';
import { ErrorPage } from '../components/Layout/ErrorPage';
import { useTranslation } from "react-i18next";
import { TopBar } from '../components/Nav/TopBar';

export const SponsorEditPageContainer = () => {
  const { t } = useTranslation(['common']);
  const { linkHash } = useParams();
  const [sponsor, setSponsor] = useState<SponsorEditLinkObject | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);
  const [responseObject, setResponseObject] = useState<SponsorEditLinkResponseObject | null>(null);
  const [payload, setPayload] = useState<{ donation_fixed: number | undefined, donation_km: number | undefined }>({ donation_fixed: undefined, donation_km: undefined });

  useEffect(() => {
    if (linkHash && (typeof linkHash !== 'undefined')) {
      sendRequest({
        method: 'GET',
        endpoint: `sponsor/edit/${linkHash}`,
        callback: (response) => {
          if (response.status >= 400) {
            setNotFound(true);
            return;
          }
          if (response.status >= 500) {
            setError(true);
            return;
          }
          setSponsor(response.data);
          setIsLoading(false);
        },
        errorCallback: (error) => {
          console.log(error);
          setError(true);
        }
      });
    }

    return () => {
      setSponsor(null);
    }
  }, [linkHash, responseObject]);

  const submit = () => {
    let data = {};
    if (typeof payload.donation_fixed !== 'undefined') {
      data = { ...data, donation_fixed: payload.donation_fixed };
    }
    if (typeof payload.donation_km !== 'undefined') {
      data = { ...data, donation_km: payload.donation_km };
    }
    setIsLoading(true);
    sendRequest({
      method: 'POST',
      endpoint: `sponsor/edit/${linkHash}`,
      payload: data,
      callback: (response) => {
        if ((response.status >= 400) || (linkHash === null) || (typeof linkHash === 'undefined')) {
          setResponseObject({
            type: 'error',
            title: t('common:an_error_occurred'),
            message: t('common:error_on_form_submit_try_again_later')
          });
          setIsLoading(false);
          return;
        }
        setResponseObject({
          type: 'success',
          title: t('common:changes_saved')
        });

        setIsLoading(false);
      },
      errorCallback: (error) => {
        console.log(error);
        setResponseObject({
          type: 'error',
          title: t('common:an_error_occurred'),
          message: t('common:error_on_form_submit_try_again_later')
        });
        setIsLoading(false);
      }
    });
  }

  const setPayloadField = (field: string, value: string | number | boolean | undefined) => setPayload({ ...payload, [field]: value });

  if (notFound) {
    return <NotFoundPage />
  }
  if (error) {
    return <ErrorPage />
  }
  if ((isLoading === true) || (sponsor === null)) {
    return <Loading />
  }

  return (
    <>
      <TopBar />
      <SponsorEditPage
        sponsor={sponsor}
        event={sponsor.event}
        participant={sponsor.participant}
        handleChange={setPayloadField}
        submitFn={submit}
        responseObject={responseObject}
      />
    </>
  )
}