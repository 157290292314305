import { useEffect, useMemo } from 'react';
import { ProfilePage } from '../components/Layout/ProfilePage';
import { useEvent } from '../hooks/useEvent';
import { useParticipant } from '../hooks/useParticipant';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { Loading } from '../components/UI/Loading';
import { formatParticipantStats } from '../services/participants';
import { ParticipantStat } from '../../types/Participant';
import { NotFoundPage } from '../components/Layout/NotFoundPage';
import { TopBar } from '../components/Nav/TopBar';

export const ProfilePageContainer = () => {
  const { event, eventLoaded } = useEvent();
  const { participant, participantLoaded, participantSponsors } = useParticipant();
  const setDocumentTitle = useDocumentTitle();

  const stats: ParticipantStat[] = useMemo(() => {
    if (!participant) {
      return [];
    }
    return formatParticipantStats(participant);
  }, [participant]);

  useEffect(() => {
    if (event && participant) {
      setDocumentTitle(participant.name + ' | ' + event.name);
    }
  }, [event, participant]);

  if (!eventLoaded || !participantLoaded) {
    return <Loading />
  }
  if (!participant) {
    return <NotFoundPage />
  }

  return (
    <>
      <TopBar />
      <ProfilePage
        name={participant.name}
        subtitle={event?.name || ''}
        stats={stats}
        event={event}
        imgUrl={participant.main_img_secondary ? participant.main_img_secondary : null}
        sponsors={participantSponsors}
        sponsorLink={event ? `/${event.org_slug}/${event.slug}/deltagere/${participant.slug}/sponsor` : null}
        isAnon={participant.is_anonymized_data}
      />
    </>
  );
}