import { Event } from '../../types/Event';
import { Participant, ParticipantStat } from '../../types/Participant';
import i18n from '../variables/i18n';
import { buildImageLink } from './assets';

export const formatParticipant = (participant: Participant, event?: Event | null): Participant => {
  let formatted = {
    ...participant,
    profileLink: event ? `/${event.org_slug}/${event.slug}/deltagere/${participant.slug}` : null,
    sponsorLink: event ? `/${event.org_slug}/${event.slug}/deltagere/${participant.slug}/sponsor` : null,
    businessSponsorLink: event ? `/${event.org_slug}/${event.slug}/sponsor/business/${participant.slug}` : null,
    stats: formatParticipantStats(participant),
    img: participant.cloudflare_img_uuid ? buildImageLink(participant.cloudflare_img_uuid) : participant.img,
  };

  if (formatted.img) {
    // set image variants
    formatted.main_img = `${formatted.img}/participant`;
    formatted.tiny_thumb_img = `${formatted.img}/64x64`;
    formatted.big_thumb_img = `${formatted.img}/300x300`;
    formatted.main_img_secondary = `${formatted.img}/360x430`;
  }

  return formatted;
}

export const formatParticipantStats = (participant: Participant): ParticipantStat[] => {
  const t = i18n.t;
  let items: ParticipantStat[] = [
    {
      label: t('event_participants:start_nr_no_val'),
      value: participant.startnr ? participant.startnr : null
    },
  ];

  if (participant?.public_donations === true || participant?.public_donations === undefined) {
    const donation_stats = [
      {
        label: t('event_participants:one_time_in_currency', { currency: 'kr' }),
        value: participant.total_donation_fixed
      },
      {
        label: t('event_participants:donations_in_currency_and_units', { currency: 'kr', units: 'km' }),
        value: participant.donation_km
      },
      {
        label: t('event_participants:total_collected_in_currency', { currency: 'kr' }),
        value: participant.total_donation
      },
    ];
    items = [...items, ...donation_stats];
  }

  const additional = [
    {
      label: t('event_participants:units_ran', { units: 'Km' }),
      value: formatUnitsRan(participant.total_meters)
    },
    {
      label: t('event_participants:number_of_sponsors'),
      value: participant.total_sponsors ? participant.total_sponsors : 0
    },
  ];

  return [...items, ...additional];
}

export const formatUnitsRan = (amount: number): string => {
  return amount > 0 ? new Intl.NumberFormat('da-DK', { unit: 'kilometer', maximumFractionDigits: 1 }).format(amount / 1000) : '0';
}