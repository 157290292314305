export const CornerRibbon = ({ children, className }: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={`
    relative md:absolute text-white top-0 left-0 shadow-lg font-bold text-base md:text-lg tracking-widest 
    w-full md:w-[300px] p-3 md:p-2 leading-none z-50 md:-translate-x-[60px] md:translate-y-[110px] md:-rotate-45 
    bg-gradient-to-t from-red-700 to-red-500 animate-[pulse_5s_ease-in-out_infinite]
    origin-bottom-left text-center ${className || ''}`}
  >
    {children}
  </div>
);