import { useEffect, useMemo, useRef, useState } from "react";

const shareOnFb = (href: string) => {
    const urlEncodedHref = encodeURIComponent(href);
    return `https://www.facebook.com/dialog/share?app_id=918600926312549&href=${urlEncodedHref}&display=popup`;
}

const shareViaMail = (href: string) => {
    const subject = 'Hjælp mig med at samle ind til et godt formål';
    let body = '';
    // TODO: Add body

    return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(href)}`;
}

const ShareBox = ({ shareUrl, onCloseAction }: { shareUrl: string, onCloseAction?: () => void }) => {
    const [buttonText, setButtonText] = useState("Kopiér");
    const shareBoxRef = useRef<HTMLDivElement>(null);
    const absoluteShareUrl = useMemo(() => {
        if (shareUrl.startsWith('http')) {
            return shareUrl;
        }

        return window.location.origin + shareUrl;
    
    }, [shareUrl]);

    useEffect(() => {
        // Function to check if clicked outside of the box
        const handleClickOutside = (event: MouseEvent) => {
            if (shareBoxRef.current && !shareBoxRef.current.contains(event.target as Node)) {
                onCloseAction?.();
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Remove event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onCloseAction]);

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onCloseAction?.();
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onCloseAction]);

    const copyUrl = async () => {
        // Assuming you want to copy the current URL
        await navigator.clipboard.writeText(absoluteShareUrl);
        setButtonText("Kopieret!");

        // Reset the button text after 2 seconds
        setTimeout(() => {
            setButtonText("Kopiér");
        }, 2000);
    };

    return (
        <div ref={shareBoxRef} className=" hover:bg-gray-900 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-all bg-gray-800 fixed mb-4 mx-auto w-full max-w-sm p-6 rounded-lg shadow-xl z-50 text-white">
            <div className="font-medium text-xl leading-none mb-2 flex justify-between">
                <span>Del</span>
                <button onClick={onCloseAction}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 hover:opacity-100 opacity-80"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
            <div className="flex items-center justify-start">
                <a target="_blank" rel="noreferrer" href={`${shareOnFb(absoluteShareUrl)}`} className="flex flex-col items-center justify-center p-4 rounded-full text-center font-semibold opacity-80 hover:opacity-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 60 60"
                        focusable="false"
                        className="h-[60px] w-[60px]"
                    >
                        <g fill="none" fillRule="evenodd">
                            <path
                                d="M28.4863253 59.9692983c-6.6364044-.569063-11.5630204-2.3269561-16.3219736-5.8239327C4.44376366 48.4721168 3e-7 39.6467924 3e-7 29.9869344c0-14.8753747 10.506778-27.18854591 25.2744118-29.61975392 6.0281072-.9924119 12.7038532.04926445 18.2879399 2.85362966C57.1386273 10.0389054 63.3436516 25.7618627 58.2050229 40.3239688 54.677067 50.3216743 45.4153135 57.9417536 34.81395 59.5689067c-2.0856252.3201125-5.0651487.5086456-6.3276247.4003916z"
                                fill="#3B5998"
                                fillRule="nonzero"
                            ></path>
                            <path
                                d="M25.7305108 45h5.4583577V30.0073333h4.0947673l.8098295-4.6846666h-4.9045968V21.928c0-1.0943333.7076019-2.2433333 1.7188899-2.2433333h2.7874519V15h-3.4161354v.021c-5.3451414.194-6.4433395 3.2896667-6.5385744 6.5413333h-.0099897v3.7603334H23v4.6846666h2.7305108V45z"
                                fill="#FFF"
                            ></path>
                        </g>
                    </svg>
                    <span className="text-white mt-2 text-sm">Facebook</span>
                </a>
                <a href={`${shareViaMail(absoluteShareUrl)}`}className="flex flex-col items-center justify-center p-4 rounded-full text-center font-semibold opacity-80 hover:opacity-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-[60px] w-[60px] rounded-full bg-gray-400 p-3"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                        />
                    </svg>

                    <span className="text-white mt-2 text-sm">Email</span>
                </a>
            </div>
            <div className="flex justify-center items-center h-16 bg-gray-900 border-gray-700 border border-solid rounded-lg overflow-hidden pr-3">
                <input
                    type="text"
                    value={absoluteShareUrl}
                    className="text-sm text-white bg-transparent outline-none px-5 flex-grow"
                    readOnly
                />
                <button
                    className="bg-blue-600 hover:bg-blue-700 text-white text-md py-2 px-3 rounded-2xl transition duration-300 ease-in-out"
                    onClick={copyUrl}
                >
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default ShareBox;
