import { useState } from 'react';
import { Button } from '../UI/Button';
import { useTranslation } from "react-i18next";
import { Sponsor as ISponsor} from '../../../types/Participant';

const padding = 'py-1 px-2 md:py-2 md:px-4 xl:px-12 ';

const Sponsor = ({ sponsor }: { sponsor: ISponsor } ) => {
  return (
    <div className="flex flex-row even:bg-primary/10 bg-gray-100 w-full leading-none text-xs md:text-sm">
      <div className={`w-4/12 ${padding}`}>{sponsor.first_name}</div>
      <div className={`w-3/12 text-center ${padding}`}>{sponsor.donation_km} kr</div>
      <div className={`w-5/12 ${padding} text-center`}>{sponsor.donation_fixed} kr</div>
    </div>
  );
}

export const SponsorList = ({ sponsors }: { sponsors: ISponsor[] }) => {
  const incrementItems = 5;
  const [currentItemsInList, setCurrentItemsInList] = useState(10);
  const { t } = useTranslation(['events', 'common', 'event_participants']);
  const handleNextPage = () => {
    setCurrentItemsInList(currentItemsInList + incrementItems);
  }

  return (
    <div className={`w-full relative ${sponsors.length > currentItemsInList ? 'mb-2' : null}`}>
      <div className="w-full overflow-hidden rounded-bl-lg rounded-br-lg">
        <div className="text-primary-800 flex flex-row w-full bg-white leading-none items-center font-semibold text-[10px] md:text-sm">
          <div className={`w-4/12 ${padding}`}>{t('events:sponsor.title')}</div>
          <div className={`w-3/12 border-l-2 border-r-2 text-center`}>
            <div className={`${padding}`}>{t('event_participants:donations_in_currency_and_units', { currency: 'kr', units: 'km' })}</div>
          </div>
          <div className={`w-5/12 ${padding} text-center`}>{t('event_participants:one_time_in_currency', { currency: 'kr' })}</div>
        </div>
        {sponsors.slice(0, currentItemsInList).map((item, index) => <Sponsor key={index} sponsor={item} />)}
      </div>
      {(sponsors.length > currentItemsInList) ? <div className="text-center">
        <Button className="opacity-90 w-20 rounded-none rounded-b hover:rounded-br hover:opacity-100 hover:tracking-wider hover:rounded-tl-none hover:w-28 transition-all" onClick={handleNextPage} small negative>
          {t('common:show_more')}
        </Button>
      </div> : null}
      {!sponsors.length ? <p className="text-gray-400 text-center mt-2 mb-2">{t('events:sponsor.no_sponsors_yet')}</p> : null}
    </div>
  );
}