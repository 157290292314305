import { useParams } from "react-router-dom";
import { Event, EventGroup } from "../../types/Event";
import { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { sendRequest } from "../services/http";
import { buildImageLink } from "../services/assets";

export const useEventGroup = () => {
    const { groupSlug } = useParams<{ groupSlug: string }>();
    const [eventGroup, setEventGroup] = useState<EventGroup | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<Error | AxiosError | null>(null);

    useEffect(() => {
        const cleanup = () => {
            setEventGroup(null)
            setIsLoading(true)
            setError(null)
        }
        if (!groupSlug) {
            setIsLoading(false)
            return cleanup
        }
        setIsLoading(true)
        setError(null)

        const cb = (response: AxiosResponse) => {
            const grp = { 
                ...response.data,
                img: response.data.cloudflare_img_uuid ? buildImageLink(response.data.cloudflare_img_uuid) : response.data.img,
            };
            if (response.data.events.length) {
                grp.events = response.data.events.map((event: Event) => ({
                    ...event,
                    img: event.cloudflare_img_uuid ? buildImageLink(event.cloudflare_img_uuid) : event.img,
                }));
            }

            setEventGroup(grp);
            setIsLoading(false);
        }
        const errorCb = (error: Error | AxiosError) => {
            console.log(error);
            setError(error);
            setIsLoading(false);
        }
        sendRequest({ method: 'GET', endpoint: '/eventgroup/get/' + groupSlug, callback: cb, errorCallback: errorCb });

        return cleanup
    }, [groupSlug])

    return { eventGroup, eventGroupIsLoading: isLoading, eventGroupError: error }
}