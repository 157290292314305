import { useEffect, useState } from "react";
import { ErrorPage } from "../components/Layout/ErrorPage";
import { NotFoundPage } from "../components/Layout/NotFoundPage";
import { ParticipantSignupPage } from "../components/Layout/ParticipantSignupPage";
import { TopBar } from "../components/Nav/TopBar";
import { Loading } from "../components/UI/Loading";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useEvent } from "../hooks/useEvent";
import { SignupFormValues } from "../../types/Participant";
import { sendRequest } from "../services/http";
import { AxiosError, AxiosResponse } from "axios";
import { Notification } from "../components/UI/Notification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../variables/constants";

export const ParticipantSignupPageContainer = () => {
  const { event, eventLoaded, error } = useEvent();
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const [successResponse, setSuccessResponse] = useState<SignupFormValues | null>(null);
  const setDocTitle = useDocumentTitle();

  useEffect(() => {
    let title = "Tilmelding";
    if (event) {
      title += ` - ${event.name}`;
    }
    setDocTitle(title);
  }, [event, setDocTitle]);

  const handleSubmission = (values: SignupFormValues) => {
    setFormIsLoading(true);
    const onSuccess = (response: AxiosResponse) => {
      setFormIsLoading(false);
      setFormSuccess(true);
      setSuccessResponse(response.data);
    };
    const onError = (error: AxiosError) => {
      setFormIsLoading(false);
      if (error.response?.status === 400) {
        setFormError(
          "Der skete en fejl. Har du indtastet alle felterne korrekt?"
        );
        return;
      }
      setFormError("Der skete en teknisk fejl"); // TODO handle better, at least with http error codes
    };
    sendRequest({
      method: "POST",
      endpoint: `/event/${event?.slug}/signup`,
      payload: values,
      callback: onSuccess,
      errorCallback: onError,
    });
    console.log(values);
  };

  if (error) {
    return <ErrorPage />;
  }
  if (!eventLoaded) {
    return <Loading />;
  }
  if (!event) {
    return <NotFoundPage />;
  }

  return (
    <>
      <TopBar />
      <ParticipantSignupPage
        isFormLoading={formIsLoading}
        event={event}
        handleSubmission={handleSubmission}
        isFormEnabled={formSuccess ? false : true}
      >
        {formError ? (
          <Notification
            onClose={() => setFormError(null)}
            title={formError}
            type={NOTIFICATION_TYPE_ERROR}
            static={false}
          />
        ) : null}
        {formSuccess ? (
          <Notification
            title={`Tak for din tilmelding, ${successResponse?.first_name ?? ""}`}
            type={NOTIFICATION_TYPE_SUCCESS}
            static={true}
          >
            <p>
              Du vil modtage en bekræftelsesemail på {successResponse?.email ? successResponse.email : 'den email du indtastede,'} om
              få minutter. Du skal bekræfte din tilmelding ved at klikke på linket i emailen.
            </p>
          </Notification>
        ) : null}
      </ParticipantSignupPage>
    </>
  );
};
