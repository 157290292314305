import { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { MasterPage } from '../components/Layout/MasterPage';
import { Footer } from '../components/UI/Footer';
import { Outlet } from "react-router-dom";
import { OrgContext } from '../context/OrgContext';
import { AppSettingsContext } from '../context/AppSettingsContext';
import { sendRequest } from '../services/http';
import { LanguageSwitch } from '../components/Nav/LanguageSwitch';
import { CtaBanner } from '../components/CallToAction/CtaBanner';
import { I18nContext } from "react-i18next";
import { useContext } from 'react';

export const MasterPageContainer = (props) => {
  const { orgSlug } = useParams();
  const [currentOrg, setCurrentOrg] = useState(null);
  const [appSettings, setAppSettings] = useState({ locale: 'da' });
  const { i18n } = useContext(I18nContext);

  useEffect(() => {
    // fetch current org from url and save it to state
    if (orgSlug) {
      sendRequest({
        endpoint: '/org/get/' + orgSlug,
        callback: (response) => {
          setCurrentOrg(response.data);
        },
        errorCallback: (error) => {
          console.log(error);
        }
      });
    }

    return () => setCurrentOrg(null);
  }, [orgSlug]);

  useMemo(() => {
    // fetch default locale from backend
    sendRequest({
      endpoint: '/settings',
      callback: (response) => setAppSettings(response.data),
      errorCallback: (error) => console.log(error)
    });
  }, []);

  useEffect(() => {
    const localStorageLocale = localStorage.getItem('i18nextLng');
    if (localStorageLocale) {
      i18n.changeLanguage(localStorageLocale);
    }
    if (appSettings.locale && !localStorageLocale) {
      i18n.changeLanguage((appSettings.locale === 'da') ? 'da' : 'en');
    }

  }, [appSettings, i18n]);

  return (
    <AppSettingsContext.Provider value={appSettings}>
      <OrgContext.Provider value={{ currentOrg: currentOrg, setCurrentOrg: setCurrentOrg }}>
        <MasterPage>
          <div className="sm:hidden fixed z-50 right-0 gap-1 scale-60 bottom-0 flex flex-col p-1 rounded transition-all">
            <LanguageSwitch />
          </div>
          <Outlet />
          {props.children}
          <div className="mt-10">
            <CtaBanner />
            <Footer companyName={currentOrg?.name} companyEmail={currentOrg?.email} companyPhone={currentOrg?.phone} companyWebsite={currentOrg?.website} />
          </div>
        </MasterPage>
      </OrgContext.Provider>
    </AppSettingsContext.Provider>
  );
}