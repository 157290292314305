import { useEffect, useMemo, useState } from "react";
import { ParticipantConfirmEmailPage } from "../components/Layout/ParticipantConfirmEmailPage";
import { TopBar } from "../components/Nav/TopBar";
import { useEvent } from "../hooks/useEvent";
import { eventParticipantLink } from "../services/events";
import { useParams } from "react-router-dom";
import { NotFoundPage } from "../components/Layout/NotFoundPage";
import { sendRequest } from "../services/http";
import { AxiosError, AxiosResponse } from "axios";

export const ParticipantConfirmEmailPageContainer = () => {
    const { event, eventLoaded } = useEvent();
    const [participantSlug, setParticipantSlug] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const { linkHash, eventSlug } = useParams<{ linkHash: string; eventSlug: string }>();
    const [linkHashNotFound, setLinkHashNotFound] = useState<boolean>(false);

    const participantLink = useMemo(() => {
        if (!eventLoaded || !event) return undefined;
        return eventParticipantLink(event, participantSlug);
    }, [event, eventLoaded, participantSlug]);

    useEffect(() => {
        sendRequest({
            method: "GET",
            endpoint: `/event/${eventSlug}/signup/${linkHash}`,
            callback: (response: AxiosResponse) => {
                // TODO if successfully confirmed
                setIsSuccess(true);
                setParticipantSlug(response.data.participant.slug);
            },
            errorCallback: (error: AxiosError) => {
                if (error.response?.status === 404) {
                    setLinkHashNotFound(true);
                }
                // Do something with the error
                console.log(error);
                setIsSuccess(false);
            }
        });
    }, [linkHash, eventSlug]);

    if (!linkHash || linkHashNotFound) {
        return <NotFoundPage /> 
    }

    return (
        <>
            <TopBar />
            <ParticipantConfirmEmailPage isSuccess={isSuccess} participantLink={participantLink} />
        </>
    );
};