import { useState, useEffect, SyntheticEvent } from 'react';
import { InputLabel } from './InputLabel';
import { useTranslation } from "react-i18next";

export const DropdownField = (props: {
    label: string;
    value?: string | null;
    emptyDefault?: boolean;
    required?: boolean;
    onChangeMethod: (val: string) => void;
    options: { value: string; label: string }[];
}) => {
    const [value, setValue] = useState('');
    const { t } = useTranslation(['various_ui']);

    useEffect(() => {
        setValue(props.value || '');
    }, [props.value]);

    const handleChange = (event: SyntheticEvent): void => {
        const target = event.target as HTMLSelectElement;
        if(!target || !target.value) {
            return;
        }
        setValue(target.value);
        if (props.onChangeMethod) {
            props.onChangeMethod(target.value);
        }
    }

    return (
        <div className="">
            <label className="flex flex-col">
                <InputLabel required={props.required && props.required === true}>{props.label}</InputLabel>
                <br />
                <select onChange={handleChange} className="text-gray-800 sm:text-sm w-full rounded px-4 py-2.5 border bg-white border-x-gray-300 border-t--gray-300 shadow focus:border-b-gray-300 focus:border-b-1 mb-2" value={value} required={props.required && props.required === true}>
                    {(props.emptyDefault === true) ? <option disabled value=""> {t('various_ui:dropdown.choose')} </option> : null}
                    {props.options.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                </select>
            </label>
        </div>
    );
}