import { useState, useEffect, useMemo } from 'react';
import { LoadingOverlay } from '../UI/Loading';
import { Notification } from '../UI/Notification';
import { NOTIFICATION_TYPE_ERROR } from '../../variables/constants';
import { Container } from '../UI/Container';
import { useTranslation } from "react-i18next";
import { Participant } from '../../../types/Participant';
import { Event, Ngo } from '../../../types/Event';
import { SponsorFormValues } from '../../../types/components/SponsorParticipantPage';
import { CheckOutForm } from '../Event/CheckOutForm';

interface Props {
  submitForm: (formValues: SponsorFormValues) => void
  participant: Participant
  formIsLoading: boolean
  event: Event
  formSubmissionErrors?: string[]
  ngo?: Ngo
}

export const SponsorParticipantPage = ({ submitForm, participant, formIsLoading, event, formSubmissionErrors, ngo }: Props) => {
  const [showNotification, setShowNotification] = useState(false);
  const { t } = useTranslation(['event_participants', 'common', 'errors']);

  const isErrorNotification = useMemo(() => (typeof formSubmissionErrors !== 'undefined') && formSubmissionErrors && formSubmissionErrors.length > 0, [formSubmissionErrors]);
  const notificationTitle = useMemo(() => isErrorNotification ? t('errors:an_error_happened') : '', [isErrorNotification, t]);
  const notificationMessage = useMemo(() => isErrorNotification ? formSubmissionErrors?.map(str => str).join(', ') : '', [isErrorNotification, formSubmissionErrors]);
  const notificationType = NOTIFICATION_TYPE_ERROR;

  useEffect(() => {
    setShowNotification(isErrorNotification);

    return () => setShowNotification(false);
  }, [isErrorNotification]);

  if ((participant === null) || (typeof participant === 'undefined')) {
    return <Container>Error: Missing participant data</Container>;
  }

  return (
    <>
      {formIsLoading ? <LoadingOverlay /> : null}
      <div className="bg-gradient-to-br from-primary-900 to-primary-700 text-white w-full py-10 text-center md:text-left">
        <Container className="flex flex-col md:flex-row md:gap-10 items-center">
          <div className="header-font md:order-2 text-[21px] leading-snug">
            <p className="">{t('event_participants:sponsor.become_sponsor_for')}</p>
            <p className="font-bold text-4xl">{participant.name}</p>
          </div>
          <div className={`mt-5 mb-1 lg:mt-0 lg:mb-0 rounded-full h-40 w-40 flex items-center justify-center overflow-hidden align-top ${participant.is_anonymized_data ? 'bg-white/20' : ''}`}>
            <img src={participant.main_img_secondary ? `${participant.main_img_secondary}` : 'undefined'} alt={participant.name} className={`bg-white ${participant.is_anonymized_data ? 'blur-md' : ''}`} />
          </div>
        </Container>
      </div>
      <Container>
        {showNotification ? <Notification onClose={() => setShowNotification(false)} type={notificationType} title={notificationTitle} text={notificationMessage} /> : null}
        <div className="header-font text-sm mt-8 mb-4 text-gray-700">
          <h3 className="text-2xl font-medium text-gray-900 mb-1 mt-2">
            {event.name}
          </h3>
          <p className="text-base text-gray-500 leading-snug">
            {event.org_name}
          </p>
          <p className="font-normal text-md mt-2">
            {t('event_participants:sponsor.fill_to_sponsor')}.
          </p>
        </div>
        <CheckOutForm
          canSignUpForMemberShip={event.can_sign_up_for_membership}
          submitForm={submitForm}
          ngo={ngo}
          event={event}
          participant={participant}
        />
      </Container>
    </>
  );
}