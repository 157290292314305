import { EventGroupPage } from "../components/Layout/EventGroupPage";
import { TopBar } from "../components/Nav/TopBar";
import { useEventGroup } from "../hooks/useEventGroup";
import { ErrorPage } from "../components/Layout/ErrorPage";
import { NotFoundPage } from "../components/Layout/NotFoundPage";
import { Loading } from "../components/UI/Loading";
import { Ngo, Project } from "../../types/Event";
import { useEffect, useState } from "react";
import { sendRequest } from "../services/http";
import { AxiosResponse } from "axios";
import { buildImageLink } from "../services/assets";

export const EventGroupPageContainer = () => {
    const { eventGroup, eventGroupError, eventGroupIsLoading } = useEventGroup();
    const [projects, setProjects] = useState<Project[]>([]);
    const [ngoList, setNgoList] = useState<Ngo[]>([]);

    useEffect(() => {
        if (!eventGroup) return;
        sendRequest({
            method: "GET",
            endpoint: `eventgroup/get/${eventGroup.slug}/project`,
            callback: (response: AxiosResponse) => {
                const projects = response.data.map((project: Project) => ({
                    ...project,
                    img: project.cloudflare_img_uuid ? buildImageLink(project.cloudflare_img_uuid) : project.img,
                }));
                setProjects(projects);
            },
        });

        return () => setProjects([]);
    }, [eventGroup]);

    useEffect(() => {
        if (!eventGroup || !eventGroup.events.length) return;
        sendRequest({
            method: "GET",
            endpoint: `event/get/${eventGroup.events[0].slug}/ngo`,
            callback: (response: AxiosResponse) => {
                setNgoList([response.data]);
            },
        });

        return () => setNgoList([]);
    }, [eventGroup]);

    if (eventGroupIsLoading) return <Loading />;
    if (eventGroupError) return <ErrorPage />;
    if (!eventGroup) return <NotFoundPage />;

    return (
        <>
            <TopBar />
            <EventGroupPage
                eventGroup={eventGroup}
                events={eventGroup.events}
                projects={projects}
                mainImage={eventGroup.img || eventGroup.events[0].img}
                results={eventGroup.result}
                ngoList={ngoList}
            />
        </>
    );
}