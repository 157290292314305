import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LoadingOverlay } from "../components/UI/Loading";
import { useQrCode } from "../hooks/useQrCode";
import { TopBar } from "../components/Nav/TopBar";
import { QrCodeType } from "../variables/enum";

export const QrRequestContainer = () => {
    const { qrHash, qrType, relatedEvent, relatedEventGroup } = useQrCode();
    const navigate = useNavigate();

    // figure out where to redirect.
    useEffect(() => {
        if (qrType === QrCodeType.EVENT_MULTI_SIGNUP) {
            navigate(`/qr/${qrHash}/event-multi-signup`, { replace: true });
            return;
        }
        if (qrType === QrCodeType.EVENT) {
            navigate(`/${relatedEvent?.org_slug}/${relatedEvent?.slug}`, { replace: true });
            return;
        }
        if (qrType === QrCodeType.EVENT_GROUP) {
            navigate(`group/${relatedEventGroup?.slug}`, { replace: true });
            return;
        }
        if (qrType === QrCodeType.PARTICIPANT_LIST) {
            navigate(`/${relatedEvent?.org_slug}/${relatedEvent?.slug}/deltagere`, { replace: true });
            return;
        }
    }, [qrHash, navigate, qrType, relatedEvent, relatedEventGroup]);

    // TODO handle invalid types

    return (
        <>
            <TopBar />
            <div style={{ minHeight: '800px' }}><LoadingOverlay /></div>
        </>
    );
};