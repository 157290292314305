import { useEffect, useState } from "react";
import { ParticipantConfirmEmailPage } from "../components/Layout/ParticipantConfirmEmailPage";
import { TopBar } from "../components/Nav/TopBar";
import { useNavigate, useParams } from "react-router-dom";
import { NotFoundPage } from "../components/Layout/NotFoundPage";
import { sendRequest } from "../services/http";
import { AxiosError, AxiosResponse } from "axios";
import { Loading } from "../components/UI/Loading";

export const MultiSignupConfirmEmailPageContainer = () => {
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const { linkHash } = useParams<{ linkHash: string }>();
    const [linkHashNotFound, setLinkHashNotFound] = useState<boolean>(false);

    useEffect(() => {
        sendRequest({
            method: "PUT",
            endpoint: `/participant-admin/${linkHash}`,
            payload: { confirm: true },
            callback: (response: AxiosResponse) => {
                // TODO if successfully confirmed
                setIsSuccess(true);
                setIsLoading(false);
            },
            errorCallback: (error: AxiosError) => {
                if (error.response?.status === 404) {
                    navigate("/not-found");
                    return;
                }
                // Do something with the error
                console.log(error);
                setIsSuccess(false);
                setIsLoading(false);
            }
        });
    }, [linkHash, navigate]);

    if (isLoading) {
        return <Loading />
    }

    if (!linkHash || linkHashNotFound) {
        return <NotFoundPage /> 
    }

    return (
        <>
            <TopBar />
            <ParticipantConfirmEmailPage isSuccess={isSuccess} isMultiSignup={true} />
        </>
    );
};