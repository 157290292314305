
export const MasterPage = (props: {
  children: React.ReactNode
}) => {

  return (
    <div className="">
      {props.children}
    </div>
  );
}