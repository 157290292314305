import { InputField } from '../UI/InputField';
import { CssTriangle } from '../Various/CssTriangle';
import { SyntheticEvent, useEffect, useState } from 'react';
import lovespring from '../../images/lovespring.png';

export const FormMemberSignupBox = ({ handleChange, value, visible, amountPerUnits, oneTimeAmount, className }: {
  className?: string
  handleChange: (val: boolean) => void
  value: boolean
  visible: boolean
  amountPerUnits: number
  oneTimeAmount: number
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    return () => {
      setIsPopupOpen(false);
    }
  }, []);

  const detailsClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsPopupOpen(!isPopupOpen);
  }

  if (visible === false) {
    return null;
  }

  return (
    <div className={`relative ${className ? className : null}`}>
      <div className="bg-primary/30 px-4 py-3 rounded text-left w-full">
        <InputField name="member-signup-check" onChangeMethod={(val) => handleChange(!!val)} type="checkbox" value={value}>
          Tilmed mig til et gratis medlemskab ved foreningen LoveSpring, hvis mit sponsorat overstiger 200 kr
        </InputField>
        <div className="font-semibold text-xs pl-5 leading-1">
          <span>Det koster ikke ekstra, og det hjælper LoveSpring som NGO</span>
          <br />
          <div className="inline-block mt-1">
            <a href="#" onClick={detailsClick} className="underline hover:no-underline" target="_blank" rel="noopener noreferrer">Tryk her</a> for at læse mere
          </div>
        </div>
        <img className="m-auto md:m-0" src={lovespring} alt="Lovespring logo" width="160" />
      </div>
      {isPopupOpen && <>
        <div className="fixed w-full h-full left-0 top-0 bg-black/40 z-40" onMouseDown={() => setIsPopupOpen(false)}></div>
        <div className="py-4 absolute left-0 top-full z-50">
          <CssTriangle className="absolute -top-1 left-12" color="blue-200" />
          <div className="relative p-6 -top-1 bg-blue-200 w-full rounded ">
            <div className="absolute top-2 right-2 cursor-pointer text-blue-400 hover:text-blue-800 transition-all" onMouseDown={() => setIsPopupOpen(false)}>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>

            <div className="font-bold uppercase text-sm leading-none text-blue-800 mb-2">Medlemskab hos LoveSpring</div>
            <div className=" text-sm leading-1 text-blue-800">
              LoveSpring, som står for brøndprojekterne, som dette løb indsamler til, er en velgørenhedsorganisation.
              <br /><br />
              For at beholde deres status som velgørenhedsorganisation (NGO), skal de have mindst 200 medlemmer om året, som har betalt mindst 200kr.
              <br /><br />
              Ved at støtte denne begivenhed med min. 200 kr i dit sponsorat, kan du blive medlem af LoveSpring i dette kalenderår + næste år.
              <br />
              Dit medlemskab fornyes <strong>IKKE</strong> automatisk.
              <br /><br />
              Der er ingen videre bindinger, forpligtelser eller betalinger.
              <br />
              Læs mere på <a rel="noreferrer" className="underline" href="https://lovespring.dk/da/forside/" target="_blank">https://lovespring.dk</a>
            </div>


            <img className="m-auto" src={lovespring} alt="Lovespring logo" width="160" />
          </div>
        </div>
      </>}
    </div>
  )
}
