import { SyntheticEvent, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { ButtonProps } from '../../../types/components/Button';



export const Button = (props: ButtonProps) => {
    const navigate = useNavigate();

    let size = "text-sm py-3 px-5"
    if (props.small) {
        size = "text-xs py-2 px-3"
    }
    if (props.big) {
        size = "text-lg font-semibold py-3.5 px-6"
    }

    const classNames = useMemo(() => {
        const negativeBg = props.transparency ? `bg-white/80` : 'bg-white';
        const primaryBg = props.transparency ? `bg-primary-700/80` : 'bg-primary-700';

        let classNames = `${size + ' '} header-font btn cursor-pointer whitespace-nowrap inline-block text-center leading-none rounded shadow-md hover:shadow-sm`;
        classNames += ` ${props.negative ? `${negativeBg} hover:bg-primary-700 hover:text-white text-primary-700` : `${primaryBg} hover:bg-primary-900 text-white `}`;
        classNames += `${props.className ? ' ' + props.className : ''}`;
        return classNames;
    }, [props.className, props.negative, size, props.transparency]);

    const clickAction = (e: SyntheticEvent): void => {
        console.log('clickAction');
        if (props.href) {
            e.preventDefault();

            // if starts with http or https, open in new tab
            if (props.href.startsWith('http')) {
                window.open(props.href, '_blank');
                return;
            }
            // if starts with mailto:, open in new tab
            if (props.href.startsWith('mailto:')) {
                window.open(props.href);
                return;
            }

            navigate(props.href);
            return;
        }
        if (props.onClick) {
            props.onClick(e);
        }
    }

    const calculatedProps = useMemo(() => {
        if (props.onClick || props.href) {
            return { onClick: clickAction };
        }
        return {};
    }, [props]);

    if (props.useBtn === true) {
        return (
            <button disabled={props.disabled} type={props.type} className={classNames} {...calculatedProps}>
                {props.children}
            </button>
        );
    }
    return (
        <a href={props.href} className={classNames} {...calculatedProps}>
            {props.children}
        </a>
    );
}