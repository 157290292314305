import dayjs from "dayjs"
import { SponsorEventPageProps } from "../../../types/components/SponsorEventpage"
import { CheckOutForm } from "../Event/CheckOutForm"
import { TopBar } from "../Nav/TopBar"
import { Container } from "../UI/Container"
import { useTranslation } from "react-i18next"

export const SponsorEventPrivatePage = ({ event, onSubmit }: SponsorEventPageProps) => {
    const { t } = useTranslation(['sponsor']);

    return (
        <>
            <TopBar />
            <div className="bg-gradient-to-br from-primary-900 to-primary-700 text-white w-full ">
                <Container className="flex flex-col lg:flex-row items-center justify-between py-8 gap-4">
                    {event.img ? <img alt={'Billede' + event.name} className="rounded shadow-lg max-h-48 h-auto lg:order-2" src={`${event.img}/800x650`} /> : null}
                    <h1 className="lg:order-1 text-center lg:text-left">
                        <span className="mb-3 block text-xl lg:text-2xl opacity-90">{t('sponsor:sponsor_type_page.become_sponsor')}</span>
                        <span className="font-bold text-2xl lg:text-4xl">{event.name}</span>
                        {event.event_start_date && <span className="block mt-3 opacity-90">d. {dayjs(event.event_start_date).format('DD-MM-YYYY')}</span>}
                    </h1>
                </Container>
            </div>
            <Container topSpacing>
                <CheckOutForm
                    event={event}
                    submitForm={onSubmit}
                    canSignUpForMemberShip={event.can_sign_up_for_membership}
                />
            </Container>
        </>
    )
}