import dayjs from 'dayjs';
import { useEffect, useRef, useMemo, useState } from 'react';
import { PROJECT_STATUS_ACTIVE } from '../../variables/constants';
import { EventPageHeader } from '../Event/EventPageHeader';
import { EventStatsBox } from '../Event/EventStatsBox';
import { EventNgoBox } from '../Event/EventNgoBox';
import { EventIsOverBanner } from '../Event/EventIsOverBanner';
import { Button } from '../UI/Button';
import { EventCountDown } from '../Event/EventCountDown';
import { EventDescription } from '../Event/EventDescription';
import { Container } from '../UI/Container';
import { InfoBoxGrid } from '../Various/InfoBoxGrid';
import { useTranslation } from "react-i18next";
import fallbkproject from '../../images/fallbk-project.jpg';
import { eventParticipantsLink, eventSignUpLink, eventSponsorLink } from '../../services/events';
import { Event, EventStage, Ngo } from '../../../types/Event';
import { EventStatsBox as EventStatsBoxType } from '../../../types/components/EventComponents';
import { DefaultInfoBoxProps } from '../../../types/components/InfoBox';
import { EventStageBox } from '../Event/EventStageBox';
import { Participantsbutton } from '../Event/ParticipantsButton';
import { SponsorButton } from '../Event/SponsorButton';
import ShareBox from '../Various/ShareBox';
import { EventType } from '../../variables/enum';

/**
 * TODO remove "any"
 */
export const EventPage = ({ mainImage, charities, eventStats, eventGroupStats, statsLastUpdated, isActive, isPast, event, ngoList, stages, displayCountDownBox }: {
  mainImage: string,
  charities: any[],
  eventStats: EventStatsBoxType,
  eventGroupStats: EventStatsBoxType[],
  statsLastUpdated: string,
  isActive: boolean,
  isPast: boolean,
  event: Event,
  ngoList: Ngo[],
  stages?: EventStage[],
  displayCountDownBox?: boolean
}) => {
  const [isShareBoxToggled, setIsShareBoxToggled] = useState(null as { x: number, y: number } | null);
  const resultsContainerRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation('events');
  const startDate = useMemo(() => dayjs(event.event_start_date), [event]);
  const displayCountDown = useMemo(() => !isPast && !isActive && (displayCountDownBox === true), [displayCountDownBox, isActive, isPast]);

  const stagesInfoBoxes = useMemo(() => {
    if (!stages || stages.length === 0) {
      return [];
    }

    return stages.map(stage => {
      // number format stage.total_meters as km using NumberFormat
      const km = new Intl.NumberFormat('da-DK', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 0 }).format(stage.total_meters / 1000);

      const obj: DefaultInfoBoxProps = {
        image: stage?.img || undefined, // TODO
        title: stage.name,
        subtitle: 'Længde i km: ' + km,
        description: stage.description,
        focused: stage.id === '1', // TODO
      };
      return obj;
    });
  }, [stages]);

  const infoboxes: any = useMemo(() => {
    if (!charities || charities.length === 0) {
      return [];
    }
    return charities.map(charity => {
      const obj = {
        image: charity.img,
        fallbackImage: fallbkproject,
        title: charity.name,
        finalGoalAmount: charity.price,
        currentGoalAmount: charity.donations,
        description: charity.description,
        focused: (charity.project_status === PROJECT_STATUS_ACTIVE) && (dayjs().isBefore(dayjs(event.event_end_date))),
      };

      return obj;
    });

  }, [charities, event.event_end_date]);

  useEffect(() => {
    // if #results is present in url, scroll to results
    if (window.location.href.includes('#results')) {
      scrollToResults();
    }
  }, []);

  const scrollToResults = () => resultsContainerRef?.current?.scrollIntoView({ behavior: 'smooth' });
  const signupUrl = useMemo(() => eventSignUpLink(event), [event]);
  const participantsUrl = useMemo(() => eventParticipantsLink(event), [event]);
  const sponsorUrl = useMemo(() => eventSponsorLink(event), [event]);

  const shareBtnClick = (event: any) => {
    const cursorX = event.clientX;
    const cursorY = event.clientY;
    setIsShareBoxToggled({ x: cursorX, y: cursorY })
  }

  return (
    <>
      {isShareBoxToggled && <ShareBox onCloseAction={() => setIsShareBoxToggled(null)} shareUrl={window.location.href} />}
      {isPast ? <EventIsOverBanner event={event} link='#results' clickCallback={scrollToResults} /> : null}
      <EventPageHeader image={mainImage}>
        <div className="w-full flex flex-col items-center justify-center overflow-hidden ">
          <div className="text-center  rounded-xl relative z-10">
            <h1 className="text-2xl mb-2 lg:text-[34px] xl:text-[38px] xl:mb-4 xl:[line-height:1.1]">{event?.name}</h1>
            <p className="text-base lg:text-lg xl:text-2xl mb-2 text-gray-900/80 font-semibold header-font">
              {t('event_page.for_good_cause')}
            </p>
            <div className="text-sm lg:flex flex-col justify-center items-center text-gray-900/70">
              <div className="font-semibold header-font text-base lg:text-lg ">
                {!isPast ? t('event_page.start_date_at', { date: dayjs(event.event_start_date).format('DD-MM-YYYY HH:mm') }) : null}
                {isActive ? <div>{t('event_page.event_currently_happening', { time: dayjs(event.event_start_date).format('HH:mm') })}</div> : null}
              </div>

              {displayCountDown ?
                <div className="mt-4 ">
                  <EventCountDown date={startDate}>
                    <div className="flex">
                    {event.has_public_sign_up ? <Button className="mr-2" negative href={signupUrl}>Tilmeld dig</Button> : null}
                        <Button className="mt-4" negative href={participantsUrl}>{t('event_page.see_all_participants')}</Button>
                        <Button onClick={shareBtnClick} className="mt-4 ml-3 flex items-center gap-2 pl-3 pr-4" negative>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                          </svg>
                          <span>Del</span>
                        </Button>
                    </div>
                  </EventCountDown>
                </div> :
                <div className="mt-5 flex flex-row gap-2 items-center justify-center">
                  <Participantsbutton participantCount={0} href={participantsUrl}></Participantsbutton>
                  {!isPast ? <SponsorButton href={sponsorUrl} /> : null}
                  <Button onClick={shareBtnClick} className="flex items-center gap-2 pl-3 pr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                    </svg>
                    <span>Del</span>
                  </Button>
                </div>
              }
            </div>
          </div>
        </div>
      </EventPageHeader>
      <EventDescription title={event.name}>
        {event.description}
      </EventDescription>
      {/* <FindParticipantToSponsor event={event} /> */}
      <EventNgoBox ngoList={ngoList} />

      <div ref={resultsContainerRef}>
        <EventStatsBox
          total_meters={event.event_type === EventType.EVENT_FIXED_LENGTH ? undefined : eventStats.total_meters}
          donations={event.event_type === EventType.EVENT_FIXED_LENGTH ? undefined : eventStats.donations}
          variableCurrentDonations={event.event_type === EventType.EVENT_FIXED_LENGTH ? 2354 : undefined}
          rounds={stages?.length || event.event_type === EventType.EVENT_FIXED_LENGTH ? undefined : eventStats.rounds}
          sponsor_count={eventStats.sponsor_count}
          isRealTime={isActive}
          lastUpdated={statsLastUpdated}
          fixedLengthTotalMeters={event.event_type === EventType.EVENT_FIXED_LENGTH ? { total_meters: 25000, meters_progress: 5000 } : undefined}
          groups={eventGroupStats}
        />
      </div>

      <div className="bg-gray-50 pt-10">
        <Container customMaxWidth="max-w-7xl">
          {infoboxes && infoboxes.length ? <div className="pb-10 pt-12 text-center">
            <h2 className="text-3xl lg:text-4xl font-semibold mb-2 text-primary">
              {t('event_page.supports_the_following')}
            </h2>
            <p className="mb-2 text-xl text-gray-500">{t('event_page.ty_sponsors')}</p>
            <hr className="mb-10 mt-10 border-gray-300 m-auto lg:max-w-6xl" />
            <div >
              {infoboxes && infoboxes.length > 0 ? <InfoBoxGrid infoBoxType="default" items={infoboxes} /> : null}
            </div>
          </div> : null}
          {stagesInfoBoxes && stagesInfoBoxes.length ? <div className="pb-10 pt-12 text-center">
            <h2 className="text-3xl lg:text-4xl font-semibold mb-2 text-primary">
              {t('event_page.stages')}
            </h2>
            <p className="max-w-lg m-auto">
              Begivheden er delt op i adskillige etaper, med forskellige længder, tidspunkter og velgørenhedsprojekter.
            </p>


            <div className={`flex flex-wrap mt-12 -mx-2 items-start gap-y-8 ${stagesInfoBoxes.length < 4 ? 'justify-center' : ''}`}>
              {stagesInfoBoxes.map((item: DefaultInfoBoxProps, index: number) => {

                return <div key={index} className={`w-full px-4 lg:w-1/2`}><EventStageBox {...item} /></div>;
              })}
            </div>

          </div> : null}
        </Container>
      </div>

    </>
  );
}