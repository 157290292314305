import { useEffect, useMemo, useState } from 'react';
import { EventPage } from '../components/Layout/EventPage';
import { NotFoundPage } from '../components/Layout/NotFoundPage';
import { ErrorPage } from '../components/Layout/ErrorPage';
import { PROJECT_STATUS_INACTIVE } from '../variables/constants';
import { useEvent } from '../hooks/useEvent';
import { useEventNgo } from '../hooks/useEventNgo';
import { sendRequest } from '../services/http';
import dayjs from 'dayjs';
import { Loading } from '../components/UI/Loading';
import { TopBar } from '../components/Nav/TopBar';
import { buildImageLink } from '../services/assets';

export const EventPageContainer = () => {
  const { event, eventLoaded, error, fetchEventStats, eventStats, eventGroupStats } = useEvent({ withDocTitle: true });
  const [charityProjects, setCharityProjects] = useState([]);
  const [eventStages, setEventStages] = useState([]);
  const { eventNgoList } = useEventNgo({ eventSlug: event?.slug || null });

  useEffect(() => {
    if (!event) {
      return;
    }
    fetchCharities();
    fetchEventStages();
    if (dayjs().isBefore(event.event_end_date) && window.Echo) {
      window.Echo.connect()
      window.Echo.channel("events").listen(".newEvent", (event) => {
        if (event.update === 'success') {
          fetchEventStats();
          fetchCharities();
          fetchEventStages();
        }
      });
    }
    return () => {
      if (window.Echo) {
        window.Echo.disconnect();
      }
      setCharityProjects([]);
      setEventStages([]);
    };
    // eslint-disable-next-line
  }, [event]);

  const statsLastUpdated = useMemo(() => {
    if ((event !== null) && (dayjs().isBefore(event.event_end_date))) {
      return dayjs().format('YYYY-MM-DD HH:mm:ss');
    }
    return null;
  }, [event]);

  useEffect(() => {
    if (event && event.is_group_event) {
      // TODO navigate to group event page
    }
    // eslint-disable-next-line
  }, [event]);

  const fetchCharities = () => {
    sendRequest({
      method: 'GET',
      endpoint: `event/get/${event.slug}/project`,
      callback: (response) => {
        if (response.data.length === 0) {
          return;
        }
        const projects = response.data
          .filter(obj => obj.project_status !== PROJECT_STATUS_INACTIVE).sort((a, b) => a.sort_order - b.sort_order)
          .map((project) => ({ ...project, img: project.cloudflare_img_uuid ? buildImageLink(project.cloudflare_img_uuid) : project.img }))
        ;
        setCharityProjects(projects);
      },
      errorCallback: (error) => {
        console.log(error);
      }
    });
  }

  const fetchEventStages = () => {
    // TODO fetch from API
/*     setEventStages([
      {
        id: '1',
        img: 'https://imagedelivery.net/gjfMLhdkfC1OSmwmVETE5w/jcT6NxHtir/',
        name: 'Tanzania etape',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc urna dolor, elementum non molestie id, ornare et augue. Curabitur sagittis aliquam egestas. Nulla et nunc eget tellus luctus congue. Nullam augue turpis, congue commodo porta eu, lobortis ut nulla. Praesent gravida in purus sed laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/> <br/> Nunc urna dolor, elementum non molestie id, ornare et augue. Curabitur sagittis aliquam egestas. Nulla et nunc eg',
        total_meters: 56320,
      },
      {
        id: '2',
        name: 'Kenya etape',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc urna dolor, elementum non molestie id, ornare et augue. Curabitur sagittis aliquam egestas.',
        total_meters: 100000,
      },
    ]); */
  }

  const isActive = useMemo(() => event && dayjs().isAfter(dayjs(event.event_start_date)) && dayjs().isBefore(dayjs(event.event_end_date)), [event]);
  const isPast = useMemo(() => event && dayjs().isAfter(dayjs(event.event_end_date)), [event]);

  if (!eventLoaded) {
    return <Loading />
  }

  if (error) {
    return <ErrorPage />
  }

  if (!event) {
    return <NotFoundPage />
  }

  return (
    <>
      <TopBar />
      <EventPage
        event={event}
        mainImage={event.img}
        charities={charityProjects}
        eventStats={eventStats}
        eventGroupStats={eventGroupStats}
        statsLastUpdated={statsLastUpdated}
        isActive={isActive}
        isPast={isPast}
        ngoList={eventNgoList}
        stages={eventStages}
        displayCountDownBox={true}
      />
    </>
  );
}