import { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { lighten } from 'polished';

const chartOptions = {
  plugins: {
    legend: {
      display: false,
      labels: {
        color: 'rgb(55, 55, 55)'
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: '#404040',
        font: {
          size: 12,
        }
      }
    },
    x: {
      ticks: {
        color: '#404040',
        font: {
          size: 14,
        }
      }
    }
  }
};

// TODO REMOVE "ANY" TYPES
export const DataChart = ({ data, title, type, children }: {
  data: any,
  title?: string,
  type: 'line' | 'bar',
  children?: React.ReactNode
}) => {
  const canvasRef = useRef<HTMLCanvasElement|null>(null);
  const chartRef = useRef(null) as any;

  useEffect(() => {
    const cleanup = () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
    if (!canvasRef.current) {
      return cleanup;
    }

    const ctx = canvasRef.current.getContext('2d');
    let formattedDataSets: any[] = [];
    data.datasets.forEach((dataset: any, i: number) => {
      const bgColor = "rgb(84 169 165)";
      const el = { 
        ...(structuredClone(dataset)), 
        borderWidth: 0,
        // calc backgroundColor based on index
        backgroundColor: [bgColor],
        borderColor: [lighten(0.15, bgColor)],
      };

      formattedDataSets.push(el)
    }); 

    chartRef.current = new Chart(ctx as CanvasRenderingContext2D, {
      type: (type === 'bar') ? 'bar' : 'line',
      //data: { labels: labels, datasets: formattedDataSets },
      data: { ...data, datasets: formattedDataSets },
      options: chartOptions
    });

    return cleanup;
  }, [data, title, type]);

  return (
    <div className="relative">
      {title ? <div className="mb-4 text-2xl uppercase text-primary font-semibold">{title}</div> : null}
      {children}
      <div className="bg-slate-200/10 p-6 rounded-lg shadow-lg">
        <canvas ref={canvasRef} width="850" height="600" />
      </div>
    </div>
  );
}