import { useTranslation } from "react-i18next";
import { Participant } from '../../../types/Participant';

const padding = 'py-1 lg:py-2 2xl:py-3';
const ParticipantRow = ({ name, rank, total_donation, total_meters, total_rounds, time, main_img, dynamicCol }: {
  name: string,
  rank: number,
  total_donation: number | null,
  total_meters: number,
  total_rounds: number,
  time?: string,
  main_img?: string,
  dynamicCol: string,
}) => {
  let formattedNumber = new Intl.NumberFormat('da-DK', {
    style: 'unit',
    unit: 'kilometer',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(total_meters / 1000);

  return (
    <div className="
                flex flex-row min-w-[600px] md:w-full justify-between items-center last:rounded-bl-lg last:rounded-br-lg leading-none text-xs lg:text-lg text-center 
                hover:scale-[1.01] hover:shadow-lg transition-all even:bg-white/70 bg-white/80 text-primary-900"
    >
      <div className={`w-1/12 ${padding}`}>{rank}</div>
      <div className={`w-5/12 text-base line-clamp-1 ${padding}`}>
        <div className="flex flex-row gap-3 pl-2 md:pl-9 items-center text-left">
          <div className="overflow-hidden rounded-full transition-all w-[32px] h-[32px]">
            <img className="max-w-full" src={`${main_img}`} alt={`billede af ${name}`} />
          </div>
          <div className="truncate">{name}</div>
        </div>
      </div>
      <div className={`w-2/12 ${padding}`}>
        {dynamicCol === 'total_meters' ? formattedNumber : total_rounds}
      </div>
      <div className={`w-4/12 ${padding}`}>{total_donation !== null ? `${total_donation} kr` : '-'}</div>
      <div className={`w-1/12 hidden ${padding}`}>{time}</div>
    </div>
  );
}

export const ParticipantsTable = ({ participants, limitItems, dynamicCol }: {
  participants: Participant[],
  limitItems?: number,
  dynamicCol: string,
}) => {
  const { t } = useTranslation(['event_participants', 'events']);

  return (
    <div className={`overflow-scroll md:overflow-visible [-webkit-overflow-scrolling:touch] w-full shadow-2xl`}>
      <div className="flex flex-row min-w-[600px] md:w-full justify-between text-primary-800 bg-white leading-none items-center font-semibold text-[10px] md:text-sm text-center">
        <div className={`w-1/12 leading-none ${padding}`}>Top 10</div>
        <div className={`w-5/12 md:text-left md:pl-20 leading-none border-l-2 border-r-2`}><div className={`${padding}`}>The runners</div></div>
        <div className={`w-2/12 leading-none border-r-2 ${padding}`}>
          {dynamicCol === 'total_meters' ? t('events:number_of_units', { units: "km" }) : t('events:number_of_rounds')}
        </div>
        <div className={`w-4/12 leading-none border-r-2 ${padding}`}>
          {t('events:total_donation_currency', { currency: "kr" })}
        </div>
        <div className={`w-1/12 leading-none hidden ${padding}`}>Tid</div>
      </div>
      {participants.length ? participants.slice(0, (limitItems ? limitItems : 10)).map((item, index) => {
        return (
          <ParticipantRow
            key={index}
            name={item.name}
            rank={index + 1}
            total_rounds={item.total_rounds}
            total_donation={(item?.public_donations === true || item?.public_donations === undefined) ? item.total_donation : null}
            total_meters={item.total_meters}
            main_img={item.tiny_thumb_img}
            dynamicCol={dynamicCol}
          />
        )
      }) : null}
      {!participants.length ? <p className="text-gray-400 text-center mt-2 mb-2">{t('events:no_runners_yet')}</p> : null}
    </div>
  );
}