import { Container } from "../UI/Container";

export const ParticipantConfirmEmailPage = ({ isSuccess, isMultiSignup }: {
    isSuccess: boolean;
    isMultiSignup?: boolean;
    participantLink?: string;
}) => {
    return (
        <Container className="text-center py-10 mt-10 flex items-center flex-col gap-4">
            {isSuccess ? (
                <>
                    <h1 className="lg:text-4xl mb-2 lg:mb-5">Din tilmelding er nu bekræftet</h1>
                    {isMultiSignup ?
                        <p className="mb-4">
                            Du vil indenfor et par minutter modtage en email, med flere informationer omkring arrangementet.
                            <br />
                            I mailen finder du blandt andet oplysninger om, hvordan du kan indsamle sponsorer til arrangementet.
                        </p>
                        :
                        <p className="mb-4">
                            Du vil modtage flere informationer omkring arrangementet på mail, når vi nærmer os startdatoen.
                        </p>
                    }
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 text-emerald-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                </>
            ) : (
                <>
                    <h1 className="text-2xl font-bold">Din tilmelding kunne ikke bekræftes</h1>

                    <p>
                        Der skete en fejl under bekræftelsen af din tilmelding. Prøv igen senere, eller kontakt os for hjælp.
                    </p>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 text-red-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                    </svg>

                </>
            )
            }
        </Container>
    );
};