import { SyntheticEvent, useEffect, useState } from 'react';
import { InputLabel } from './InputLabel';
import { InputFieldProps } from '../../../types/components/InputField';


export const InputField = (props: InputFieldProps) => {
    const [value, setValue] = useState<string | number | boolean | undefined>('');

    useEffect(() => {
        setValue(props.value || '');
    }, [props.value]);

    const handleChange = (e: SyntheticEvent) => {
        const field = e.target as HTMLInputElement;
        if (!field) {
            return;
        }
        // switch on type
        switch (field.type) {
            case 'checkbox':
                setValue(!!field.checked ? 1 : 0);
                props.onChangeMethod(!!field.checked);
                break;
            case 'radio':
                if (typeof props.value === 'undefined') {
                    return;
                }
                props.onChangeMethod(props.value);
                break;
            default:
                setValue(field.value);
                props.onChangeMethod(field.value);
        }
    }

    if (props.type === 'radio') {
        return (
            <div className="py-1">
                <label className="flex items-center justify-left ">
                    <input type="radio" name={props.name} checked={value === props.activeValue} onChange={handleChange} />
                    <span className="pl-1 leading-none text-xs">
                        {props.label}
                    </span>
                </label>
            </div>
        )
    }
    if (props.type === 'checkbox') {
        return (
            <div className="py-1">
                <label className="flex cursor-pointer">
                    <input className="cursor-pointer hover:scale-125 transition-all" type="checkbox" name={props.name} required={props.required || false} checked={!!value === true} onChange={handleChange} />
                    <span className="pl-2 md:pl-1 leading-1 text-xs ">
                        {props.label ? props.label : props.children}
                    </span>
                </label>
            </div>
        )
    }

    return (
        <div className="w-full">
            <label>
                {props.label ? (<><InputLabel required={props.required && props.required === true}>{props.label}</InputLabel><br /></>) : null}
                <input
                    id={props.id}
                    max={props.max}
                    name={props.name}
                    autoComplete="off"
                    onFocus={props.onFocus}
                    placeholder={props.placeholder || props.label || undefined}
                    required={props.required || false}
                    value={value as string | number | undefined}
                    onChange={handleChange}
                    onKeyUp={props.onKeyPress}
                    type={props.type}
                    className={`
                        mt-1 mb-4 m-auto md:ml-0 md:mr-0 px-3 py-2.5 w-full block rounded 
                        border border-x-gray-300 border-t--gray-300 shadow focus:border-b-primary-500 transition-all
                        focus:ring-0 outline-none text-sm ${props.className ? ' ' + props.className : ''}
                    `}
                />
            </label>
        </div>
    );
}
