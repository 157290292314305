export enum ParticipantAdminState {
    OPEN_FOR_ROUTE_REGISTRATION = 'OPEN_FOR_ROUTE_REGISTRATION'
}

export enum SponsorMode {
    BUSINESS = 'BUSINESS',
    PRIVATE = 'PRIVATE'
}

export enum SponsorLevel {
    EVENT = 'EVENT',
    PARTICIPANT = 'PARTICIPANT'
}

export enum QrCodeType {
    EVENT = 'event',
    EVENT_GROUP = 'event_group',
    EVENT_MULTI_SIGNUP = 'event_multi_signup',
    PARTICIPANT_LIST = 'participant_list',
}

export enum EventType {
    EVENT = 'event',
    EVENT_FIXED_LENGTH = 'event_fixed_length',
}