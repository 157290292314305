import { useEffect, useState } from 'react';
import { useEvent } from '../hooks/useEvent';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { EventCurrentStandingsPage } from '../components/Layout/EventCurrentStandingsPage';
import { sendRequest } from '../services/http';

export const EventCurrentStandingsContainer = () => {
  const { event, eventLoaded, eventStats, eventGroupStats, fetchEventStats } = useEvent();
  const [participants, setParticipants] = useState([]);
  const setDocumentTitle = useDocumentTitle();

  useEffect(() => {
    const fetchParticipantData = () => {
      if (!event) {
        return;
      }
      const cb = (response) => {
        if (response.data.length === 0) {
          return;
        }
        setParticipants(response.data);
      }
      sendRequest({
        method: 'GET',
        endpoint: '/live/' + event.slug,
        callback: cb
      });
    }
    fetchParticipantData();
    window.Echo?.connect()
    window.Echo?.channel("events").listen(".newEvent", (event) => {
      if (event.update === 'success') {
        fetchParticipantData();
        fetchEventStats();
      }
    });
    return () => {
      window.Echo?.disconnect()
      setParticipants([])
    };
  }, [event, eventLoaded]);

  useEffect(() => {
    if (event) {
      setDocumentTitle(`LIVE - ${event.name}`);
    }
  }, [event, eventLoaded]);

  if (!eventLoaded) {
    return null;
  }

  return (
    <EventCurrentStandingsPage
      org={event.org_name}
      participants={participants}
      event={event}
      mainStats={eventStats}
      typesStats={eventGroupStats}
      sortFieldsCycle={['total_meters', 'total_rounds']}
      cycleInterval={10000}
    />
  )
}