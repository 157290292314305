import { Container } from '../UI/Container';
import { FancyImage } from '../UI/FancyImage';
import fallbackImg from '../../images/flbk-run.jpg';
import { SyntheticEvent } from 'react';

export const EventPageHeader = ({ image, children }: {
  image?: string,
  children: React.ReactNode
}) => {

  const handleImageError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = fallbackImg;
  }
  
  return (
    <>
      <div className="
        header relative overflow-hidden border-b-[0rem] border-primary lg:pt-0 md:pt-10 bg-white 
      ">

        <Container noPad className="relative md:pt-2 lg:pt-5 min-h-[80px] pb-5 z-30">

          <div className="lg:flex lg:gap-20 lg:justify-between items-center">
            <div className="w-full lg:order-1 py-12 px-4 md:p-10">
              {children}
            </div>
            {image ? <div className="md:m-auto lg:m-0 md:max-w-md xl:max-w-3xl w-full px-4 md:px-0 order-1 lg:order-2 lg:mb-0 relative z-20">
              <FancyImage imgSrc={`${image}/800x650`} onImageError={handleImageError} />
            </div> : null}

          </div>

        </Container>
      </div>
    </>
  );
}