import { EventInfoBox } from '../Event/EventInfoBox';
import { InfoBox } from './InfoBox';
import { EventInfoBoxProps, DefaultInfoBoxProps } from '../../../types/components/InfoBox';

const components = {
  default: InfoBox,
  event: EventInfoBox
};

export const InfoBoxGrid = ({ items, infoBoxType }: {
  items: (EventInfoBoxProps|DefaultInfoBoxProps)[],
  infoBoxType: 'default' | 'event'
}) => {

  if (!items || items.length === 0) {
    return (<div>Ingenting at vise</div>);
  }
  
  return (
    <div className={`flex flex-wrap -mx-2 items-start gap-y-8 ${items.length < 4 ? 'justify-center' : ''}`}>
      {items.map((item: EventInfoBoxProps|DefaultInfoBoxProps, index: number) => {
        const Box: any = components[infoBoxType] || components.default;

        return <div key={index} className={`w-full px-4 md:w-1/2 ${items.length < 3 ? 'lg:w-1/2 xl:w-1/3' : 'lg:w-1/3 xl:w-1/3'}`}><Box {...item} /></div>;
      })}
    </div>
  );
}