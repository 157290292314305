import { EventGraphData } from "../../../types/components/EventComponents";
import { Container } from "../UI/Container";
import { DataChart } from '../Various/DataChart';

const CollapseIcon = () => {
    return (
        <div className="opacity-70 hover:opacity-100 transition-opacity">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-4 w-4" viewBox="0 0 24 24"><path d="M16.586 19.414l-2.586 2.586v-8h8l-2.586 2.586 4.586 4.586-2.828 2.828-4.586-4.586zm-13.758-19.414l-2.828 2.828 4.586 4.586-2.586 2.586h8v-8l-2.586 2.586-4.586-4.586zm16.586 7.414l2.586 2.586h-8v-8l2.586 2.586 4.586-4.586 2.828 2.828-4.586 4.586zm-19.414 13.758l2.828 2.828 4.586-4.586 2.586 2.586v-8h-8l2.586 2.586-4.586 4.586z" /></svg>
        </div>
    )
}

export const SingleGraphPage = ({ graph, collapseAction }: {
    graph: EventGraphData
    collapseAction: () => void
}) => {
    return (
        <>
            <Container className="pt-10 pb-10 lg:max-w-[70vw]">
                <DataChart
                    title={graph.title}
                    data={graph.data}
                    type={graph.type === 'bar' ? 'bar' : 'line'}
                >
                    <div className="relative translate-y-3 px-3 flex flex-row justify-end h-0 z-10">
                        <button title="Collapse" className="block" onClick={collapseAction}>
                            <CollapseIcon />
                        </button>
                    </div>
                </DataChart>
            </Container>
        </>
    );
}