export const DEFAULT_DOCUMENT_TITLE_SUFFIX = ' | Init Together'
export const DEFAULT_DOCUMENT_TITLE = `Events ${DEFAULT_DOCUMENT_TITLE_SUFFIX}`

export const NOTIFICATION_TYPE_SUCCESS = 'success'
export const NOTIFICATION_TYPE_ERROR = 'error'
export const NOTIFICATION_TYPE_INFO = 'info'
export const NOTIFICATION_TYPE_WARNING = 'warning'

export const PAYMENT_METHOD_MOBILEPAY = 'mobilepay'
export const PAYMENT_METHOD_TRANSFER = 'transfer'
export const PAYMENT_METHOD_CREDIT_CARD = 'credit_card'

export const PROJECT_STATUS_ACTIVE = 1;
export const PROJECT_STATUS_INACTIVE = 0;
export const PROJECT_STATUS_DONE = 2;

export const IMAGE_BASE_URL = 'https://imagedelivery.net/gjfMLhdkfC1OSmwmVETE5w'