import { TopBar } from "../Nav/TopBar";
import { Container } from "../UI/Container";

export const NotFoundPage = () => {
  return (
    <>
      <TopBar />
      <Container topSpacing className="text-center min-h-[20rem] lg:min-h-[40rem] flex flex-col align-center justify-center">
        <h1 className="text-4xl lg:text-6xl mb-4">404</h1>
        <h2 className="text-2xl xl:text-4xl ">
          Siden blev ikke fundet
        </h2>
      </Container>
    </>
  );
}