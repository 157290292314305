import { Container } from "../UI/Container";
import Hero from "./components/Hero";
import lovespring from '../../images/lovespring.png';
import Events from "./components/Events";
import Testimonials from "./components/Testimonials";
import PrimaryFeatures from "./components/PrimaryFeatures";
import { Faqs } from "./components/Faqs";
import { CallToAction } from "./components/CallToAction";
import { SecondaryFeatures } from "./components/SecondaryFeatures";
import { Pricing } from "./components/Pricing";

export const LandingPage = () => {
    return (<>
        <main>
            <Hero />
            <div className="bg-slate-100/70 py-10">
                <Container>
                    <div className="text-center flex flex-col justify-center items-center">
                        <h4 className="header-font">Vi arbejder primært sammen med NGO'en</h4>
                        <a href="https://lovespring.dk/da/skolelob" rel="noreferrer" target="_blank">
                            <img src={lovespring} alt="Lovespring logo" width="300" />
                        </a>
                    </div>

                </Container>
            </div>
            <Events />
            <Testimonials />
            <PrimaryFeatures />
            <Faqs />
            <CallToAction />
            <SecondaryFeatures />
            <Pricing />
            {/*             <Hero />
            <LogoBand />
            <Events />
            <Testimonials />

            <PrimaryFeatures />
            <Faqs />
            <CallToAction />
            <SecondaryFeatures />
            <Pricing />
 */}
        </main>
    </>);
};