import dayjs from "dayjs";
import { Event } from "../../../types/Event";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// create functional component
export const EventIsOverBanner = ({ event, link, clickCallback }: { event: Event, link?: string, clickCallback?: () => void }) => {
    const { t } = useTranslation('events');

    return (
        <div className="relative bg-indigo-600">
            <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
                <div className="sm:px-16 text-center">
                    <p className="font-medium text-white">
                        <span>{t('event_page.finished_event_at', { date: dayjs(event.event_end_date).format('DD-MM-YYYY') })}</span>
                        {link ?
                            <span className="block sm:ml-2 sm:inline-block">
                                <Link onClick={() => clickCallback ? clickCallback() : null} to={link} className="font-bold text-white underline hover:no-underline">
                                    {t('see_results')}
                                    <span aria-hidden="true"> &rarr;</span>
                                </Link>
                            </span>
                        : null}
                    </p>
                </div>
            </div>
        </div>
    );
};
