import { useState } from "react";
import { SignupFormValues } from "../../../types/Participant";
import { Button } from "../UI/Button";
import { InputField } from "../UI/InputField";
import { useTranslation } from "react-i18next";
import { Loading } from "../UI/Loading";

export const SignupForm = ({
  doSubmit,
  isSubmitting,
}: {
  doSubmit: (formValues: SignupFormValues) => void;
  isSubmitting?: boolean;
}) => {
  const { t } = useTranslation(["event_participants", "common", "errors"]);
  const [values, setValues] = useState<SignupFormValues>({});
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doSubmit(values);
  };

  return (
    <>
      <form
        onSubmit={submit}
        className={`${isSubmitting ? "pointer-events-none opacity-60" : ""}`}
      >
        <div className="md:grid md:grid-cols-2 gap-4">
          <InputField
            required
            name="first_name"
            type="text"
            label="Fornavn"
            onChangeMethod={(value) =>
              setValues({ ...values, first_name: value as string })
            }
          />
          <InputField
            required
            name="last_name"
            type="text"
            label="Efternavn"
            onChangeMethod={(value) =>
              setValues({ ...values, last_name: value as string })
            }
          />
        </div>
        <div className="md:grid md:grid-cols-3 gap-4">
          <div className="col-span-2">
            <InputField
              required
              name="email"
              type="email"
              label="Email"
              onChangeMethod={(value) =>
                setValues({ ...values, email: value as string })
              }
            />
          </div>
          <div className="col-span-1">
            <InputField
              className="col-span-1"
              name="phone_number"
              type="number"
              label="Telefon"
              onChangeMethod={(value) =>
                setValues({ ...values, phone_number: value as string })
              }
            />
          </div>
        </div>
        <InputField
          name="accept_privacy_policy"
          onChangeMethod={(value) =>
            setValues({ ...values, accept_privacy_policy: value as boolean })
          }
          type="checkbox"
          value={values.accept_privacy_policy || false}
          required
        >
          {t("event_participants:sponsor.form.i_consent")}{" "}
          <a
            href="/privatlivspolitik"
            className="underline hover:no-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("event_participants:sponsor.form.privacy_policy")}
          </a>
        </InputField>
        <Button useBtn={true} big type="submit" className="mt-5 flex gap-4 items-center">
          {isSubmitting ? <Loading widthClass="w-1" heightClass="h-4" /> : null}
          Tilmeld
        </Button>
      </form>
    </>
  );
};
