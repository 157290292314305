import { ProfileCardList } from '../ProfileCard/ProfileCardList';
import { Container } from '../UI/Container';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import { FancyAlert } from '../UI/FancyAlert';
import { Event } from '../../../types/Event';
import { Participant } from '../../../types/Participant';
import { EventIsOverBanner } from '../Event/EventIsOverBanner';
import { Button } from '../UI/Button';
import { useMemo } from 'react';
import { eventSponsorLink } from '../../services/events';

export const EventParticipantsPage = ({ participants, participantsLoaded, itemsPerPage, mainImage, event, isActive }: {
  participants: Participant[],
  participantsLoaded: boolean,
  itemsPerPage: number,
  mainImage: string,
  event: Event,
  isActive: boolean
}) => {
  const { t } = useTranslation(['events', 'common', 'event_participants']);

  const sponsorLink = useMemo(() => {
    if (!event) {
      return null;
    }

    return eventSponsorLink(event);
  }, [event]);
  const isPast = useMemo(() => event && dayjs().isAfter(dayjs(event.event_end_date)), [event]);

  if (!event) {
    return null;
  }

  return (
    <>
      {(dayjs().isAfter(dayjs(event.event_end_date))) ? <EventIsOverBanner event={event} link={`/${event.org_slug}/${event.slug}#results`} /> : null}
      <Container >
        <div className="text-center w-full pt-10 lg:mb-0 pb-4">
          <h1 className="text-4xl mb-4 lg:text-5xl">{t('event_participants:page.title')}</h1>
          <p className="text-base lg:text-lg header-font">{event.name}</p>
          {(!isPast && sponsorLink) && <Button className="mt-4" href={sponsorLink}>{t('event_participants:sponsor_this')}</Button>}
        </div>
      </Container>
      <Container className="pt-10 mb-20 pb-10 bg-gray-50">
        {event.is_anonymized_data ? <FancyAlert extraClasses="mb-6" type="info" title={t('event_participants:anon_title')} description={t('event_participants:anon_desc')} /> : null}
        <ProfileCardList title={event.name} itemsPerPage={itemsPerPage || 16} items={participants} loaded={participantsLoaded} />
      </Container>
    </>
  );
}