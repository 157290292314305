import { EventStatsPage } from "../components/Layout/EventStatsPage"
import { NotFoundPage } from "../components/Layout/NotFoundPage";
import { Loading } from "../components/UI/Loading";
import { useEvent } from "../hooks/useEvent";
import { useState, useEffect, useMemo } from 'react';
import { sendRequest } from "../services/http";
import { EventGraphData } from "../../types/components/EventComponents";
import { default as EchoType } from "laravel-echo";
import { SingleGraphPage } from "../components/Layout/SingleGraphPage";

/**
 * TODO refactor fullscreen functionality to use router instead!! 
 */
export const EventStatsPageContainer = () => {
  const { event, eventLoaded } = useEvent({ withDocTitle: true });
  const [statsLoaded, setStatsLoaded] = useState(false);
  const [graphObjects, setGraphObjects] = useState<EventGraphData[]>([]);
  const [hiddenGraphIndexes, setHiddenGraphIndexes] = useState<number[]>([]);
  const [fullscreenGraphIndex, setFullscreenGraphIndex] = useState<number | null>(null);

  useEffect(() => {
    const winAlias: { Echo: EchoType } = window as any;

    const cleanup = () => {
      setStatsLoaded(false);
      setGraphObjects([]);
      winAlias.Echo.disconnect();
    };

    if (eventLoaded === false) {
      return cleanup;
    }

    const fetchStats = () => {
      if (event === null) {
        return;
      }
      sendRequest({
        method: 'GET',
        endpoint: `stat/${event.slug}/graphs`,
        callback: (response) => {
          if (response.data.length === 0) {
            setStatsLoaded(true);
            return;
          }
          setStatsLoaded(true);
          setGraphObjects(response.data);
        },
        errorCallback: (error) => {
          console.log(error);
        }
      });
    }

    fetchStats();
    // hook up websockets
    winAlias.Echo.connect()
    winAlias.Echo.channel("events").listen(".newEvent", (event: { update: string }) => {
      if (event.update === 'success') {
        fetchStats();
      }
    });
    return cleanup;
  }, [eventLoaded, event]);

  // when dismount we have to reset the fullscreenGraphIndex, silly react
  useEffect(() => {
    return () => {
      setFullscreenGraphIndex(null);
    }
  }, []);

  const visibleGraphObjects = useMemo(() => {
    return graphObjects.filter((graphObject, index) => {
      return !hiddenGraphIndexes.includes(index);
    });
  }, [graphObjects, hiddenGraphIndexes]);

  if (!eventLoaded || !statsLoaded) {
    return <Loading />
  }

  if (!event) {
    return <NotFoundPage />
  }

  if (fullscreenGraphIndex !== null) {
    return <SingleGraphPage graph={graphObjects[fullscreenGraphIndex]} collapseAction={() => setFullscreenGraphIndex(null)}/>
  }

  return <EventStatsPage 
    graphs={graphObjects}
    visibleGraphs={visibleGraphObjects} 
    hiddenGraphIndexes={hiddenGraphIndexes} 
    setHiddenGraphIndexes={setHiddenGraphIndexes}
    setFullscreenGraphIndex={setFullscreenGraphIndex}
  />
}