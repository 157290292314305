export const NavButton = ({ open }: { open: boolean }) => {
  return (
    <a className={`flex flex-col cursor-pointer  ${open ? 'text-white p-4 hover:rotate-90' : 'text-primary'}`}>
      {open === true ? <svg fill="currentColor" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
        : (<svg fill="currentColor" className="w-9 h-6" viewBox="0 0 28 18" version="1.1">
          <path d="M5,16 L28,16 L28,18 L5,18 L5,16 Z M0,8 L28,8 L28,10 L0,10 L0,8 Z M0,0 L28,0 L28,2 L0,2 L0,0 Z"></path>
        </svg>)}
    </a>
  );
}