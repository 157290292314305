import { useEffect, useState, useMemo } from 'react';
import { Loading } from '../UI/Loading';
import { ProfileCard } from '../ProfileCard/ProfileCard';
import { ProfileCardListItem } from '../ProfileCard/ProfileCardListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faAnglesLeft, faTableCellsLarge, faList } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import { Participant } from '../../../types/Participant';
import { useQuery } from '../../hooks/useQuery';
import { formatUnitsRan } from '../../services/participants';

/**
 * REFACTOR THIS MESS LOL
 * 
 * @param props 
 * @returns 
 */
export const ProfileCardList = (props: {
  itemsPerPage: number,
  items: Participant[],
  loaded: boolean,
  title?: string
}) => {
  const [offset, setOffset] = useState(0);
  const [activeItems, setActiveItems] = useState<Participant[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [listMode, setListMode] = useState<'grid' | 'list'>('list');
  const query = useQuery();
  const { t } = useTranslation(['event_participants']);

  const itemsPerPage = props.itemsPerPage || 4;
  const lastPageIndex = useMemo(() => props.items.length - itemsPerPage, [props.items, itemsPerPage]);

  useEffect(() => {
    // listmode from localstorage
    const localListMode = localStorage.getItem('listMode');
    if (localListMode) {
      setListMode(localListMode as 'grid' | 'list');
    }
  }, []);

  useEffect(() => {
    // when list mode changes, save to localstorage
    localStorage.setItem('listMode', listMode);
  }, [listMode]);

  useEffect(() => {
    // handle setting page from query params
    const queryOffset = query.get('offset') ? parseInt(query.get('offset') as string) : 0;
    setOffset(queryOffset);
    return () => setOffset(0);
  }, [query]);

  useEffect(() => {
    setActiveItems(handleOffset(props.items, offset, itemsPerPage));
    return () => setActiveItems([]);
  }, [props.items, offset, itemsPerPage]);

  useEffect(() => {
    if (searchTerm && searchTerm !== '') {
      setOffset(0); 
      const filteredItems = props.items.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()) || (item.startnr && parseInt(item.startnr) === parseInt(searchTerm)));
      setActiveItems(filteredItems);
    } else {
      setActiveItems(handleOffset(props.items, offset, itemsPerPage));
    }
  }, [searchTerm, props.items, offset, itemsPerPage]);

  const handleOffset = (items: Participant[], offset: number, pageSize: number) => items.slice(offset, offset + pageSize);

  const handleNextPage = () => {
    const itemsPerPage = props.itemsPerPage || 4;
    let newOffset = offset + itemsPerPage;
    if (offset >= lastPageIndex) {
      return;
    }
    setOffset(newOffset);
    addOffsetToQueryParams(newOffset);
  }

  const handlePrevPage = () => {
    let newOffset = offset - itemsPerPage;
    if (newOffset <= 0) {
      setOffset(0);
      addOffsetToQueryParams(0);
      return;
    }
    setOffset(newOffset);
    addOffsetToQueryParams(newOffset);
  }

  const addOffsetToQueryParams = (offset: number) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('offset', offset.toString());
    window.history.pushState({}, '', newUrl);
  }

  if (!props.loaded) {
    return <Loading />;
  }

  return (
    <>
      <div className="md:flex flex-row items-end justify-between">
        <div>
          <h2 className="text-xl md:text-xl xl:text-2xl font-bold tracking-wide">{t('event_participants:page.list_title')}: {props.items.length}</h2>
          <p className="text-sm">
            {props.title}
            <br />
            {t('event_participants:page.list_tag_line')}
          </p>
        </div>
        <div className="flex flex-col mt-4 md:mt-0 md:flex-row gap-2">
          <div className="flex-row items-center gap-2 hidden md:flex">
            <FontAwesomeIcon onClick={() => setListMode('grid')} icon={faTableCellsLarge} className={`${listMode !== 'grid' ? 'opacity-50 cursor-pointer' : null} hover:opacity-100`} />
            <FontAwesomeIcon onClick={() => setListMode('list')} icon={faList} className={`${listMode !== 'list' ? 'opacity-50 cursor-pointer' : null} hover:opacity-100`} />
          </div>
          <div className="relative text-gray-600  max-w-xs">
            {searchTerm ? <div className="absolute bottom-full text-white text-xs block left-0 leading-none bg-slate-500 p-2 rounded">
              {t('event_participants:page.results_found', { count: activeItems.length })}
            </div> : null}
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              className="focus:outline-1 outline-zinc-300 transition-all border-0 special-rounded border-gray-300 bg-white h-10 px-5 pr-16 w-full md:w-[250px] text-sm"
              type="search"
              name="search"
              placeholder={t('event_participants:page.search_field_placeholder', { count: props.items.length })}
            />
            <button type="submit" className="absolute right-0 top-0 mt-3 mr-4">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <hr className="mb-6 mt-3 border-gray-400" />
      {listMode === 'grid' ? (
        <div className="grid lg:grid-cols-2 gap-4 lg:gap-10">
          {activeItems.length ? activeItems.map((item, i) => (<div key={i} className="max-w-4xl w-full m-auto">
            <ProfileCard
              name={item.name}
              stats={item.stats || []}
              imgSrc={item.main_img || null}
              startnr={item.startnr || null}
              profileLink={`${item.profileLink}?offset=${offset}`}
              sponsorLink={item.is_anonymized_data === true ? null : `${item.sponsorLink}?offset=${offset}`}
              anonymized={item.is_anonymized_data === true}
            />
          </div>)) : <p className="text-center text-gray-500">{t('event_participants:page.no_participants')}</p>}
        </div>
      ) : null}
      {listMode === 'list' ? (
        <div className="flex flex-col gap-2 ">
          {activeItems.length ? activeItems.map((item, i) => (<div key={i} className="max-w-4xl w-full m-auto">
            <ProfileCardListItem
              name={item.name}
              first_stat={{ label: t('event_participants:units_ran', { units: 'Km' }), value: formatUnitsRan(item.total_meters) }}
              second_stat={(item?.public_donations === undefined || item?.public_donations === true) ? { label: t('event_participants:total_collected_in_currency', { currency: 'kr' }), value: item.total_donation } : null}
              imgSrc={item.tiny_thumb_img || null}
              startnr={item.startnr || null}
              profileLink={`${item.profileLink}?offset=${offset}`}
              sponsorLink={item.sponsorLink ? `${item.sponsorLink}?offset=${offset}` : undefined}
              anonymized={item.is_anonymized_data === true}
            />
          </div>)) : <p className="text-center text-gray-500">{t('event_participants:page.no_participants')}</p>}
        </div>
      ) : null}
      <div className="text-center mt-16 mb-4 w-2/4 max-w-full m-auto">
        <div className="md:w-56 flex flex-row justify-between items-center m-auto mb-2">
          <FontAwesomeIcon onClick={handlePrevPage} icon={faAnglesLeft} className="cursor-pointer ml-1 mr-1 opacity-70 hover:opacity-100" />
          <FontAwesomeIcon onClick={handleNextPage} icon={faAnglesRight} className="cursor-pointer m-1 mr-1 opacity-70 hover:opacity-100" />
        </div>
      </div>
    </>
  );
}