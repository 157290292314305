import header from '../images/running.jpg';
import { TopBar } from "../components/Nav/TopBar";
import { Container } from "../components/UI/Container";
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { FancyImage } from "../components/UI/FancyImage";
import { useTranslation } from 'react-i18next';

export const ContactPageContainer = () => {
  const { t } = useTranslation('content');

  return (<>
    <TopBar />
    <Container>
      <div className="py-12 flex flex-col lg:flex-row gap-10 max-w-6xl m-auto items-center">
        <div className="">
          <h2 className="text-3xl lg:text-5xl">{t('contact_page.title')}</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600 max-w-xl">
          {t('contact_page.desc')}
          </p>
          <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
            <div className="flex gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Adresse</span>
                <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
              </dt>
              <dd>
                Havkærvænget 186
                <br />
                8381 Tilst
                <br />
                CVR 44 64 54 83
              </dd>
            </div>
            <div className="flex gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Email</span>
                <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
              </dt>
              <dd>
                <a className="hover:text-gray-900" href="mailto:info@init-together.dk">
                  info@init-together.dk
                </a>
              </dd>
            </div>
          </dl>
        </div>
        <div className="max-w-xl">
          <FancyImage imgSrc={header} altText="" />
        </div>
      </div>

    </Container>

  </>)
}