import { CompanyLogoWide } from "../SVG/Logos";
import { Container } from "../UI/Container";
import { NavMenu } from "./NavMenu";
import { useNav } from '../../hooks/useNav';
import { useEvent } from "../../hooks/useEvent";
import { NavLink, useLocation } from "react-router-dom";
import { LanguageSwitch } from "./LanguageSwitch";

export const TopBar = () => {
    const { event } = useEvent();
    const location = useLocation();
    const { navItems, navItemsLoaded, eventPrefix } = useNav();

    return (
        <div className="pt-4 pb-4 bg-white shadow-md lg:shadow-sm z-40 relative">
            <Container className="flex flex-row items-center justify-between text-primary">
                <div className="flex flex-row gap-8 items-center">
                    <NavLink to={`/`}>
                        <CompanyLogoWide className="w-[120px] md:w-[180px] lg:w-[200px] " />
                    </NavLink>
                    <NavLink to={eventPrefix ? eventPrefix : '/'} className="hidden sm:block text-lg font-semibold tracking-wider">
                        {event ? event.org_name : null}
                    </NavLink>
                    {(location.pathname === '/' || location.pathname === '/events') && (
                        <div className="hidden md:flex gap-x-5 flex-wrap">
                            <a className="rounded-lg leading-none py-1 text-sm text-slate-700 hover:text-primary-600" href="/#motivation">Skab sammenhold</a>
                            <a className="rounded-lg leading-none py-1 text-sm text-slate-700 hover:text-primary-600" href={`/#host`}>Afhold et løb</a>
                            <a className="rounded-lg leading-none py-1 text-sm text-slate-700 hover:text-primary-600" href={`/#ngo`}>For NGO'er</a>
                            <a className="rounded-lg leading-none py-1 text-sm text-slate-700 hover:text-primary-600" href={`/#about`}>Om os</a>
                        </div>
                    )}
                </div>
                <div className="flex gap-4">
                    <NavLink to={'/events'} className="hidden md:inline-block tracking-wider header-font mr-4 sm:block text-sm font-normal bg-slate-400/10 hover:bg-slate-400/20 px-2 py-1.5 rounded uppercase leading-none">
                        Events
                    </NavLink>
                    <div className="hidden sm:block scale-110 "><LanguageSwitch /></div>
                    {navItemsLoaded ? <NavMenu open={false} items={navItems} /> : null}
                </div>
            </Container>
        </div>
    );
}