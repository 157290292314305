import { useRef, useMemo } from "react";
import { EventGroup, Project, Event, EventResult, Ngo } from "../../../types/Event";
import { EventNgoBox } from "../Event/EventNgoBox";
import { EventPageHeader } from "../Event/EventPageHeader";
import { EventDescription } from '../Event/EventDescription';
import { Container } from "../UI/Container";
import { useTranslation } from "react-i18next";
import { InfoBoxGrid } from "../Various/InfoBoxGrid";
import { Button } from "../UI/Button";
import { DefaultInfoBoxProps, EventInfoBoxProps } from "../../../types/components/InfoBox";
import { EventStatsBox } from "../Event/EventStatsBox";

export const EventGroupPage = ({ eventGroup, mainImage, projects, events, results, ngoList }: {
    eventGroup: EventGroup
    mainImage: string
    projects: Project[]
    events: Event[]
    results?: EventResult
    ngoList?: Ngo[]
}) => {
    const { t } = useTranslation('events');
    const eventsContainerRef = useRef<HTMLDivElement>(null);
    const projectsContainerRef = useRef<HTMLDivElement>(null);

    const eventInfoboxes: EventInfoBoxProps[] = useMemo(() => {
        if (!events || events.length === 0) return [];
        return events.map(event => ({ event: event }));
    }, [events]);

    const projectInfoBoxes: DefaultInfoBoxProps[] = useMemo(() => {
        if (!projects || projects.length === 0) return [];
        return projects.map(project => ({
            image: project.img,
            title: project.name,
            finalGoalAmount: project.price,
            currentGoalAmount: project.donations || 0,
            description: project.description
        }));
    }, [projects]);

    const scrollToEvents = () => {
        if (eventsContainerRef.current !== null) {
            eventsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToProjects = () => {
        if (projectsContainerRef.current !== null) {
            projectsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <>
            <EventPageHeader image={mainImage}>
                <div className="w-full flex flex-col items-center justify-center overflow-hidden ">
                    <div className="text-center  rounded-xl relative z-10">
                        <h1 className="text-3xl mb-2 lg:text-[34px] xl:text-[45px] xl:mb-4 xl:[line-height:1.1]">{eventGroup.name}</h1>
                        <p className="text-base lg:text-lg xl:text-2xl mb-4 text-gray-900/80 font-semibold header-font">
                            {t('event_page.for_good_cause')}
                        </p>
                        <Button big onClick={scrollToEvents}>
                            {t('event_holder.go_to_events')}
                        </Button>
                    </div>
                </div>
            </EventPageHeader>
            {eventGroup.description ? <EventDescription title={eventGroup.name}>
                {eventGroup.description}
            </EventDescription> : null}
            <EventNgoBox ngoList={ngoList || []} />
            {results ? <div className=" bg-white">
                <EventStatsBox
                    total_meters={results.total_meters}
                    donations={results.donations}
                    sponsor_count={results.sponsor_count}
                    rounds={results.rounds}
                />
            </div> : null}
            <Container className="my-8 pt-8 pb-8 flex flex-col gap-8 ">
                <div className="text-center" ref={eventsContainerRef}>
                    <h2 className="text-3xl lg:text-4xl font-semibold mb-2 text-primary">
                        {t('event_group_page.events_title')}
                    </h2>
                    <p className="mb-5 text-xl text-gray-500 max-w-3xl m-auto">
                        {t('event_group_page.events_desc')}
                    </p>
                    <Button onClick={scrollToProjects}>
                        {t('event_group_page.go_to_projects')}
                    </Button>
                    <hr className="mb-5 mt-10 border-gray-300 m-auto lg:max-w-6xl" />
                </div>
                <div className="text-center">
                    {eventInfoboxes.length === 0 ? <p className="header-font">{t('no_events_yet')}</p> : null}
                    {eventInfoboxes.length > 0 ? <InfoBoxGrid infoBoxType="event" items={eventInfoboxes} /> : null}
                </div>
            </Container>
            <div className="bg-white py-10">
                <Container>
                    <div className="text-center pt-4" ref={projectsContainerRef}>
                        <h2 className="text-3xl lg:text-4xl font-semibold mb-2 text-primary">
                            {t('event_group_page.supports_the_following_projects')}
                        </h2>
                        <p className="mb-2 text-xl text-gray-500">{t('event_page.ty_sponsors')}</p>
                        <hr className="mb-10 mt-10 border-gray-300 m-auto lg:max-w-6xl" />
                        <div>
                            {projectInfoBoxes.length === 0 ? <p className="header-font">{t('no_projects_yet')}</p> : null}
                            {projectInfoBoxes.length > 0 ? <InfoBoxGrid infoBoxType="default" items={projectInfoBoxes} /> : null}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}