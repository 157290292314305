import { useState, useEffect, useMemo } from 'react';
import { EventHolder } from '../components/Layout/EventHolder';
import { sendRequest } from '../services/http';
import { Loading } from '../components/UI/Loading';
import { TopBar } from '../components/Nav/TopBar';
import dayjs from 'dayjs';
import { buildImageLink } from '../services/assets';

export const EventHolderContainer = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const pastEvents = useMemo(() => {
    if (allEvents.length === 0) return [];

    const now = dayjs();
    return allEvents
      .filter(event => now.isAfter(dayjs(event.event_end_date)))
      .sort((a, b) => {
        // sort newest first
        return dayjs(a.event_start_date).isBefore(dayjs(b.event_start_date)) ? 1 : -1;
      })
      .map(event => ({
        ...event,
        img: event.cloudflare_img_uuid ? buildImageLink(event.cloudflare_img_uuid) : event.img,
      }));
  }, [allEvents]);

  const upcomingEvents = useMemo(() => {
    if (allEvents.length === 0) return [];
    console.log(allEvents);
    const now = dayjs();
    return allEvents
      .filter(event => now.isBefore(dayjs(event.event_end_date)))
      .sort((a, b) => {
        return dayjs(a.event_start_date).isBefore(dayjs(b.event_start_date)) ? -1 : 1;
      });
  }, [allEvents]);

  useEffect(() => {
    const cb = (response) => {
      // TODO handle errors
      setAllEvents(response.data);
      setIsLoading(false);
    }
    sendRequest({
      method: 'GET',
      endpoint: '/frontpage/list',
      callback: cb
    });
    return () => setIsLoading(true)
  }, []);

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <TopBar />
      <EventHolder upcomingEvents={upcomingEvents} pastEvents={pastEvents} />
    </>
  );
}