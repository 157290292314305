import { Event } from "../../../types/Event";
import { Participant, ParticipantAdmin } from "../../../types/Participant";
import { ParticipantAdminState } from "../../variables/enum";
import { TopBar } from "../Nav/TopBar";
import { Container } from "../UI/Container";
import { FancyImage } from "../UI/FancyImage";
import RoundsEditParticipantTable from "./RoundsEditParticipantTable";

export const RegisterRoundsPage = ({ event, participants, submitParticipantRounds, participantAdmin }: {
    event: Event,
    participantAdmin: ParticipantAdmin,
    participants: Partial<Participant>[],
    submitParticipantRounds: (participantId?: string, rounds?: number) => void
}) => {
    return (
        <>
            <TopBar />
            <Container topSpacing className="flex items-center justify-between">
                <div>
                    <p className="text-sm mb-2 opacity-60">{event.name}</p>
                    <h1 className="mb-5">Dine deltagere</h1>
                    <p className="max-w-2xl">
                        Her kan du se de deltagere, som du har oprettet til begivenheden. 
                        <br /> 
                        Du vil løbende kunne se hvor mange sponsorer de har, og også finde et link hvormed sponsorer kan oprette sig selv.
                        <br /><br/>
                        For at vi kan regne ud hvilke beløb vi skal opkræve af sponsorerne, skal vi vide hvordan det gik med deltagerne, til begivenheden.
                        <br /><br />

                        {participantAdmin.state === ParticipantAdminState.OPEN_FOR_ROUTE_REGISTRATION ?
                            'I øjeblikket kan du ikke indtaste omgange for dine deltagere endnu.'
                        : 'Du bedes indtaste hvor mange omgange hver deltager har løbet.'}

                    </p>
                </div>
                <div className="max-w-md max-h-64 hidden lg:flex">
                    {event.img ? <FancyImage imgSrc={`${event.img}/800x650`} /> : null}
                </div>
            </Container>
            <Container topSpacing>
                <RoundsEditParticipantTable participants={participants} clickHandler={submitParticipantRounds} />
            </Container>
        </>
    );
}