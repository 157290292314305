import {useState, useEffect} from 'react';
import { DEFAULT_DOCUMENT_TITLE, DEFAULT_DOCUMENT_TITLE_SUFFIX } from '../variables/constants';

export const useDocumentTitle = () => {
    const [title, setTitle] = useState<string>(DEFAULT_DOCUMENT_TITLE);
    
    useEffect(() => {
        document.title = title;
        if (title !== DEFAULT_DOCUMENT_TITLE) {
            document.title += ` ${DEFAULT_DOCUMENT_TITLE_SUFFIX}`;
        }

        return () => {
            document.title = DEFAULT_DOCUMENT_TITLE;
        }
    }, [title]);
    
    return setTitle;
}