export const Route = ({ classes }: {
    classes?: string
}) => {
    return (
        <svg className={classes} width="50" height="50" viewBox="0 0 55 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="18.1553" y="21.9242" width="6.05716" height="6.05716" rx="3.02858" transform="rotate(134.495 18.1553 21.9242)" stroke="currentColor" strokeWidth="2" />
            <rect x="31.0981" y="32.5182" width="6.05716" height="6.05717" rx="3.02858" transform="rotate(-45.5047 31.0981 32.5182)" stroke="currentColor" strokeWidth="2" />
            <path d="M33.24 32.4994L28.6741 32.5396C26.7885 32.5562 25.8457 32.5645 25.2548 31.9839C24.6639 31.4033 24.6556 30.4605 24.639 28.575L24.6151 25.8675C24.5985 23.9819 24.5902 23.0391 23.9993 22.4585C23.4084 21.8779 22.4656 21.8862 20.5801 21.9028L16.0141 21.9431" stroke="currentColor" strokeWidth="2" />
            <path d="M14.7646 12.3036V5.42589C14.7646 5.10933 14.7646 4.95105 14.8685 4.87622C14.9723 4.80139 15.1224 4.85144 15.4228 4.95155L24.6988 8.04356C25.2871 8.23967 25.5813 8.33772 25.5813 8.51791C25.5813 8.6981 25.2871 8.79615 24.6988 8.99225L14.7646 12.3036ZM14.7646 12.3036V17.0358V17.9822" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <path d="M35.9644 22.9037V16.026C35.9644 15.7094 35.9644 15.5512 36.0682 15.4763C36.172 15.4015 36.3222 15.4515 36.6225 15.5516L45.8985 18.6437C46.4868 18.8398 46.781 18.9378 46.781 19.118C46.781 19.2982 46.4868 19.3962 45.8985 19.5923L35.9644 22.9037ZM35.9644 22.9037V27.6359V28.5823" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </svg>

    );
};