import { useContext, useState } from 'react';
import { SponsorFormSuccessResponse, SponsorFormValues } from '../../types/components/SponsorParticipantPage';
import { I18nContext } from 'react-i18next';
import { sendRequest } from '../services/http';
import { Event } from '../../types/Event';
import { AxiosError, AxiosResponse } from 'axios';

export const useCheckoutForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successResponse, setSuccessResponse] = useState<SponsorFormSuccessResponse | null>(null);
    const { i18n } = useContext(I18nContext);
    const [errors, setErrors] = useState<string[]>([]);

    const submitCheckoutForm = (formValues: SponsorFormValues, event: Event) => {
        setIsSubmitting(true);
        const payload = preparePayload(formValues);

        if (payload.accept_privacy_policy !== true) {
            setErrors(['accept_privacy_policy']);
            setIsSubmitting(false);
            return;
        }

        sendRequest({
            method: 'POST',
            endpoint: `event/get/${event.slug}/sponsor/create`,
            payload: payload,
            callback: succesCallback,
            errorCallback: errorCallback
        });
    }

    const preparePayload = (formValues: SponsorFormValues) => {
        return {
            ...formValues,
            donation_fixed: formValues.donation_fixed ? Number(formValues.donation_fixed) : 0,
            donation_km: formValues.donation_km ? Number(formValues.donation_km) : 0,
            locale: i18n.language,
        };
    }

    const succesCallback = (response: AxiosResponse) => {
        setSuccessResponse(response.data);
        setIsSubmitting(false);
    }

    const errorCallback = (error: AxiosError) => {
        console.log(error.response?.data);
        setIsSubmitting(false);
        if (!error.response) {
            return;
        }
        // TODO messages
        if (error.response.status === 400) {
            setErrors(['There was an error with your submission. Please check the form and try again.']);
        }
        if (error.response.status === 500) {
            setErrors(['There was an error with your submission. Please check the form and try again.']);
        }
    };

    return {
        submitCheckoutForm: submitCheckoutForm,
        checkoutErrors: errors,
        checkoutFormIsSubmiting: isSubmitting,
        checkoutFormSuccessResponse: successResponse,
        resetCheckoutErrors: () => setErrors([]),
    }
}