import { useTranslation } from "react-i18next"
import { STEP_CONTACT_INFO } from "../../variables/checkout_form"
import { DropdownField } from "../UI/DropdownField"
import { InputField } from "../UI/InputField"
import { CheckOutFormStepHeading } from "./CheckOutFormStepHeading"
import { CheckOutFormStepDesc } from "./CheckoutFormStepDesc"
import { useContext } from "react"
import { CheckOutFormContext } from "../../context/CheckOutFormContext"

export const CheckOutFormPrivateContact = () => {
    const { t } = useTranslation(['event_participants', 'checkout_form']);
    const formContext = useContext(CheckOutFormContext);
    
    return (
        <>
            <CheckOutFormStepHeading>{t('checkout_form:contact_step_private.heading')}</CheckOutFormStepHeading>
            <CheckOutFormStepDesc>
                {t('checkout_form:contact_step_private.desc')}
                <br />
                {t('checkout_form:contact_step_private.desc_2')}
            </CheckOutFormStepDesc>
            <div className="flex flex-row gap-2 mt-5">
                <InputField 
                    name="first_name" 
                    onFocus={(e) => formContext.activateStepsOnFocus(e, STEP_CONTACT_INFO)} 
                    onChangeMethod={(val) => formContext.handleFieldChange('first_name', val)} 
                    type="text" 
                    label={t('event_participants:sponsor.form.first_name')} 
                    placeholder={t('event_participants:sponsor.form.first_name_placeholder')} 
                    required />
                <InputField name="last_name" onChangeMethod={(val) => formContext.handleFieldChange('last_name', val)} type="text" label={t('event_participants:sponsor.form.surname')} placeholder={t('event_participants:sponsor.form.surname_placeholder')} required />
            </div>
            <div className="flex flex-row gap-2 ">
                <InputField name="email" onChangeMethod={(val) => formContext.handleFieldChange('email', val)} type="email" label={t('event_participants:sponsor.form.email')} placeholder={t('event_participants:sponsor.form.email_placeholder')} required />
                <InputField name="phone_number" onChangeMethod={(val) => formContext.handleFieldChange('phone_number', val)} type="number" label={t('event_participants:sponsor.form.phone')} placeholder={t('event_participants:sponsor.form.phone_placeholder')} required />
            </div>
            <div className="mt-2">
                <DropdownField
                    emptyDefault={formContext.internalFormState.where_are_you_from === null}
                    value={formContext.internalFormState.where_are_you_from}
                    label={t('event_participants:sponsor.form.where_are_you_from_label')}
                    onChangeMethod={(val) => formContext.handleFieldChange('where_are_you_from', val)}
                    options={[{ label: t('event_participants:sponsor.form.where_are_you_from.dk'), value: 'dk' }, { label: t('event_participants:sponsor.form.where_are_you_from.other'), value: 'other' }]}
                    required
                />
            </div>
{/*             <div className="mt-2">
                <InputField type="checkbox" value={formContext.internalFormState.is_public_sponsor_name} name="is_public_sponsor_name" onChangeMethod={(val) => formContext.handleFieldChange('is_public_sponsor_name', val)} label={t('checkout_form:contact_step_private.is_public_sponsor_name')}></InputField>
            </div> */}
        </>
    )
}