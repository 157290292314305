import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const HighlightedParticipant = ({ name, rank, main_img, small, highlightedStat, highlightedStatValue }: {
  name: string
  rank: number
  main_img?: string
  small?: boolean
  highlightedStat?: string
  highlightedStatValue?: number
}) => {
  const { t } = useTranslation(['events']);

  const formattedStatValue = useMemo(() => {
    if (highlightedStatValue && highlightedStat === 'total_meters') {
      return new Intl.NumberFormat('da-DK', {
        style: 'unit',
        unit: 'kilometer',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(highlightedStatValue / 1000);
    }
    return highlightedStatValue;
  }, [highlightedStatValue, highlightedStat]);

  const formattedStatLabel = useMemo(() => {
    return highlightedStat === 'total_meters' ? t('events:number_of_units', { units: "km" }) : t('events:number_of_rounds')
  }, [highlightedStat]);

  return (
    <div className={`transition-all header-font origin-center text-center relative ${small ? 'scale-75 z-0 opacity-90 hover:scale-100 hover:z-50' : '-ml-10 -mr-10 z-10'}`}>
      <div className={`leading-none mb-2 text-primary ${small ? 'text-[25px]' : 'text-[45px]'}`}>
        #0{rank}
      </div>
      <div className={`shadow-md rounded-md p-2 text-gray-800 bg-gray-50/90 w-[150px] flex flex-col items-center`}>
        <div className={`mt-2 mb-3 leading-none ${small ? 'text-sm' : ' text-lg'}`}>
          {name}
        </div>
        {main_img ? <div className={`overflow-hidden rounded-full h-[120px] w-[120px] text-center bg-gray-300 flex justify-center`}>
          <img src={main_img} alt={name} className="block" />
        </div> : null}
        {formattedStatValue ? <div className="flex flex-col text-sm leading-none pt-2 mt-2 border-t w-full border-gray-300">
          <span className="">{formattedStatLabel}</span>
          <span className="text-2xl">{formattedStatValue}</span>
        </div> : null}
      </div>
    </div>
  );
}