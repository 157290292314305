import { useEffect } from 'react';
import { SponsorParticipantPage } from '../components/Layout/SponsorParticipantPage';
import { SuccessSponsorPage } from '../components/Layout/SuccessSponsorPage';
import { NotFoundPage } from '../components/Layout/NotFoundPage';
import { useEvent } from '../hooks/useEvent';
import { useParticipant } from '../hooks/useParticipant';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { Loading } from '../components/UI/Loading';
import { SponsorFormValues } from '../../types/components/SponsorParticipantPage';
import { Participant, Sponsor } from '../../types/Participant';
import { TopBar } from '../components/Nav/TopBar';
import { useEventNgo } from '../hooks/useEventNgo';
import { useCheckoutForm } from '../hooks/useCheckoutForm';

export const SponsorParticipantPageContainer = () => {
  const { event, eventLoaded } = useEvent();
  const { participant, participantLoaded }: { participant: Participant | null, participantLoaded: boolean, participantSponsors: Sponsor[] } = useParticipant(true);
  const setDocumentTitle = useDocumentTitle();
  const { eventNgoList } = useEventNgo({ eventSlug: event?.slug || null });
  const { submitCheckoutForm, checkoutFormSuccessResponse, checkoutFormIsSubmiting, checkoutErrors } = useCheckoutForm();

  const submitAction = (formValues: SponsorFormValues) => {
    if (!event) {
      return;
    }
    submitCheckoutForm(formValues, event);
  }

  useEffect(() => {
    if (participantLoaded && eventLoaded && (participant !== null)) {
      setDocumentTitle(`Sponsorér ${participant.name}`);
    }
  }, [participantLoaded, eventLoaded, participant, setDocumentTitle]);

  if (!eventLoaded || !participantLoaded) {
    return <Loading />
  }

  if (!event || !participant) {
    return <NotFoundPage />
  }

  if (checkoutFormSuccessResponse) {
    return <>
      <TopBar />
      <SuccessSponsorPage
        email={checkoutFormSuccessResponse.email}
        name={checkoutFormSuccessResponse.first_name}
        payment_type={checkoutFormSuccessResponse.payment_type}
        event={event}
      />
    </>
  }

  return (
    <>
      <TopBar />
      <SponsorParticipantPage
        event={event}
        formIsLoading={checkoutFormIsSubmiting}
        participant={participant}
        submitForm={submitAction}
        formSubmissionErrors={checkoutErrors}
        ngo={eventNgoList.length > 0 ? eventNgoList[0] : undefined}
      />
    </>
  );
}
