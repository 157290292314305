import { useEffect, useMemo, useState } from "react";
import { Event, EventGroup, QrCodeResponse } from "../../types/Event";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { sendRequest } from "../services/http";
import { QrCodeType } from "../variables/enum";

export const useQrCode = () => {
    const [qrResponse, setQrResponse] = useState<QrCodeResponse | null>(null);
    const { qrHash } = useParams<{ qrHash: string }>();
    const navigate = useNavigate();
    const relatedEvent: Event | null = useMemo(() => qrResponse?.event ?? null, [qrResponse]);
    const relatedEventGroup: EventGroup | null = useMemo(() => qrResponse?.event_group ?? null, [qrResponse]);
    const qrType: QrCodeType | null = useMemo(() => qrResponse?.type ?? null, [qrResponse]);

    useEffect(() => {
        // fetch event from qrHash and redirect to the correct page
        sendRequest({
            method: 'GET',
            endpoint: `/qr/${qrHash}`,
            callback: (response) => setQrResponse(response.data),
            errorCallback: (error: AxiosError) => {
                if (error.response?.status === 404 || error.response?.status === 400 || error.response?.status === 403) {
                    navigate('/not-found', { replace: true });
                    return;
                }
                navigate('/error-page', { replace: true });
            }
        });
    }, [qrHash, navigate]);

    return {
        qrResponse,
        relatedEvent,
        relatedEventGroup,
        qrType,
        qrHash,
    }
}