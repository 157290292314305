import { SyntheticEvent } from "react";

export const FancyImage = (props: {
  imgSrc: string,
  altText?: string,
  onImageError?: (event: SyntheticEvent<HTMLImageElement>) => void
}) => {
  return (
    <div className="
      relative shadow-lg blob-rounded overflow-hidden p-0 bg-white flex items-center max-h-[80vh]
    ">
      <img onError={props.onImageError} src={props.imgSrc} alt={props.altText || ''} className="w-full block" />
    </div>
  );
}