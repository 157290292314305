
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../services/http';
import dayjs from 'dayjs';
import { Container } from '../../UI/Container';
import { buildLink } from '../../../services/events';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Event } from '../../../../types/Event';
import { buildImageLink } from '../../../services/assets';
import {Route} from "../../SVG/Route";
import {Donate} from "../../SVG/Donate";
import {useTranslation} from "react-i18next";


export default function Events() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'events']);

  useEffect(() => {
    sendRequest({
      method: 'GET',
      endpoint: '/event/last3/',
      callback: (res) => {
        const events = res.data.map((event: Event) => {

          return {
            ...event,
            img: event.cloudflare_img_uuid ? buildImageLink(event.cloudflare_img_uuid) : event.img,
          };
        });
        setPosts(events);
      }
    });
  }, [])

  if (posts.length === 0) {
    return <div></div>;
  }

  return (

    <section id="results" className="relative overflow-hidden bg-gradient-to-tr from-primary-900 via-primary-700 to-primary-900 py-28"
    >
      <Container className="pt-4 pb-16 text-center lg:pt-4">

        <div className="md:mx-auto md:text-center xl:max-w-none">
          <h2 className="text-white text-4xl xl:text-5xl font-normal">
            Seneste 3 events
          </h2>
          {<div className="mx-auto mt-16 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {posts.map((post: any) => (
              <div key={post.slug} className="flex flex-col overflow-hidden rounded-lg shadow-lg cursor-pointer" onClick={() => navigate(buildLink(post))}>
                <div className="flex-shrink-0 bg-white">
                  <img className="h-48 w-full object-cover" src={post.img + '/480x380'} alt="" />
                </div>
                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                  <div className="flex-1">
                    <p className="text-md font-medium text-primary pb-2">
                      <Link to={buildLink(post)} className="hover:underline" rel="noreferrer">
                        {post.name}
                      </Link><br></br>
                      <span className="mt-2 text-sm text-gray-500">{dayjs(post.event_end_date).format('DD-MM-YYYY')}</span>
                    </p>
                    <p className="mt-2 text-sm text-gray-500">{post.description}</p>

                    <div className="mt-2 header-font flex gap-4 w-full justify-center">
                      <div className="flex flex-col items-center">
                        <Route classes="w-[40px] h-[40px]"/>
                        <div className="text-sm text-center">
                          <div className="text-xs">{t('events:event_info_box.distance_ran')}</div>
                          <div className="text-lg font-semibold ">{new Intl.NumberFormat('da-DK', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 0 }).format(post.distance_in_km || 0)}</div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <Donate classes="w-[32px] h-[40px]"/>
                        <div className="text-sm text-center">
                          <div className="text-xs">{t('events:event_info_box.donations')}</div>
                          <div className="text-lg font-semibold text-green-500">{new Intl.NumberFormat('da-DK', { maximumFractionDigits: 0 }).format(post.donations) || 0} kr</div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            ))}
          </div>}
        </div>
      </Container>
    </section>
  )
}
