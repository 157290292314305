import { CountDownClock } from '../UI/CountDownClock';
import { useTranslation } from "react-i18next";
import { Dayjs } from 'dayjs';

export const EventCountDown = ({ date, children }: {
  date: Dayjs
  children: React.ReactNode
}) => {
  const { t } = useTranslation('events');

  return (
    <div className={`relative bg-primary border-black/30 px-4 lg:px-10 py-4 shadow-lg negative-container rounded border-4 border-l-0 border-r-0 border-solid text-center max-w-[400px] m-auto`}>
      <div className="text-2xl font-semibold">{t('event_count_down.running_in')}:</div>
      <div className="text-3xl font-bold relative z-10">
        <CountDownClock targetDate={date} />
      </div>
      <div className="absolute overflow-hidden -bottom-1 right-2 -top-1 pt-3 flex items-end z-0">
        <svg width="100" height="100" viewBox="0 0 24 24"><path className="fill-white/20" d="M2.029 15.077l-2.029-.567 1.49-1.43-.442-1.9 1.902.435 1.424-1.333.574 1.864 1.866.567-.972 1.047c-.21-.105-.412-.251-.61-.44l.222-.239-1.104-.335-.339-1.102-.842.788-1.125-.257.262 1.124-.785.845 1.104.335.34 1.102.211-.198c.183.178.374.341.57.488l-1.144 1.07-.573-1.864zm15.971-15.077l6 6v-6h-6zm-.291 2.537l-8.303 8.304-.012.011 3.752 3.752 8.314-8.315-3.751-3.752zm-8.518 12.742c.578-.329 1.338-.886 1.855-1.399l-.821-.826c-.252.25-.925.803-1.524 1.161l.49 1.064zm-3.478-.62c-.389-.149-.78-.399-1.16-.78l-.824.824c.504.504 1.045.85 1.607 1.057l.377-1.101zm2.203-.06c-.419.16-.872.257-1.339.238l-.388 1.131c.732.092 1.482-.024 2.214-.311l-.487-1.058zm1.548 9.401l13.536-13.594-1.4-1.412-14.962 15.006h2.826z" /></svg>
      </div>
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
}