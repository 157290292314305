import { Event } from "../../../types/Event";
import { Participant, SponsorEditLinkObject, SponsorEditLinkResponseObject } from "../../../types/Participant";
import { Container } from "../UI/Container";
import { InputField } from "../UI/InputField";
import { useTranslation } from "react-i18next";
import { Button } from "../UI/Button";
import { Notification } from "../UI/Notification";
import { useState } from 'react';

export const SponsorEditPage = ({ event, sponsor, participant, handleChange, responseObject, submitFn }: {
    event: Event
    sponsor: SponsorEditLinkObject
    participant: Participant
    handleChange: (field: string, value: string | boolean | number | undefined) => void
    responseObject?: SponsorEditLinkResponseObject | null
    submitFn: () => void
}) => {
    const { t } = useTranslation(['event_participants', 'common', 'errors']);
    const [showNotification, setShowNotification] = useState(true);

    return <Container className="mt-10">
        {((responseObject !== null) && (responseObject !== undefined) && (showNotification === true)) ?
            <Notification className="mb-5" title={responseObject?.title} text={responseObject?.message} type={responseObject.type} onClose={() => setShowNotification(false)} static />
            : null}
        <div className="max-w-lg">
            <h1>{t('common:hello_name', { name: sponsor.sponsor.first_name })}</h1>
            <p>
                {t('event_participants:sponsor_edit_page.change_amount_participant_event', { event: event.name, name: participant.name })}
                <br /><br />
                {t('event_participants:sponsor_edit_page.change_until_end')}
            </p>
            <div className="max-w-sm mt-5 flex flex-col gap-3">
                <InputField
                    max={1000}
                    name="amount_per_units"
                    className="text-center md:text-left"
                    onChangeMethod={(val) => handleChange('donation_km', val)}
                    value={sponsor.sponsor.donation_km} type="number"
                    label={t('event_participants:sponsor.form.donation_per_unit')}
                />
                <InputField
                    max={9999}
                    name="one_time_amount"
                    className="text-center md:text-left"
                    onChangeMethod={(val) => handleChange('donation_fixed', val)}
                    value={sponsor.sponsor.donation_fixed}
                    type="number"
                    label={t('event_participants:sponsor.form.donation_one_time')}
                />
                <div><Button onClick={submitFn}>{t('common:save_changes')}</Button></div>
            </div>
        </div>
    </Container>;
}