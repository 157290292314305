import { useEffect, useRef, useState } from "react";

const btnClasses = "shadow-md hover:shadow-lg rounded-md px-2 py-1 text-white h-8";

export const InlineInputField = ({ value, onChange, onBlur, onCancel, type, className, disabled, }: {
    value: string | number | undefined;
    onChange: (value: string | number | undefined) => void;
    onBlur?: () => void;
    onCancel?: () => void;
    type?: string;
    className?: string;
    disabled?: boolean;
}) => {
    const [localValue, setLocalValue] = useState(value || 0);
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }

    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalValue(e.target.value);
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onChange(localValue);
        }
        if (e.key === "Escape") {
            onCancel && onCancel();
        }
    };
    return (
        <>
            <div className="flex gap-2 items-center justify-start">
                <input
                    ref={inputRef}
                    type={type}
                    value={localValue}
                    onChange={handleChange}
                    onBlur={onBlur}
                    onKeyDown={handleKeyDown}
                    className={`mb-0 h-12 max-w-[60px] text-lg text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ${className}`}
                    disabled={disabled}
                />
                <button type="submit" onClick={() => onChange(localValue)} className={`bg-emerald-500 hover:bg-emerald-700 ${btnClasses}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                </button>
                {onCancel && (
                    <button type="button" onClick={(e) => onCancel()} className={`bg-red-500 hover:bg-red-700 ${btnClasses}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                )}
            </div>

        </>
    );
}
