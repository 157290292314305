import { LandingPage } from "../components/LandingPage/LandingPage";
import { TopBar } from "../components/Nav/TopBar";

export const LandingPageContainer = () => {

    return (
        <>
            <TopBar />
            <LandingPage />
        </>
    );
}