import { useState, useEffect, SyntheticEvent } from 'react';
import { Button } from '../UI/Button';
import { useNavigate } from 'react-router-dom';
import { DefaultInfoBoxProps } from '../../../types/components/InfoBox';
import { InfoBoxDonations } from './InfoBoxDonations';
import fallbackImg from '../../images/flbk-run.jpg';
import { useTranslation } from 'react-i18next';

const BoxFooter = ({ children }: {
  children: React.ReactNode
}) => {
  return (
    <div className="text-center p-6 bg-white border-t border-gray-300">
      {children}
    </div>
  );
}

export const InfoBox = ({ image, title, subtitle, finalGoalAmount, currentGoalAmount, children, focused, btnProps, description, extraClasses, fallbackImage }: DefaultInfoBoxProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['various_ui']);

  useEffect(() => {
    if (currentGoalAmount && finalGoalAmount && (currentGoalAmount > finalGoalAmount) && !focused) {
      setIsFocused(true);
    } else if (typeof focused !== 'undefined' && focused) {
      setIsFocused(focused);
    }
  }, [currentGoalAmount, finalGoalAmount, focused]);

  let containerClassNames = "";
  if (isFocused) {
    containerClassNames += "";
  } else {
    containerClassNames += " ";
  }

  const onClick = (event: SyntheticEvent) => {
    event.preventDefault();
    if (btnProps?.onClick && typeof btnProps.onClick === 'string') {
      navigate(btnProps.onClick);
      return;
    }
  }

  const handleImageError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = fallbackImage || fallbackImg;
  }

  return (

    <div className={`transition-all flex flex-col overflow-hidden rounded-lg shadow-lg ${btnProps?.onClick ? 'hover:cursor-pointer hover:shadow-2xl' : null} ${containerClassNames} ${extraClasses}`} onClick={onClick}>
      <a className="block flex-shrink-0" href={btnProps?.onClick}>
        <img className="h-48 w-full object-cover" src={`${image}/480x380`} onError={handleImageError} alt={title} />
      </a>
      <div className="flex flex-1 flex-col justify-between bg-white p-6">
        <div className="flex-1">

          {(focused === true) ? <span className="px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">{t('various_ui:info_box.currently_active')}</span> : null}
          <div className="mt-1 block mb-4">
            <p className="text-xl font-semibold text-gray-900">
              <a href={btnProps?.onClick} onClick={onClick} className={`${btnProps?.onClick ? 'hover:underline' : null}`}>
                {title}
              </a>
            </p>
            {subtitle ? <p className="mt-1 text-base text-gray-700">{subtitle}</p> : null}
            <p className="mt-2 text-sm text-gray-500">{description}</p>
          </div>
          <InfoBoxDonations currentAmount={currentGoalAmount} totalAmount={finalGoalAmount} />
          {children}
        </div>
      </div>
      {btnProps ? <BoxFooter><Button href={btnProps.onClick}>{btnProps.label}</Button></BoxFooter> : null}
    </div>
  );
}