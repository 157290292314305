import { useNavigate } from "react-router-dom";
import { LoadingOverlay } from "../components/UI/Loading";
import { useEffect, useState } from "react";
import { sendRequest } from "../services/http";
import { AxiosError, AxiosResponse } from "axios";
import { MultiParticipantSignupPage } from "../components/Layout/MultiParticipantSignupPage";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { Notification } from "../components/UI/Notification";
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from "../variables/constants";
import { MultiSignupFormValues } from "../../types/components/Signup";
import { useQrCode } from "../hooks/useQrCode";
import { QrCodeType } from "../variables/enum";

export const MultiParticipantSignupPageContainer = () => {
    const { qrType, relatedEvent } = useQrCode();
    const navigate = useNavigate();
    const [formIsLoading, setFormIsLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [formSuccess, setFormSuccess] = useState(false);
    const [successResponse, setSuccessResponse] = useState<any | null>(null);
    const setDocTitle = useDocumentTitle();

    useEffect(() => {
        if (qrType && qrType !== QrCodeType.EVENT_MULTI_SIGNUP) {
            navigate('/not-found', { replace: true });
        }
    }, [navigate, qrType]);

    useEffect(() => {
        if (formIsLoading) {
            setFormError(null);
            setSuccessResponse(null);
        }
    }, [formIsLoading]);

    useEffect(() => {
        let title = "Tilmelding";
        if (relatedEvent) {
            title += ` - ${relatedEvent?.name}`;
        }
        setDocTitle(title);
    }, [relatedEvent, setDocTitle]);

    const handleSubmission = (values: MultiSignupFormValues) => {
        setFormIsLoading(true);
        const onSuccess = (response: AxiosResponse) => {
            setFormIsLoading(false);
            setFormSuccess(true);
            setSuccessResponse(response.data);
        };
        const onError = (error: AxiosError) => {
            setFormIsLoading(false);
            if (error.response?.status === 400) {
                setFormError("Der skete en fejl. Har du indtastet alle felterne korrekt?");
                return;
            }
            setFormError("Der skete en teknisk fejl"); // TODO handle better, at least with http error codes
        };

        sendRequest({
            method: "POST",
            endpoint: `/event/${relatedEvent?.slug}/group-signup`,
            payload: values,
            callback: onSuccess,
            errorCallback: onError,
        });
    };

    if (!relatedEvent) {
        return <LoadingOverlay />;
    }

    if (qrType !== QrCodeType.EVENT_MULTI_SIGNUP) {
        return null;
    }

    return <MultiParticipantSignupPage event={relatedEvent} isFormEnabled={formSuccess ? false : true} isFormLoading={false} handleSubmission={handleSubmission}>
        {formError ? (
            <Notification
                onClose={() => setFormError(null)}
                title={formError}
                type={NOTIFICATION_TYPE_ERROR}
                static={false}
            />
        ) : null}
        {formSuccess ? (
            <Notification
                title={`Tak for din tilmelding, ${successResponse?.first_name ?? ""}`}
                type={NOTIFICATION_TYPE_SUCCESS}
                static={true}
            >
                <p>
                    Du vil modtage en bekræftelsesemail på {successResponse?.email ? successResponse.email : 'den email du indtastede,'} om
                    få minutter. Du skal bekræfte din tilmelding ved at klikke på linket i emailen.
                    <br />
                    Efterfølgende vil du modtage en email med yderligere informationer om arrangementet.
                </p>
            </Notification>
        ) : null}
    </MultiParticipantSignupPage>;
}