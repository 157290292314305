export default function AlertIcon() {
    return (
        <svg enableBackground="new 0 0 64 64" version="1.1" viewBox="0 0 64 64">
            <g clip-rule="evenodd" fill-rule="evenodd">
                <circle cx="31.999" cy="49" r="2.9999" />
                <path d="m32 0c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm0 62c-16.542 0-30-13.458-30-30 0-16.542 13.458-30 30-30 16.542 0 30 13.458 30 30 0 16.542-13.458 30-30 30z" />
                <path d="m31.119 42h1.7623c0.5373 0 0.9786-0.4246 0.9993-0.9615l1.0795-28c0.0219-0.5669-0.4319-1.0385-0.9993-1.0385h-3.9213c-0.56741 0-1.0212 0.4716-0.99931 1.0385l1.0795 28c0.0207 0.5369 0.462 0.9615 0.9993 0.9615z" />
            </g>
        </svg>
    );
}