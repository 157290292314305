import { I18nContext } from "react-i18next";
import { useContext, useEffect, useState } from 'react';
import { DK, EN } from '../SVG/Flags';

const titleMap = {
    da: 'Skift til dansk',
    en: 'Switch to english'
}

const FlagBtn = ({ language, clickHandler }: {
    language: 'da' | 'en',
    clickHandler: () => void
}) => (
    <button className={`bg-white border-2 border-white hover:shadow rounded-full w-[20px] h-[20px] overflow-hidden`} onClick={clickHandler} title={titleMap[language]}>
        {language === 'da' && <DK classes="scale-150"/>}
        {language === 'en' && <EN classes="scale-150"/>}
    </button>
)

export const LanguageSwitch = () => {
    const { i18n } = useContext(I18nContext);
    const [language, setLanguage] = useState(i18n.language);
    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        const cb = (lng: string) => {
            setLanguage(lng);
        };
        i18n.on('languageChanged', cb);
      
        return () => {
        i18n.off('languageChanged', cb);
        }
    }, []);

    const handleChangeLang = () => {
        setToggled(false);
        const lng = i18n.language === 'en' ? 'da' : 'en';
        i18n.changeLanguage(lng)
        localStorage.setItem('i18nextLng', lng)
    };

    return (
        <div className="relative">
            <button onClick={() => setToggled(!toggled)} className="bg-white border-4 hover:border-black/20 border-white rounded-full w-full h-full min-w-[25px] min-h-[25px] overflow-hidden">
                {language === 'da' && <DK classes="scale-150" />}
                {language === 'en' && <EN classes="scale-150" />}
            </button>

            <div className={`absolute top-0 pt-[2.5px] bg-white pr-2 rounded-full right-full flex flex-row gap-1 ${toggled === false ? 'hidden' : ''}`}>
                {Object.keys(titleMap).map((lang) => (
                    <FlagBtn key={lang+language} language={lang as 'da' | 'en'} clickHandler={handleChangeLang} />
                ))}
            </div>
        </div>
    );
}
