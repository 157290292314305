import { useEffect, useRef, useState } from "react";
import { TopBar } from "../Nav/TopBar";
import { Container } from "../UI/Container";
import { useNavigate } from "react-router-dom";
import { FaqCategoryOne } from "./components/FaqCategoryOne";
import { FaqCategoryThree } from "./components/FaqCategoryThree";
import { FaqCategoryTwo } from "./components/FaqCategoryTwo";

export const FaqPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(1);
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);
  const defaultCategory = 1;

  // check url for selected category on load
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get('category');
    if (category) {
      setSelectedCategory(parseInt(category));
    }
  }, []);

  // update url when category changes
  useEffect(() => {
    if (selectedCategory > 0) {
      navigate(`/faq?category=${selectedCategory}`, { replace: true });
    } else {
      navigate('/faq', { replace: true });
    }
  }, [selectedCategory, navigate]);

  // when category changes, scroll to top of content
  useEffect(() => {
    // only scroll into view on small devices
    if (contentRef.current && window.innerWidth < 1024 && selectedCategory !== defaultCategory) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedCategory]);

  return (
    <>
      <TopBar />
      <Container topSpacing>
        <div className="text-center">
          <h1 className="text-3xl my-4 lg:text-4xl xl:text-5xl xl:my-8">
            Sådan virker Init
            <span className="relative whitespace-nowrap ">
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute top-2/3 left-0 h-[0.80em] w-full opacity-60 text-primary"
                preserveAspectRatio="none"
              >
                <path fill="currentColor" d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
              </svg>
              <span className="ml-3 relative z-10">Together</span>
            </span></h1>
          <p className="text-lg lg:text-2xl">Vælg en kategori for at lære mere</p>
        </div>
      </Container>
      <div className="mt-12 min-h-[400px] bg-white">
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 py-12">
            <a
              className={`cursor-pointer bg-primary-700/20 p-4 xl:p-6 rounded-lg hover:bg-primary-800/40 ${selectedCategory === 1 ? 'bg-primary-800/40' : ''}`}
              onClick={() => setSelectedCategory(1)}
            >
              <h2 className="text-xl font-bold">Før løbet</h2>
              <p className="mt-2">Når begivenheden er på plads, og løberne er oprettet</p>
            </a>
            <a
              className={`cursor-pointer bg-primary-700/20 p-4 xl:p-6 rounded-lg hover:bg-primary-800/40 ${selectedCategory === 2 ? 'bg-primary-800/40' : ''}`}
              onClick={() => setSelectedCategory(2)}
            >
              <h2 className="text-xl font-bold">Under løbet</h2>
              <p className="mt-2">
                Registrering af løbernes omgange m.m.
              </p>
            </a>
            <a
              className={`cursor-pointer bg-primary-700/20 p-4 xl:p-6 rounded-lg hover:bg-primary-800/40 ${selectedCategory === 3 ? 'bg-primary-800/40' : ''}`}
              onClick={() => setSelectedCategory(3)}
            >
              <h2 className="text-xl font-bold">Efter løbet</h2>
              <p className="mt-2">Spørgsmål om sikkerhed og privatliv</p>
            </a>
          </div>
          <div className="py-8" ref={contentRef}>
            {selectedCategory === 1 && <FaqCategoryOne />}
            {selectedCategory === 2 && <FaqCategoryTwo />}
            {selectedCategory === 3 && <FaqCategoryThree />}
          </div>
        </Container>

      </div>
    </>
  );
}