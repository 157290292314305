import React from 'react';
import InfoIcon from '../SVG/InfoIcon';
import CheckIcon from '../SVG/CheckIcon';
import AlertIcon from '../SVG/AlertIcon';
import CancelIcon from '../SVG/CancelIcon';

export const FancyAlert = ({ children, extraClasses, title, description, type }: {
    children?: React.ReactNode;
    extraClasses?: string;
    title?: string;
    description?: string;
    type: 'success' | 'error' | 'warning' | 'info';
}) => {
    const baseClassNames: string = 'w-full p-4 rounded relative overflow-hidden';
    let calcClassNames: string = baseClassNames + ' ';
    switch (type) {
        case 'success':
            calcClassNames += 'bg-green-100 text-green-500 fill-green-300/40';
            break;
        case 'error':
            calcClassNames += 'bg-red-100 text-red-500 fill-red-300/40';
            break;
        case 'warning':
            calcClassNames += 'bg-yellow-100 text-yellow-500 fill-yellow-300/40';
            break;
        case 'info':
            calcClassNames += 'bg-blue-100 text-blue-500 fill-blue-300/40';
            break;
        default:
            break;
    }
    if (extraClasses) {
        calcClassNames += ' ' + extraClasses;
    }

    return (
        <div className={calcClassNames}>
            <div className="absolute -rotate-12 bottom-0 right-0 -top-8 -left-2 z-0 w-[160px]">
                {type === 'info' ? <InfoIcon /> : null}
                {type === 'success' ? <CheckIcon /> : null}
                {type === 'error' ? <CancelIcon /> : null}
                {type === 'warning' ? <AlertIcon /> : null}
            </div>
            <div className="z-10 relative">
                {title ? <div className="text-lg font-bold">{title}</div> : null}
                {description ? <div className="text-sm opacity-100">{description}</div> : null}
                {children}
            </div>
        </div>
    );
}