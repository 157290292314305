import { useTranslation } from "react-i18next";

const StatValue = ({ children, extraClasses }: {
    children: React.ReactNode
    extraClasses?: string
}) => {
    return <div className={`shadow-lg pt-1.5 pb-1 leading-none whitespace-nowrap text-sm md:pb-2.5 px-2 bg-white/50 md:block flex justify-between w-full items-center min-h-[45px] md:min-h-0 ${extraClasses}`}>
        {children}
    </div>
}

export const CurrentStandingsStatsBox = ({ laps, totalDistanceCovered, totalDonations }: {
    laps: number
    totalDistanceCovered: number
    totalDonations: number
}) => {
    const { t } = useTranslation('events');

    return (
        <div className={`flex flex-col mb-2 gap-2 text-center md:w-[350px] items-center text-primary header-font`}>
            <div className="w-full md:w-1/2">
                <StatValue extraClasses="rounded-t">
                    <div className={`text-base md:text-lg`}>
                        {t('event_stats_box.distance_ran')}
                    </div>
                    <span className="font-semibold tracking-wide">
                        {new Intl.NumberFormat('da-DK', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 0 }).format(totalDistanceCovered / 1000) || 0}
                    </span>
                </StatValue>
            </div>
            <div className="flex flex-col md:flex-row gap-2 w-full">
                <StatValue extraClasses="md:rounded-tl">
                    <div className={`text-base md:text-lg`}>
                        {t('event_stats_box.laps_ran')}
                    </div>
                    <span className="font-semibold tracking-wide">{laps || 0}</span>
                </StatValue>
                <StatValue extraClasses="rounded-b md:rounded-b-none md:rounded-tr">
                    <div className={`text-base md:text-lg`}>
                        {t('event_stats_box.donations')}
                    </div>
                    <span className="font-semibold tracking-wide">
                        {new Intl.NumberFormat('da-DK', { maximumFractionDigits: 0 }).format(totalDonations) || 0} kr
                    </span>
                </StatValue>
            </div>
        </div>
    );
}