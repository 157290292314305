//import { EventGraphData } from "../../../types/components/EventComponents";
import { useState, useEffect } from 'react';
import { InputLabel } from "../UI/InputLabel";

const CloseIcon = () => {
    return (
        <div className="transition-transform hover:rotate-180">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </div>
    );
}

const SettingsIcon = () => {
    return (
        <div className="opacity-70 hover:opacity-100 transition-transform hover:rotate-180">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24"><path d="M24 13.616v-3.232l-2.869-1.02c-.198-.687-.472-1.342-.811-1.955l1.308-2.751-2.285-2.285-2.751 1.307c-.613-.339-1.269-.613-1.955-.811l-1.021-2.869h-3.232l-1.021 2.869c-.686.198-1.342.471-1.955.811l-2.751-1.308-2.285 2.285 1.308 2.752c-.339.613-.614 1.268-.811 1.955l-2.869 1.02v3.232l2.869 1.02c.197.687.472 1.342.811 1.955l-1.308 2.751 2.285 2.286 2.751-1.308c.613.339 1.269.613 1.955.811l1.021 2.869h3.232l1.021-2.869c.687-.198 1.342-.472 1.955-.811l2.751 1.308 2.285-2.286-1.308-2.751c.339-.613.613-1.268.811-1.955l2.869-1.02zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"/></svg>
        </div>
    );
}

export const EventStatsGraphsConfig = ({ graphs, disabledIndexes, submitChangedIndexArray }: {
    graphs: string[]
    disabledIndexes: number[]
    submitChangedIndexArray: (indexes: number[]) => void
}) => {
    const [expanded, setExpanded] = useState(false);

    // if pressing the escape key, collapse the settings
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setExpanded(false);
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    return (
        <>
            <div className={`rounded-tr rounded-br fixed top-5 transition-all z-40 bg-white shadow p-1 ${expanded ? '-left-full' : 'left-0'}`}>
                <button className="block" title="See settings" onClick={() => setExpanded(true)}>
                    <SettingsIcon />
                </button>
            </div>
            <div className={`mr-10 rounded-tr rounded-br fixed top-5 transition-all bg-white shadow-md z-50 p-5 ${expanded ? 'left-0' : '-left-full'}`}>
                <div className="absolute top-1 right-1">
                    <button title="Hide settings" onClick={() => setExpanded(false)}>
                        <CloseIcon />
                    </button>
                </div>
                <div className="flex flex-col gap-2 text-white">
                    {graphs.map((graph, i) => {
                        return (
                            <div key={i} className="flex flex-row gap-2 items-center">
                                <input type="checkbox" id={`graph-${i}`} name={`graph-${i}`} value={i} checked={disabledIndexes.includes(i) ? false : true} onChange={(e) => {
                                    const target = e.target as HTMLInputElement;
                                    if (target.checked) {
                                        submitChangedIndexArray(disabledIndexes.filter(item => item !== i));
                                    } else {
                                        submitChangedIndexArray([...disabledIndexes, i]);
                                    }
                                }
                                } />
                                <label className="leading-none" htmlFor={`graph-${i}`}><InputLabel>{graph}</InputLabel></label>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}
