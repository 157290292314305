import dayjs, { Dayjs } from 'dayjs';
import { useState, useEffect } from 'react';
import { Loading } from './Loading';
import { useTranslation } from "react-i18next";

export const CountDownClock = ({ targetDate }: { targetDate: Dayjs }) => {
  const [daysLeft, setDaysLeft] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const { t } = useTranslation('various_ui');

  useEffect(() => {
    const calc = () => {
      const now = dayjs();
      const target = dayjs(targetDate);
      const diff = target.diff(now, 'second');
      const days = Math.floor(diff / 86400);
      const hours = Math.floor((diff - days * 86400) / 3600);
      const minutes = Math.floor((diff - days * 86400 - hours * 3600) / 60);
      const seconds = Math.floor(diff - days * 86400 - hours * 3600 - minutes * 60);

      setHoursLeft(hours);
      setMinutesLeft(minutes);
      setSecondsLeft(seconds);
      setDaysLeft(days);
    }

    calc();
    const interval = setInterval(() => {
      calc();
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  if (hoursLeft === 0 && minutesLeft === 0 && secondsLeft === 0) {
    return <Loading />;
  }

  return <div className="flex flex-row gap-2 justify-center">
    {daysLeft > 0 ? <span>{daysLeft}<span className="opacity-70">{t('count_down_clock.days_short')}</span></span> : null}
    {hoursLeft > 0 ? <span>{hoursLeft}<span className="opacity-70">{t('count_down_clock.hours_short')}</span></span> : null}
    {minutesLeft > 0 ? <span>{minutesLeft}<span className="opacity-70">{t('count_down_clock.minutes_short')}</span></span> : null}
    {secondsLeft > 0 ? <span>{secondsLeft}<span className="opacity-70">{t('count_down_clock.seconds_short')}</span></span> : null}
  </div>;
}
