import { useId, useState } from 'react'
import clsx from 'clsx'

import screenshotContacts from '../../../images/screenshots/admin_register_rounds.webp'
import screenshotInventory from '../../../images/screenshots/admin_participant_stats.webp'
import screenshotProfitLoss from '../../../images/screenshots/admin_payment_stats.webp'
import { Container } from '../../UI/Container'

const features = [
  {
    name: 'Overblik over betalinger',
    summary: "NGO'en har adgang og overblik over alle betalingerne.",
    description:
      "Alle betalingsanmodningerne sker automatisk, 2 timer efter løbet. Pengene ryger direkte ind på NGO'ens konto fra MobilePay og betalingskort overførsler (Bankoverførelser er også en mulighed). Her er det muligt for NGO'en at følge med i betalingerne real time.",
    image: screenshotProfitLoss,
    icon: function ReportingIcon() {

      return (
        <>
          <path
            d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
            stroke={'#fff'}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    },
  },
  {
    name: 'Deltagere og sponsorer',
    summary:
      'Løbets administrator har nem adgang til at se og redigere både sponsorer og løbere.',
    description:
      'Hver løber kan have flere sponsorer tilknyttet, og som administrator vil du altid kunne ændre løbernes og sponsorernes oplysninger og donationer, hvis det skulle blive nødvendigt.',
    image: screenshotInventory,
    icon: function InventoryIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            opacity=".3"
            d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
        </>
      )
    },
  },
  {
    name: 'Registrering af omgange',
    summary:
      'Nemt at registrere distance for løberne.',
    description:
      'Løbets administrator har mulighed for at registrere distance for hver løber. Det er muligt at registrere distance for flere løbere på en gang. Regstreringen foregår ved at løberne fysisk løber forbi et registreringspunkt. Her vil løberne blive registreret og deres distance vil blive opdateret.',
    image: screenshotContacts,
    icon: function ContactsIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z"
            fill="#fff"
          />
          <path
            d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z"
            fill="#fff"
          />
        </>
      )
    },
  },
]

function Feature({ feature, isActive, className, ...props }: any) {
  return (
    <div
      className={clsx(className, { 'opacity-75 hover:opacity-100': !isActive })}
      {...props}
    >
      <div
        className={clsx('w-9 rounded-lg', {
          'bg-primary-600': isActive,
          'bg-slate-500': !isActive,
        })}
      >
        <svg aria-hidden="true" className="h-9 w-9" fill="none">
          <feature.icon />
        </svg>
      </div>
      <h3
        className={clsx('mt-6 text-sm font-medium', {
          'text-primary-600': isActive,
          'text-slate-600': !isActive,
        })}
      >
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">
        {feature.summary}
      </p>
      <p className="mt-4 text-sm text-slate-600">{feature.description}</p>
    </div>
  )
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 space-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.name}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto aspect-[1085/730] w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <img
                src={feature.image}
                alt=""
                sizes="52.75rem"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function FeaturesDesktop() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div id="tabgroup" className="hidden lg:mt-20 lg:block">
      <div id="tablist" className="grid grid-cols-3 gap-x-8">
        {features.map((feature, featureIndex) => (
          <Feature
            key={feature.name}
            onClick={() => setSelectedIndex(featureIndex)}
            feature={{
              ...feature,
              name: (
                <div id="tab" className="[&:not(:focus-visible)]:focus:outline-none">
                  <span className="absolute inset-0" />
                  {feature.name}
                </div>
              ),
            }}
            isActive={featureIndex === selectedIndex}
            className="relative cursor-pointer"
          />
        ))}
      </div>
      <div id="tabpanels" className="relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16">
        <div className="-mx-5 flex">
          {features.map((feature, featureIndex) => (
            <div id="tabpanel"
              key={feature.name}
              className={clsx(
                'px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none',
                {
                  'opacity-60': featureIndex !== selectedIndex,
                }
              )}
              style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
              aria-hidden={featureIndex !== selectedIndex}
            >
              <div className="relative aspect-[1085/730] w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                <img
                  src={feature.image}
                  alt=""
                  sizes="52.75rem"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
      </div>
    </div>
  )
}

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-labelledby="secondary-features-title"
      className="pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2
            id="secondary-features-title"
            className="font-normal text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Nemt og enkelt overblik.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Som NGO eller anden organisation, får I et nemt overblik over jeres løb og donationer, samt adgang til at redigere sponsorer og løbere.
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  )
}
