import common from "./common.json";
import events from "./events.json";
import various_ui from "./various-ui.json";
import event_participants from "./event-participants.json";
import errors from "./errors.json";
import footer from "./footer.json";
import ngo from "./ngo.json";
import checkout_form from "./checkout-form.json";
import content from "./content.json";
import sponsor from "./sponsor.json";

const translations = {
    common,
    events,
    various_ui,
    event_participants,
    errors,
    footer,
    ngo,
    checkout_form,
    content,
    sponsor
};

export default translations;