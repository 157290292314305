import { useMemo } from 'react';
import lovespring from '../../images/lovespring.png';
import { Ngo } from '../../../types/Event';
import { useTranslation } from 'react-i18next';

export const EventNgoBox = ({ ngoList }: {
    ngoList: Ngo[]
}) => {
    const { t } = useTranslation(['ngo']);

    const title = useMemo(() => {
        if (ngoList.length > 1) {
            return t('collab_with_the_following_plural');
        }
        if (ngoList[0]?.name === "Lovespring") {
            return t('collab_with', { name: "LoveSpring - Water for water" });
        }

        return t('collab_with_the_following');
    }, [ngoList, t]);

    return (
        <div className="bg-primary-200 bg-opacity-25">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                    <h2 className="mx-auto max-w-md text-center text-3xl font-bold tracking-tight text-primary-900 lg:max-w-lg">
                        {title}
                    </h2>
                    <div className="mt-8 flow-root self-center lg:mt-0">
                        <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
                            {ngoList.map((ngo) => {
                                return (
                                    <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 " key={ngo.phone}>
                                        <a href={ngo.url} rel="noreferrer" target="_blank" className="-mt-2 inline-block hover:scale-110">
                                            {ngo.name === "Lovespring" && <img src={lovespring} alt="Lovespring logo" width="300" />}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};