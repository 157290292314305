import { useMemo, useRef, useEffect } from 'react';
import { Button } from '../UI/Button';
import { useCheckoutFormHelpers } from '../../hooks/useCheckoutFormHelpers';
import { useTranslation } from 'react-i18next';

export const CheckOutFormStep = ({ name, children, currentStep, isLast, continueAction }: {
    name: string,
    currentStep: string,
    children: React.ReactNode,
    isLast?: boolean,
    continueAction?: () => void,
}) => {
    const container = useRef<HTMLDivElement>(null);
    const { completedSteps, startStep } = useCheckoutFormHelpers({ currentStep: currentStep });
    const { t } = useTranslation(['checkout_form']);

    const classNames = useMemo(() => {
        let classNames = 'mb-2';
        if (!completedSteps.includes(name) && name !== currentStep) {
            classNames += ' opacity-20 pointer-events-none';
        }

        return classNames;
    }, [currentStep, name, completedSteps]);

    useEffect(() => {
        if ((name === currentStep) && (container.current !== null) && (name !== startStep)) {
            container.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentStep, name, startStep]);

    return (
        <>
            <div className={classNames} ref={container}>
                {children}
                {(continueAction && name === currentStep) ? <Button className="mt-4 py-4" onClick={(e) => continueAction()}>
                    {t('checkout_form:continue_next_step')} &#8594;
                </Button> : null}
            </div>
            {!isLast ? <hr className="bg-gray-200 h-[1px] w-full" /> : null}
        </>
    );
}
