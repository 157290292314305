export const RoundedImg = ({imgSrc, altText, className, onClick}: {
    imgSrc: string,
    altText?: string,
    className?: string,
    onClick?: (() => void) | null
}) => {
    let extraClassNames = '';
    if(onClick) {
        extraClassNames += ' cursor-pointer';
    }

    const handleClick = (event: React.MouseEvent<HTMLImageElement>) => onClick ? onClick() : null;

    return (
        <div className={`inline-block overflow-hidden special-rounded ${className || ''} ${extraClassNames}`}>
            <img onClick={handleClick} src={imgSrc} alt={altText} className="max-w-full block"/>
        </div>
    );
}