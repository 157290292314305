import i18next from "i18next";

import daTranslations from "../translations/da";
import enTranslations from "../translations/en";

export const i18nextConfig = {
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'da',                              // language to use
    resources: {
        en: enTranslations,
        da: daTranslations,
    },
};

i18next.init(i18nextConfig)

export default i18next;