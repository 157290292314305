import { useState, useEffect } from 'react';
import { sendRequest } from '../services/http';
import { useParams } from "react-router-dom";
import { Participant, Sponsor } from '../../types/Participant';
import { AxiosResponse } from 'axios';
import { default as EchoType } from "laravel-echo";
import { formatParticipant } from '../services/participants';

export const useParticipant = (disableWebSocket?: boolean): { participant: Participant | null, participantLoaded: boolean, participantSponsors: Sponsor[] } => {
  const { eventSlug, participantSlug } = useParams();
  const [participant, setParticipant] = useState<Participant | null>(null);
  const [participantLoaded, setParticipantLoaded] = useState(false);
  const [participantSponsors, setParticipantSponsors] = useState<Sponsor[]>([]);

  useEffect(() => {
    const fetchParticipant = () => {
      // TODO only do 1 request for specific participant
      if (!participantSlug || !eventSlug) {
        setParticipantLoaded(true);
        return;
      }
      const cb = (response: AxiosResponse) => {
        if (response.data.length === 0) {
          setParticipantLoaded(true);
          return;
        }
        const item = response.data;
        setParticipant(formatParticipant(item));
        if (item && item.sponsor) {
          setParticipantSponsors(item.sponsor);
        }

        setParticipantLoaded(true);
      }
      sendRequest({
        method: 'GET',
        endpoint: '/event/get/' + eventSlug + '/participant/' + participantSlug,
        callback: cb,
        errorCallback: (error) => {
          console.log(error);
          setParticipantLoaded(true);
        }
      });
    }
    fetchParticipant();

    const winAlias: { Echo: EchoType } = window as any;    
    if ((disableWebSocket === false) || (typeof disableWebSocket === 'undefined')) {
      winAlias.Echo?.connect()
      winAlias.Echo?.channel("events").listen(".newEvent", (event: { update: string }) => {
        if (event.update === 'success') {
          fetchParticipant();
        }
      });
    }
    return () => {
      if ((disableWebSocket === false) || (typeof disableWebSocket === 'undefined')) {
        winAlias.Echo?.disconnect();
      }
      
      setParticipant(null);
      setParticipantLoaded(false);
      setParticipantSponsors([]);
    }
  }, [participantSlug, eventSlug, disableWebSocket]);

  return { participant, participantLoaded, participantSponsors };
}
