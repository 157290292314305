import { useMemo, useRef } from 'react';
import { InfoBoxGrid } from '../Various/InfoBoxGrid';
import { PastEventByYear } from '../Event/PastEventsByYear';
import { Container } from '../UI/Container';
import { useTranslation } from "react-i18next";
import { Event } from '../../../types/Event';
import { Button } from '../UI/Button';


export const EventHolder = ({ upcomingEvents, pastEvents }: {
  upcomingEvents: Event[],
  pastEvents: Event[],
}) => {
  const { t } = useTranslation('events');
  const eventsContainerRef = useRef<HTMLDivElement>(null)

  const upcomingEventInfoboxes = useMemo(() => {
    if (!upcomingEvents || upcomingEvents.length === 0) return [];
    return upcomingEvents.map(event => ({ event: event }));
  }, [upcomingEvents]);

  const scrollToEvents = () => {
    if (eventsContainerRef.current !== null) {
      eventsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <>
      <Container>
        <div className="mt-8 pt-12 text-center max-w-2xl mx-auto">
          <h1 className="text-3xl xl:text-[3.5rem] font-semibold text-center tracking-wider leading-tight mb-3">
              {t('event_holder.title')}
          </h1>
          <p className="mb-6">
            {t('event_holder.description')}
          </p>
          <Button onClick={scrollToEvents} className="rounded-full">
              {/* {t('event_holder.go_to_events')} */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>

          </Button>
        </div>
      </Container>
      <Container>
        <div className="mb-20 pb-10 relative z-20 pt-10" ref={eventsContainerRef}>
          {upcomingEventInfoboxes && upcomingEventInfoboxes.length > 0 ? (
            <>
              <h2 className="text-2xl lg:text-4xl font-semibold mb-6 text-center">{t('event_holder.upcoming_events')}</h2>
              <hr className="max-w-md m-auto mb-8 border-primary-700/30 border-2 rounded-full" />
              <div className="mb-[60px]"><InfoBoxGrid infoBoxType="event" items={upcomingEventInfoboxes} /></div>
            </>
          ) : null}

          {pastEvents ? <PastEventByYear events={pastEvents} /> : null}
        </div>
      </Container>
    </>
  );
}
