// a secret page given to participant admins, to be able to register laps on behalf of their participants

import { useNavigate, useParams } from "react-router-dom";
import { ErrorPage } from "../components/Layout/ErrorPage";
import { NotFoundPage } from "../components/Layout/NotFoundPage";
import { RegisterRoundsPage } from "../components/RegisterRoundsPage/RegisterRoundsPage";
import { Loading } from "../components/UI/Loading";
import { useEvent } from "../hooks/useEvent";
import { useEffect, useMemo, useState } from "react";
import { sendRequest } from "../services/http";
import { AxiosResponse, AxiosError } from "axios";
import { Notification } from "../components/UI/Notification";
import { NOTIFICATION_TYPE_ERROR } from "../variables/constants";
import { Participant, ParticipantAdmin } from "../../types/Participant";

export const RegisterRoundsPageContainer = () => {
    const [participantAdmin, setParticipantAdmin] = useState<ParticipantAdmin | undefined>(undefined);

    const participants = useMemo(() => {
        return participantAdmin?.participants || [];
    }, [participantAdmin]);

    const eventSlug = useMemo(() => {
        return participantAdmin?.event_slug;
    }, [participantAdmin]);

    const { event, eventLoaded, error } = useEvent({ withDocTitle: true, givenEventSlug: eventSlug });
    const { linkHash } = useParams<{ linkHash: string }>();
    const [hashIsValid, setHashIsValid] = useState(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [submitSuccess, setSubmitSuccess] = useState<string | null>(null);
    const [pendingReload, setPendingReload] = useState(true); // used to trigger a reload of the page after a successful submission
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!linkHash || !pendingReload) {
            return;
        }
        sendRequest({
            method: "GET",
            endpoint: `/participant-admin/${linkHash}`,
            callback: (response: AxiosResponse) => {
                setParticipantAdmin(response.data);
                setHashIsValid(true);
                setPendingReload(false);
            },
            errorCallback: (error: AxiosError) => {
                setHashIsValid(false);
                if (error.response?.status === 404) {
                    navigate('/not-found');
                    return;
                }
                navigate('/error-page');
            },
        });
    }, [linkHash, pendingReload])

    const handleSubmission = (participantSlug?: string, rounds?: number) => {

        console.log('submitting', participantSlug, rounds);

        sendRequest({
            method: "PUT",
            endpoint: `/participant-admin/${linkHash}/${participantSlug}`,
            payload: { rounds: rounds },
            callback: (response: AxiosResponse) => {
                setSubmitSuccess('Omgange blev indsendt.');
                setPendingReload(true);
            },
            errorCallback: (error: AxiosError) => {
                setSubmitError('Der skete en fejl ved indsendelse af omgange.');
            }
        });

    }

    if (!eventLoaded || !eventSlug) {
        return <Loading />
    }

    if (error) {
        return <ErrorPage />
    }

    if (!event || !linkHash || !hashIsValid || !eventSlug || !participantAdmin) {
        return <NotFoundPage />
    }

    return (
        <>
            {submitError ? <Notification onClose={() => setSubmitError(null)} duration={2000} type={NOTIFICATION_TYPE_ERROR} title="Fejl" text={submitError}></Notification> : null}
            {submitSuccess ? <Notification onClose={() => setSubmitSuccess(null)} duration={2000} type="success" title="Succes" text={submitSuccess}></Notification> : null}
            <RegisterRoundsPage participantAdmin={participantAdmin} event={event} participants={participants} submitParticipantRounds={handleSubmission} />
        </>
    );
}