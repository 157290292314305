import { Refresh } from '../SVG/Refresh';
import { Donate } from '../SVG/Donate';
import { Route } from '../SVG/Route';

export const FeaturedEventStat = ({ icon, children, value }: {
    icon: 'laps' | 'donations' | 'route' | 'sponsors'
    value: string | number
    children: React.ReactNode
}) => {

    return (
        <div className="flex flex-col justify-end items-center mb-2 text-primary">
            {icon === 'laps' && <Refresh classes="w-[100px] h-[100px]" />}
            {icon === 'donations' && <Donate classes="w-[100px] h-[100px]" />}
            {icon === 'route' && <Route classes="w-[120px] h-[90px]" />}
            {icon === 'sponsors' && <svg xmlns="http://www.w3.org/2000/svg" width="100" height="80" viewBox="0 0 24 24"><title>user-heart</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="1.5" fill="none" stroke="currentColor" stroke-miterlimit="10"><circle cx="10" cy="5.5" r="4.5"></circle><path d="M12,14.223c-.643-.146-1.313-.223-2-.223C5.029,14,1,18.029,1,23H15"></path><path d="M22.361,15.631c-.973-.961-2.597-.804-3.361,.333-.765-1.138-2.389-1.293-3.361-.333-.852,.842-.852,2.207,0,3.048l3.361,3.321,3.361-3.321c.852-.841,.852-2.206,0-3.048Z" stroke="currentColor"></path></g></svg>}
            <div className="flex flex-col text-center header-font mt-1">
              <p className="text-5xl font-semibold tracking-tight text-primary whitespace-nowrap">{value}</p>
              <p className="mt-2 text-lg font-medium leading-6 text-gray-500">{children}</p>
            </div>
        </div>
    )
}