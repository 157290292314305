import { useTranslation } from "react-i18next";

export const CtaBanner = () => {
    const { t } = useTranslation(['various_ui']);
    
    return (
        <div className="bg-gray-800">
            <div className="max-w-2xl mx-auto py-12 px-4 lg:px-0 sm:px-6 lg:py-16 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl font-extrabold tracking-tight text-white">
                    <span className="block">{t('various_ui:cta_banner.heading')}</span>
                    <a href="mailto:info@init-together.dk" className="block text-indigo-400 text-2xl md:text-3xl mt-1 font-bold">{t('various_ui:cta_banner.sub_heading', { email: 'info@init-together.dk'})}</a>
                </h2>
            </div>
        </div>
    );
};
