import { Container } from "../UI/Container";

export const EventDescription = ({ children, title }: {
    children: React.ReactNode,
    title: string
}) => {
    return (
        <div className="py-10">
            <Container className="text-center header-font flex gap-3 flex-col">
                <h2 className="text-2xl lg:text-3xl">{title}</h2>
                <hr className="w-full max-w-xs m-auto my-2 border-primary-700/30 border-2 rounded-full" />
                <div className="max-w-lg m-auto">
                    {children}
                </div>
            </Container>
        </div>
    );
}