export const Container = (props: {
  children: React.ReactNode;
  className?: string;
  topSpacing?: boolean;
  noPad?: boolean;
  customMaxWidth?: string;
}) => (
  <div className={`
      ${props.customMaxWidth ? props.customMaxWidth : 'max-w-project'} m-auto${props.className ? ' ' + props.className : ''} ${props.topSpacing ? 'pt-5' : ''}
      ${props.noPad ? 'md:px-8' : 'px-4 md:px-8'}
    `}>
    {props.children}
  </div>
);
