import { useState, useEffect } from 'react';
import { NavItem } from '../../types/Nav';
import { useEvent } from './useEvent';

const alwaysPresentInNav = [
  { href: "/events", label: "Alle Events" },
  { href: "https://lovespring.dk", label: "Om LoveSpring - Water for Water" },
  { href: "/faq", label: "FAQ - Om løbet" },
  { href: "/#about", label: "Om os" },
  { href: "/contact", label: "Kontakt" },
  { href: "https://admin.init-together.dk/", label: "Log ind", target: "_blank" },
]

export const useNav = () => {
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [navItemsLoaded, setNavItemsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [eventPrefix, setEventPrefix] = useState<string | null>(null);
  const { event, eventLoaded } = useEvent();
  useEffect(() => {
    if (event) {
      // render very specific event relevant nav items
      const prefix = `/${event.org_slug}/${event.slug}`;
      const items = [
        { href: prefix + "/deltagere", label: "Deltagere" },
        { href: prefix + "/live", label: "Live (projektor visning)" },
        { href: prefix + "/stats", label: "Statistik" },
        ...alwaysPresentInNav
      ];
      setEventPrefix(prefix);
      setNavItems(items);
    }
    if (!event) {
      setNavItems(alwaysPresentInNav);
    }
    
    setNavItemsLoaded(true);
    return () => {
      setNavItems([]);
      setNavItemsLoaded(false);
      setError(null);
      setEventPrefix(null);
    }
  }, [event, eventLoaded]);

  return { navItems, navItemsLoaded, error, eventPrefix };
}