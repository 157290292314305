import { useEffect, useMemo, useRef, useState } from "react";
import { useEvent } from "../../hooks/useEvent";
import { TopBar } from "../Nav/TopBar";
import { Container } from "../UI/Container";
import { NotFoundPage } from "../Layout/NotFoundPage";
import dayjs from "dayjs";
import { Loading } from "../UI/Loading";
import { ErrorPage } from "../Layout/ErrorPage";
import { SponsorTypeButton } from "./SponsorTypeButton";
import { SponsorLevel, SponsorMode } from "../../variables/enum";
import { SponsorLevelButton } from "./SponsorLevelButton";
import { useNavigate } from "react-router-dom";
import { SearchSponsorParticipants } from "../Various/SearchSponsorParticipants";
import { useQuery } from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";

export const SponsorTypePage = () => {
  const { t } = useTranslation(['sponsor']);
  const queryParams = useQuery();
  const sponsorModesContainer = useRef<HTMLDivElement>(null);
  const participantSearchContainer = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { event, eventLoaded, error } = useEvent();
  const [sponsorMode, setSponsorMode] = useState<SponsorMode | null>(null);
  const [sponsorLevel, setSponsorLevel] = useState<SponsorLevel | null>(null);

  const presetParticipantSlug = useMemo(() => queryParams.has('participant_slug') ? queryParams.get('participant_slug') : null, [queryParams]);
  const businessSponsorUrl: string = useMemo(() => event ? `/${event.org_slug}/${event.slug}/sponsor/business` : '', [event]);
  const businessSponsorParticipantUrl: string = useMemo(() => event ? `/${event.org_slug}/${event.slug}/sponsor/business/${presetParticipantSlug}` : '', [event, presetParticipantSlug]);
  const privateSponsorUrl = useMemo(() => event ? `/${event.org_slug}/${event.slug}/sponsor/private` : '', [event]);
  const privateSponsorParticipantUrl = useMemo(() => event ? `/${event.org_slug}/${event.slug}/deltagere/${presetParticipantSlug}` : '', [event, presetParticipantSlug]);
  const showParticipantSearch = useMemo(() => sponsorMode !== null && sponsorLevel === SponsorLevel.PARTICIPANT, [sponsorMode, sponsorLevel]);
  const showSponsorModes = useMemo(() => sponsorLevel !== null, [sponsorLevel]);
  // if participant has been chosen already, then skip sponsor level selection
  const showSponsorLevels = useMemo(() => !presetParticipantSlug, [presetParticipantSlug]);

  const startDate = useMemo(() => {
    if (event) {
      const date = dayjs(event.event_start_date);
      return date.format("DD-MM-YYYY");
    }
    return null;
  }, [event]);

  const privateSponsorModeClickFn = () => {
    if (sponsorLevel === SponsorLevel.EVENT) {
      navigate(privateSponsorUrl);
      return;
    }
    if (presetParticipantSlug) {
      navigate(privateSponsorParticipantUrl);
      return;
    }

    setSponsorMode(SponsorMode.PRIVATE);
  };

  const businessSponsorModeClickFn = () => {
    if (sponsorLevel === SponsorLevel.EVENT) {
      navigate(businessSponsorUrl);
      return;
    }
    if (presetParticipantSlug) {
      navigate(businessSponsorParticipantUrl);
      return;
    }

    setSponsorMode(SponsorMode.BUSINESS);
  }

  const eventSponsorLevelClickFn = () => {
    setSponsorMode(null);
    setSponsorLevel(SponsorLevel.EVENT);
    // remove query param participant_slug. Navigate to current url without query params
    navigate(window.location.pathname);
  }

  const participantSponsorLevelClickFn = () => {
    setSponsorMode(null);
    setSponsorLevel(SponsorLevel.PARTICIPANT);
  }

  // if participant slug is set in query params, then set sponsor level to participant
  useEffect(() => presetParticipantSlug ? setSponsorLevel(SponsorLevel.PARTICIPANT) : () => {}, [presetParticipantSlug]);
  // scroll things into view when they become visible
  useEffect(() => sponsorModesContainer.current ? sponsorModesContainer.current.scrollIntoView({ behavior: 'smooth' })  : () => {}, [sponsorLevel]);
  useEffect(() => participantSearchContainer.current ? participantSearchContainer.current.scrollIntoView({ behavior: 'smooth' }) : () => {}, [sponsorMode]);

  if (!eventLoaded) {
    return <Loading />;
  }

  if (error) {
    <ErrorPage />;
  }

  if (!event) {
    return <NotFoundPage />;
  }

  return (
    <>
      <TopBar />
      <div className="bg-gradient-to-br from-primary-900 to-primary-700 text-white w-full py-10 text-center">
        <Container>
          <h1>
            <span className="mb-3 block opacity-80">{ t('sponsor:sponsor_type_page.become_sponsor') }</span>
            <span className="font-bold text-4xl">{event.name}</span>
            <span className="block mt-3 opacity-80">d. {startDate}</span>
          </h1>
        </Container>
      </div>
      <Container className="pb-12 mb-12 ">
        {showSponsorLevels && (
          <div className="py-5">
            <h2 className="text-center text-xl lg:text-3xl mt-10 font-medium">{ t('sponsor:sponsor_type_page.how_you_want') }</h2>
            <div className="grid md:grid-cols-2 gap-10 md:gap-20 items-center justify-center py-10 m-auto max-w-3xl">
              <SponsorLevelButton disabled={!!sponsorLevel} focused={sponsorLevel === SponsorLevel.PARTICIPANT} sponsorLevel={SponsorLevel.PARTICIPANT} onClick={participantSponsorLevelClickFn} />
              <div className="pointer-events-none relative overflow-hidden">
                <div className="z-10 absolute w-[300px] text-center top-[40px] -left-[80px] -rotate-45 py-2 pb-3 text-base font-bold text-green-900 leading-none px-4 bg-green-300">Kommer snart</div>
                <div className="opacity-70 pointer-events-none ">
                  <SponsorLevelButton focused={sponsorLevel === SponsorLevel.EVENT} sponsorLevel={SponsorLevel.EVENT} onClick={eventSponsorLevelClickFn} />
                </div>
              </div>
            </div>
          </div>
        )}
        {showSponsorModes && (
          <div ref={sponsorModesContainer} className="py-5">
            <h2 className="text-center text-xl lg:text-3xl mt-5 font-medium">{ t('sponsor:sponsor_type_page.kind_of_sponsor') }</h2>
            <div className="flex flex-col sm:flex-row gap-10 md:gap-20 items-center justify-center pt-10 pb-4 m-auto max-w-3xl">
              <SponsorTypeButton
                onClick={businessSponsorModeClickFn}
                sponsorType="business"
                disabled={!!sponsorMode}
                focused={sponsorMode === SponsorMode.BUSINESS}
              />
              <SponsorTypeButton
                onClick={privateSponsorModeClickFn}
                sponsorType="private"
                disabled={!!sponsorMode}
                focused={sponsorMode === SponsorMode.PRIVATE}
              />
            </div>
          </div>
        )}

        {showParticipantSearch && (
          <div ref={participantSearchContainer} className="pb-12">
            <h2 className="text-center text-xl lg:text-3xl mt-10 mb-4 font-medium">{ t('sponsor:sponsor_type_page.find_your_participant') }</h2>
            <div className=""> 
              <SearchSponsorParticipants event={event} sponsorMode={sponsorMode || undefined} />
            </div>
          </div>
        )}
      </Container>
    </>
  );
};
