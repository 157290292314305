
export const FaqCategoryThree = () => {
    return (
        <div>
            <h1 className="xl:text-3xl mb-2">Efter løbet</h1>
            <ul className="list-disc md:text-lg flex flex-col gap-2 header-font pl-8 mt-5 prose">
                    <li>2 timer efter løbet er afsluttet sender vi automatisk opkrævninger ud til sponsorerne.</li>
                    <li>Hvis en sponsor har valgt MobilePay som betalingsform, får de en notifikation på deres telefon, hvorefter de kan godkende betalingen.</li>
                    <li>En sponsor kan altid vælge at afstå fra at betale.</li>
                    <li>Ønsker en sponsor en kvittering for sin betaling, kan de skrive til os på <a href="mailto:info@init-together.dk">info@init-together.dk</a></li>
                </ul>
        </div>
    )
}