import { ReactComponent as LapsIcon } from './../../images/refresh.svg';
import { ReactComponent as DonationsIcon } from './../../images/donations.svg';
import { ReactComponent as RouteIcon } from './../../images/route-icon.svg';

const StatValue = ({ children, extraClasses }: {
    children: React.ReactNode
    extraClasses?: string
}) => {
    return <div className={`px-2 pt-2 pb-1 gap-2 leading-none whitespace-nowrap text-sm bg-primary-800/60 flex flex-row w-full items-center ${extraClasses}`}>
        {children}
    </div>
}

export const CurrentStandingsGroupStatsBox = ({ laps, totalDistanceCovered, totalDonations, title }: {
    laps: number
    totalDistanceCovered: number
    totalDonations: number
    title?: string
}) => {
    return (
        <div className="gap-0 flex flex-col">
            <div className="tracking-wider text-primary header-font">{title}</div>
            <div className={`flex flex-row mb-2 gap-[2px] text-center md:min-w-[280px]`}>
                <StatValue extraClasses="rounded-tl">
                    <RouteIcon className="w-[28px] h-[28px] -mb-1" />
                    <span>{new Intl.NumberFormat('da-DK', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 0 }).format(totalDistanceCovered / 1000) || 0}</span>
                </StatValue>
                <StatValue>
                    <LapsIcon className="w-[22px] h-[22px] -mt-1" />
                    <span>{laps || 0}</span>
                </StatValue>
                <StatValue extraClasses="rounded-tr">
                    <DonationsIcon className="w-[18px] h-[18px] ml-1" />
                    <span>{new Intl.NumberFormat('da-DK', { maximumFractionDigits: 0 }).format(totalDonations) || 0} kr</span>
                </StatValue>
            </div>
        </div>
    );
}