import { useEffect, useRef } from "react"

export const ThinLoadingBar = ({ loadTime, iterations, reload }: { loadTime: number, iterations?: number, reload: number }) => {
    const loadingBarRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (loadingBarRef.current) {
            // make keyframe animation
            loadingBarRef.current.animate(
                [
                    { width: "0%" },
                    { width: "100%" },
                ],
                {
                    duration: loadTime,
                    iterations: iterations ? iterations : Infinity,
                    easing: "linear",
                }
            )
        }
    }, [reload, iterations, loadTime])

    return (
        <div className="h-1 bg-primary" ref={loadingBarRef} />
    )
} 