import { useState, useEffect, SyntheticEvent, useMemo, useCallback, useContext } from 'react';
import { InputField } from '../UI/InputField';
import { useTranslation } from "react-i18next";
import { STEP_DONATION_AMOUNT, STEP_CONTACT_INFO, STEP_PAYMENT_OPTIONS, STEP_SUBMIT, DEFAULT_VALUES, DEFAULT_PAYMENT_METHODS, INTERNATIONAL_PAYMENT_METHODS, DEFAULT_VALUES as defaultFormValues } from '../../variables/checkout_form';
import { SponsorFormValues } from '../../../types/components/SponsorParticipantPage';
import { CheckOutFormStep } from './CheckOutFormStep';
import { FormPaymentMethodField } from '../Event/FormPaymentMethodField';
import { NOTIFICATION_TYPE_INFO, NOTIFICATION_TYPE_WARNING, PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_MOBILEPAY } from '../../variables/constants';
import { FormMemberSignupBox } from './FormMemberSignupBox';
import { Button } from '../UI/Button';
import { FormFootNote } from './FormFootNote';
import { CheckOutFormStepHeading } from './CheckOutFormStepHeading';
import { CheckOutFormStepDesc } from './CheckoutFormStepDesc';
import { useCheckoutFormHelpers } from '../../hooks/useCheckoutFormHelpers';
import { Event, Ngo } from '../../../types/Event';
import { CheckOutFormContext } from '../../context/CheckOutFormContext';
import { CheckOutFormPrivateContact } from './CheckoutFormPrivateContact';
import { AppSettingsContext } from '../../context/AppSettingsContext';
import { Participant } from '../../../types/Participant';
import { Notification } from '../UI/Notification';
import { EventType } from '../../variables/enum';

interface Props {
    submitForm: (values: SponsorFormValues) => void,
    event: Event,
    canSignUpForMemberShip: boolean,
    ngo?: Ngo,
    contentSlots?: {
        [key: string]: React.ReactNode
    },
    participant?: Participant
}

export const CheckOutForm = ({
    submitForm,
    event,
    canSignUpForMemberShip = false,
    ngo,
    contentSlots,
    participant,
}: Props) => {
    const { t } = useTranslation(['event_participants', 'common', 'errors', 'checkout_form']);
    const [currentStep, setCurrentStep] = useState(STEP_DONATION_AMOUNT);
    const [internalValues, setInternalValues] = useState<SponsorFormValues>(DEFAULT_VALUES);
    const { completedSteps } = useCheckoutFormHelpers({ currentStep: currentStep });
    const appSettings = useContext(AppSettingsContext);
    const [allowedPaymentMethods, setAllowedPaymentMethods] = useState<string[]>(DEFAULT_PAYMENT_METHODS);

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        if (participant) {
            submitForm({ ...internalValues, participant_id: participant.id });
            return;
        }
        submitForm(internalValues);
    }

    const nullifyOnFocus = (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        if (!target) {
            return;
        }
        if (parseInt(target.value) === 0) {
            target.value = '';
        }
    }

    const handleFieldChange = useCallback((field: string, value: string | number | boolean | undefined) => setInternalValues({ ...internalValues, [field]: value }), [internalValues]);

    const activateStepsOnFocus = useCallback((e: SyntheticEvent, step: string) => {
        const target = e.target as HTMLInputElement;
        if (!target) {
            return;
        }
        if (!completedSteps.includes(step)) {
            setCurrentStep(step);
        }
    }, [completedSteps]);

    useEffect(() => {
        // when choosing country, change payment methods
        if (internalValues.where_are_you_from === 'other' || internalValues.where_are_you_from === null) {
            setInternalValues(internalValues => ({ ...internalValues, payment_type: PAYMENT_METHOD_CREDIT_CARD }));
            setAllowedPaymentMethods(INTERNATIONAL_PAYMENT_METHODS);
        }
        if (internalValues.where_are_you_from === 'dk') {
            setInternalValues(internalValues => ({ ...internalValues, payment_type: PAYMENT_METHOD_MOBILEPAY }));
            setAllowedPaymentMethods(DEFAULT_PAYMENT_METHODS);
        }

        return () => {
            setAllowedPaymentMethods(DEFAULT_PAYMENT_METHODS);
        }
    }, [internalValues.where_are_you_from]);

    useEffect(() => {
        // TODO figure out default value for payment_type form field. Might be MobilePay, might be something else. ask backend for this?
        if (appSettings.locale === 'da') {
            // setInternalValues(internalValues => ({ ...internalValues, payment_type: PAYMENT_METHOD_MOBILEPAY, where_are_you_from: 'dk' }));
        }
        if (appSettings.locale !== 'da') {
            // we need them to make an active choice here. dont have payment type prefilled and set country to empty as well
            // setInternalValues(internalValues => ({ ...internalValues, payment_type: '', where_are_you_from: null }));
        }

        return () => {
            setInternalValues(defaultFormValues);
        }
    }, [appSettings.locale]);

    const formContext = useMemo(() => ({
        internalFormState: internalValues,
        handleFieldChange: handleFieldChange,
        activateStepsOnFocus: activateStepsOnFocus
    }), [internalValues, handleFieldChange, activateStepsOnFocus]);

    return (
        <CheckOutFormContext.Provider value={formContext}>
            <form onSubmit={handleSubmit} className="flex flex-col w-full gap-5 max-w-xl" autoComplete="off">
                <CheckOutFormStep name={STEP_DONATION_AMOUNT} currentStep={currentStep} continueAction={() => setCurrentStep(STEP_CONTACT_INFO)}>
                    <CheckOutFormStepHeading>{t('checkout_form:donation_amount_step.heading')}</CheckOutFormStepHeading>
                    <CheckOutFormStepDesc>
                        {t('checkout_form:donation_amount_step.desc')}
                        {event.event_type === EventType.EVENT_FIXED_LENGTH ? <span className="inline-block mt-2">
                            <strong>Engangsbeløb:</strong> Et fast beløb, som ikke ændrer sig i løbet af begivenheden.
                            <br />
                            <span className="inline-block mt-2"><strong>Antal kr per sparet minut:</strong> Hvis du tror at deltageren kan løbe hurtigere end det mål de har sat!</span>
                        </span> : null}

                    </CheckOutFormStepDesc>
                    <div className="flex flex-col md:flex-row gap-1 md:gap-4 mt-5">
                        <div className="flex-auto order-2 md:order-1">
                            {event.event_type === EventType.EVENT_FIXED_LENGTH ?
                                <InputField max={1000} name="donation_km" onFocus={nullifyOnFocus} onChangeMethod={(val) => formContext.handleFieldChange('donation_km', val)} value="0" type="number" label={`Antal kr per sparet minut`} />
                                : <InputField max={1000} name="donation_km" onFocus={nullifyOnFocus} onChangeMethod={(val) => formContext.handleFieldChange('donation_km', val)} value="0" type="number" label={t('event_participants:sponsor.form.donation_per_unit')} />
                            }
                        </div>
                        <div className="md:w-52 md:order-2">
                            <InputField max={9999} name="donation_fixed" onFocus={nullifyOnFocus} onChangeMethod={(val) => formContext.handleFieldChange('donation_fixed', val)} value="0" type="number" label={t('event_participants:sponsor.form.donation_one_time')} />
                        </div>
                    </div>
                    {/*                     <InputField type="checkbox" value={formContext.internalFormState.is_public_sponsor_amount} name="is_public_sponsor_amount" onChangeMethod={(val) => formContext.handleFieldChange('is_public_sponsor_amount', val)} label={t('checkout_form:donation_amount_step.is_public_sponsor_amount')}></InputField>
 */}
                    {event.event_type === EventType.EVENT_FIXED_LENGTH ? <Notification type={NOTIFICATION_TYPE_INFO} static className="mb-4">
                        <span className="text-sm">Deltageren har sat sig et mål om at gennemføre løbet på <strong>30 min</strong>.</span>
                        <br />
                        <span className="text-xs">
                            Hvis du eksempelvis donerer <strong>10 kr per sparet minut</strong>, og deltageren løber 5 minutter hurtigere end målet, vil din donation være på 50 kr (5 min x 10 kr). 
                        </span>
                    </Notification> : null}
                </CheckOutFormStep>
                <CheckOutFormStep name={STEP_CONTACT_INFO} currentStep={currentStep} continueAction={() => setCurrentStep(STEP_PAYMENT_OPTIONS)}>
                    {contentSlots && contentSlots[STEP_CONTACT_INFO] ? contentSlots[STEP_CONTACT_INFO] : <CheckOutFormPrivateContact />}
                </CheckOutFormStep>
                <CheckOutFormStep name={STEP_PAYMENT_OPTIONS} currentStep={currentStep} continueAction={() => setCurrentStep(STEP_SUBMIT)}>
                    <CheckOutFormStepHeading>{t('checkout_form:payment_options_step.heading')}</CheckOutFormStepHeading>
                    <CheckOutFormStepDesc>{t('checkout_form:payment_options_step.desc')}</CheckOutFormStepDesc>
                    <div className="w-full mb-2" onFocus={(e) => formContext.activateStepsOnFocus(e, STEP_PAYMENT_OPTIONS)}>
                        <FormPaymentMethodField allowedOptions={allowedPaymentMethods} currentValue={formContext.internalFormState.payment_type} onChange={(val) => formContext.handleFieldChange('payment_type', val)} />
                    </div>
                </CheckOutFormStep>
                <CheckOutFormStep name={STEP_SUBMIT} currentStep={currentStep} isLast>
                    <CheckOutFormStepHeading>{t('checkout_form:submit_step.heading')}</CheckOutFormStepHeading>
                    <CheckOutFormStepDesc>{t('checkout_form:submit_step.desc')}</CheckOutFormStepDesc>
                    <div className="flex flex-col mt-4 gap-4 items-start" onFocus={(e) => formContext.activateStepsOnFocus(e, STEP_SUBMIT)}>
                        <InputField name="privacy_policy" onChangeMethod={(val) => formContext.handleFieldChange('accept_privacy_policy', val)} type="checkbox" value={internalValues.accept_privacy_policy} required>
                            {t('event_participants:sponsor.form.i_consent')} <a href="/privatlivspolitik" className="underline hover:no-underline" target="_blank" rel="noopener noreferrer">{t('event_participants:sponsor.form.privacy_policy')}</a>
                        </InputField>
                        {/*                         <InputField name="todo_is_sponsor_public" onChangeMethod={(val) => formContext.handleFieldChange('todo_is_sponsor_public', val)} type="checkbox" value={true} required>
                            Vis mit fornavn og donation på deltagerens profil
                        </InputField> */}
                        <FormMemberSignupBox
                            className="mb-4 w-full md:w-auto"
                            visible={(formContext.internalFormState.donation_fixed >= 200 || formContext.internalFormState.donation_km >= 15) && (canSignUpForMemberShip === true)}
                            handleChange={(val) => formContext.handleFieldChange('signed_up_for_membership', val)}
                            value={formContext.internalFormState.signed_up_for_membership}
                            oneTimeAmount={formContext.internalFormState.donation_fixed}
                            amountPerUnits={formContext.internalFormState.donation_km}
                        />

                        {/* {ngo ? (
                            <div className="p-2 lg:p-4 bg-blue-200 w-full rounded text-blue-800 flex lg:flex-row flex-col items-center gap-2 lg:gap-4">

                                <p className=" text-xs  text-blue-800">
                                    Når man sponsorerer denne begivenhed, lægges der et administrationsgebyr oveni donationen.
                                    <br />
                                    Gebyret er på <strong>12,5 kr</strong>.
                                    <br />
                                    For spørgsmål angående dette, kan du kontakte NGO'en {ngo.name} via tlf. {ngo.phone}.
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 hidden lg:block">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                            </div>
                        ) : null} */}

                        {formContext.internalFormState?.donation_km ? <>
                            <Notification type={NOTIFICATION_TYPE_WARNING} static>
                                Bemærk at du har valgt at donere <strong>{formContext.internalFormState.donation_km} kr per kilometer</strong> løbet.
                                <br />
                                Dette betyder, at den mængde kilometer, som deltageren løber, lægges sammen og ganges med det beløb, du har valgt at donere per kilometer.
                                <br />
                                Hvis deltageren eksempelvis løber 20 kilometer, bliver regnestykket altså 20 x {formContext.internalFormState.donation_km} kr
                            </Notification>
                        </> : null}

                        <Button
                            className={`w-full text-center md:text-left md:w-auto mt-4 ${!formContext.internalFormState.accept_privacy_policy ? 'opacity-40 cursor-not-allowed' : ''}`}
                            useBtn={true} type="submit"
                            big
                        >
                            {t('event_participants:sponsor.form.sign_up')}
                        </Button>
                    </div>
                </CheckOutFormStep>
                <FormFootNote />
            </form>
        </CheckOutFormContext.Provider>
    )
}
