import { useTranslation } from "react-i18next";
import { Participant } from "../../../types/Participant"
import { formatUnitsRan } from "../../services/participants";
import { Button } from "../UI/Button";
import { useEffect, useState } from "react";
import { InlineInputField } from "../UI/InlineInputField";

function participantTdClasses(index: number, colNr: number) {
    return `relative py-4 pl-4 pr-3 text-sm sm:pl-6 ${index === 0 ? '' : ' lg:table-cell border-t border-gray-200'} ${colNr === 0 || colNr === 5 ? '' : 'hidden lg:table-cell'} `;
}

export default function RoundsEditParticipantTable({ participants, clickHandler }: {
    participants: Partial<Participant>[]
    clickHandler: (participantSlug?: string, value?: number) => void
}) {
    const { t } = useTranslation('event_participants');
    const [showEdit, setShowEdit] = useState<string | null>(null);
    const [showClipboardToolTip, setShowClipboardToolTip] = useState<string | null>(null);

    useEffect(() => {
        if (showClipboardToolTip) {
            setTimeout(() => {
                setShowClipboardToolTip(null);
            }, 2000);
        }
    }, [showClipboardToolTip]);

    const handleEdit = (participantSlug: string, value: number) => {
        setShowEdit(null);
        clickHandler(participantSlug, value);
    }

    const copyToClipboard = (participantSlug: string, text: string) => () => {
        navigator.clipboard.writeText(text);
        setShowClipboardToolTip(participantSlug);
    }

    return (

        <div className="mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Navn
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            {t('event_participants:start_nr_no_val')}
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            {t('event_participants:number_of_sponsors')}
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Km. løbet
                        </th>
                        <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Omgange løbet
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {participants.map((participant, participantIdx) => (
                        <tr key={participant.slug}>
                            <td className={participantTdClasses(participantIdx, 0)}>
                                <div className="text-lg lg:text-base font-semibold lg:font-medium text-gray-900">
                                    {participant.name}
                                </div>
                                <div className="font-medium text-gray-600 text-xs">
                                    <div className=" lg:hidden">
                                        Omgange løbet: {participant.total_rounds}
                                    </div>
                                    <div className="relative group cursor-pointer">
                                        Sponsor link:
                                        <br />
                                        <div title="Kopiér" className="relative flex hover:text-primary gap-1 items-center -top-1" onClick={copyToClipboard(participant.slug || '', participant.share_url || '')}>
                                            <span className=" underline">
                                                {participant.share_url}
                                            </span>
                                            <div className="opacity-100 lg:opacity-0 transition-all group-hover:opacity-100 ">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                                </svg>
                                            </div>
                                            {showClipboardToolTip === participant.slug && <div className="p-1 leading-none bg-black/60 rounded text-white text-[10px] absolute -top-full right-0">
                                                Kopieret til udklipsholderen!
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                            </td>
                            <td
                                className={participantTdClasses(participantIdx, 1)}
                            >
                                {participant.startnr}
                            </td>
                            <td
                                className={participantTdClasses(participantIdx, 2)}
                            >
                                {participant.total_sponsors}
                            </td>
                            <td
                                className={participantTdClasses(participantIdx, 3)}
                            >
                                {participant.total_meters ? formatUnitsRan(participant.total_meters) : 0} km
                            </td>
                            <td
                                className={participantTdClasses(participantIdx, 4)}
                            >
                                {participant.total_rounds}
                            </td>
                            <td
                                className={`${participantTdClasses(participantIdx, 5)}`}
                            >

                                {(showEdit === null || participant.slug !== showEdit) ? (
                                    <Button small className="items-center inline-flex justify-start gap-2" onClick={() => setShowEdit(participant.slug || null)}>
                                        Indtast omgange
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                        </svg>

                                    </Button>
                                ) : null}
                                {showEdit === participant.slug ? (
                                    <InlineInputField
                                        className="mb-1.5"
                                        type="number"
                                        value={participant.total_rounds}
                                        onChange={(value) => handleEdit(participant.slug || '', Number(value))}
                                        onCancel={() => setShowEdit(null)}
                                    />
                                ) : null}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
