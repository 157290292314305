
// import backgroundImage from '@/images/background-faqs.jpg'

import { Button } from "../../UI/Button"
import { Container } from "../../UI/Container"

const faqs = [
  [
    {
      question: '1. trin - Information om løbet',
      answer:
        'Send os navn og dato på løbet, samt anden relevant information. Hvem skal pengene gå til? Hvilke projekter skal der doneres til? Hvad er jeres økonomiske mål?',
    },
    {
      question: '2. trin - Løberne',
      answer:
        'I modtager et Excel ark, som I skal udfylde med oplysninger omkring løberne. Herefter opretter vi løberne på platformen.',
    },
    {
      question: '3. trin - Mail til alle løbere',
      answer: 'Alle løberne vil derefter modtage en mail med et unikt link til deres personlige løber-profil.',
    },
  ],
  [
    {
      question: '4. trin - Sponsorer',
      answer:
        'Når løberne har modtaget deres link, kan de dele dette med deres sponsorer. Sponsorerne kan oprette deres donation via linket. Der kan doneres et fast beløb eller et beløb pr. kilometer.',
    },
    {
      question:
        '5. trin - Under løbet',
      answer:
        'Under løbet leverer vi en platform der bruges til at registrere løbernes distance m.m. som opdateres live.',
    },
    {
      question: '6. trin - Motivationen',
      answer:
        'Under løbet, kan løberne følge med i, hvor mange penge de har samlet ind og hvor mange kilometer de har løbet. Samtidig kan alle sponsorerne følge med live, på platformen',
    },
  ],
  [

    {
      question: '7. trin - Efter løbet',
      answer: 'Når løbet er slut, bliver der sendt en opgørelse ud til alle sponsorer, med det antal kroner, de skal donere. Vi håndterer Vipps MobilePay, kortbetaling samt bankoverførsler. Alt er naturligvis automatiseret. Pengene går direkte til NGO\'ens konto, og Init-Together har således intet med pengene at gøre.',
    },
  ],
]

export function Faqs() {
  return (
    <section
      id="host"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <h2 id="faq-title" className="sr-only">
        Det er nemt at afholde et event, som skole eller organisation.
      </h2>
      {/*       <div className="absolute top-0 left-1/2 -translate-x-[30%] -translate-y-[25%]">
        <Image
          src={backgroundImage}
          alt=""
          width={1558}
          height={946}
          layout="fixed"
          unoptimized
        />
      </div> */}
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <p className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Hvad skal der til, i 7 trin.
          </p>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Det er nemt at afholde et event med Init-Together.
          </p>
          <Button className="mt-4" href='mailto:info@init-together.dk'>Kontakt os</Button>
        </div>
        <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="space-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
