import { useMemo } from "react"
import { Event } from "../../../types/Event"
import { NOTIFICATION_TYPE_SUCCESS } from "../../variables/constants"
import { Button } from "../UI/Button"
import { Container } from "../UI/Container"
import { Notification } from "../UI/Notification"
import { useTranslation } from "react-i18next";
import { buildLink } from "../../services/events"

export const SuccessSponsorPage = ({ event, name, payment_type, email }: {
  event?: Event
  email: string
  name: string
  payment_type: string
}) => {
  const { t } = useTranslation(['event_participants']);

  const eventLink = useMemo(() => {
    if (!event) {
      return '';
    }
    return buildLink(event);
  }, [event]);

  return <Container topSpacing>
    <Notification className="relative !z-0 mt-12 mb-[200px] !right-0 !left-0" type={NOTIFICATION_TYPE_SUCCESS}>
      <h1 className="mb-2">{t('event_participants:sponsor.success.title', { name: name })}</h1>
      <p>{t('event_participants:sponsor.success.message_1')}</p>
      {payment_type === 'mobilepay' ? <p>{t('event_participants:sponsor.success.message_mobile_pay')}</p> : null}
      <p>{t('event_participants:sponsor.success.message_2', { email: email })}</p>
      {(event) ? <Button className="mt-4 !whitespace-normal" href={eventLink} negative small>
        {t('event_participants:sponsor.success.btn', { name: event.name })}
      </Button> : null}
    </Notification>
  </Container>
}