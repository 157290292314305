import { useTranslation } from "react-i18next";
import { Button } from "../UI/Button";
import { useMemo } from "react";

export const SponsorTypeButton = ({ sponsorType, onClick, disabled, focused }: {
    sponsorType: 'business' | 'private'
    onClick?: () => void
    disabled?: boolean
    focused?: boolean
}) => {
    const { t } = useTranslation(['sponsor']);
    const dynamicClasses: string = useMemo(() => {
        if (focused) {
            return 'shadow-lg text-primary border-primary/50 border-4';
        }
        return 'text-gray-700';
    }, [focused]);

    const opaqueClasses: string = useMemo(() => {
        if (disabled && !focused) {
            return 'opacity-50 text-gray-500';
        }

        return '';
    }, [disabled, focused]);

    return (
        <div className={`w-full md:w-1/2 overflow-hidden rounded-lg bg-white shadow text-center hover:shadow-lg transition-all cursor-pointer hover:text-primary ${dynamicClasses} ${opaqueClasses}`} onClick={onClick}>
            <div className="px-4 pt-5 pb-2 flex flex-col items-center">
                {sponsorType === 'business' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[100px] h-[100px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[100px] h-[100px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                )}
            </div>
            <div className="px-4 pb-7">
                <h3 className="mb-2 text-xl lg:text-2xl leading-none font-medium ">
                    {sponsorType === 'business' ? t('sponsor:sponsor_type_page.business_sponsor') : t('sponsor:sponsor_type_page.individual_sponsor')}
                </h3>
                <p className="mb-4">
                    {sponsorType === 'business' ? t('sponsor:sponsor_type_page.business_sponsor_subtext') : t('sponsor:sponsor_type_page.individual_sponsor_subtext')}
                </p>
                {!disabled ? <Button>{ t('sponsor:sponsor_type_page.choose_btn_label') }</Button> : null}
            </div>
        </div>
    );
}