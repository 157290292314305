import { Container } from "../components/UI/Container";
import { TopBar } from "../components/Nav/TopBar";
import { I18nContext } from 'react-i18next';
import { useContext, useEffect, useState } from "react";

const DefaultVersion = () => {
  return (
    <>
      <h1 className="mb-2">Privatlivspolitik for <br></br>Init-Together ApS</h1>
      <small className="italic">Opdateret 24-12-2024</small>

      <h2>Introduktion</h2>
      <p>Hos Init-Together ApS respekterer vi dit privatliv og er forpligtet til at beskytte dine personoplysninger. Vi overholder databeskyttelsesforordningen (GDPR) og behandler kun dine oplysninger i overensstemmelse med gældende lovgivning. Denne privatlivspolitik beskriver, hvordan vi indsamler, bruger og beskytter dine oplysninger, når du bruger vores platform.</p>

      <h2>Hvem er dataansvarlig, og hvem er databehandler?</h2>
      <p>NGO'er (f.eks. Lovespring) er dataansvarlige for de oplysninger, som behandles på platformen, herunder data om elever og sponsorer, da de fastlægger formålet med databehandlingen.</p>
      <p>Init-Together ApS fungerer som databehandler og behandler personoplysninger på vegne af NGO'erne. Vi opbevarer, administrerer og beskytter disse data, men har ikke kontrol over formålet med indsamlingen eller brugen af data.</p>

      <h2>Typer af data vi indsamler</h2>

      <h3>Elever</h3>
      <p>Vi indsamler følgende oplysninger om elever, afhængigt af hvad skolen vælger at dele med os:</p>
      <ul>
        <li>Fornavn</li>
        <li>Efternavn</li>
        <li>E-mailadresse</li>
        <li>Køn</li>
      </ul>
      <p>Nogle skoler, såsom folkeskoler, vælger at sende anonymiserede data, hvor vi kun modtager et startnummer/løbernummer. I disse tilfælde er det skolen selv, der matcher løbernummeret med elevdata. Hvis både fornavn og efternavn leveres til os, vises disse oplysninger offentligt på platformen.</p>

      <h3>Sponsorer</h3>
      <p>For sponsorer indsamler vi følgende oplysninger:</p>
      <ul>
        <li>Fornavn</li>
        <li>Efternavn</li>
        <li>E-mailadresse</li>
        <li>Telefonnummer</li>
      </ul>

      <h2>Hvordan bruger vi dine oplysninger?</h2>
      <p>De oplysninger, vi indsamler, bruges til følgende formål:</p>
      <ul>
        <li><strong>Elever:</strong> Elevers fornavn og efternavn kan vises på en offentlig profil, som sponsorer kan støtte via et delt link. Hvis vi kun modtager anonymiserede oplysninger fra skolen, vises kun startnummeret/løbernummeret.</li>
        <li><strong>Sponsorer:</strong> Dine oplysninger bruges til at registrere din støtte til en elev og til at sende automatiske betalingsanmodninger via NGO'ens MobilePay eller Stripe efter løbet.</li>
      </ul>

      <h2>Betalingsstatus og påmindelser</h2>
      <p>Init-Together ApS håndterer ikke betalingsoplysninger, men vi modtager statusopdateringer fra MobilePay og Stripe om, hvorvidt en sponsor har gennemført betalingen. Dette sker udelukkende for at sende en venlig påmindelse til sponsorer, der ikke har gennemført betalingen, 2 dage efter den første anmodning er sendt. Efter denne påmindelse gør vi ikke yderligere ved betalingen, da donationerne ikke er bindende.</p>

      <h2>Valg om medlemskab af NGO</h2>
      <p>Når du som sponsor tilmelder dig et skoleløb, kan du vælge at blive medlem af den tilknyttede NGO. Hvis du vælger dette, vil vi efter løbet videregive alle dine oplysninger (fornavn, efternavn, e-mail og telefonnummer) til NGO'en, så de kan registrere dig som medlem. Dette er samme data, som NGO'en allerede har adgang til via platformen under løbet.</p>

      <h2>Offentlig tilgængelig information</h2>
      <p>Hvis både fornavn og efternavn er leveret til os, vil disse oplysninger være offentligt tilgængelige via det link, som eleven deler med potentielle sponsorer. Anonyme elever vil blive identificeret med deres startnummer/løbernummer. Ingen andre oplysninger vil være offentligt tilgængelige.</p>

      <h2>Adgang til oplysninger</h2>
      <p>Det er kun NGO'en, arrangøren af eventet, og Init-Together ApS, der har adgang til dine data. Dine oplysninger videregives ikke til andre virksomheder, med undtagelse af de betalingsudbydere (MobilePay, Stripe), som behandler donationerne.</p>

      <h2>Opbevaring af data</h2>
      <p>Vi opbevarer dine oplysninger i følgende perioder:</p>
      <ul>
        <li><strong>Elever:</strong> En måned efter skoleløbet afsluttes, bliver dine personoplysninger anonymiseret. Dit fornavn og efternavn erstattes med tilfældige navne, og alle andre personlige oplysninger, såsom e-mail og køn, slettes.</li>
        <li><strong>Sponsorer:</strong> Dine oplysninger slettes også en måned efter løbet. På grund af vores daglige backup-procedurer kan dine data dog være gemt i vores backups i op til to måneder, hvorefter de automatisk slettes.</li>
      </ul>

      <h2>Servere og sikkerhed</h2>
      <p><strong>Hosting:</strong> Vores servere hostes hos Hetzner i et datacenter i Finland.</p>
      <p><strong>Backups:</strong> Vi laver daglige krypterede backups, som opbevares hos Backblaze. Backups slettes automatisk efter 30 dage via en timelock.</p>
      <p><strong>E-mails:</strong> Postmark bruges til at sende e-mails. Dine e-mails opbevares hos dem i op til 41 dage, hvorefter de slettes.</p>

      <h2>Betalingstjenester</h2>
      <p>NGO's MobilePay og Stripe: Init-Together ApS har intet med pengene at gøre. Vi bruger NGO'ens MobilePay og Stripe konti til at sende automatiske betalingsanmodninger til sponsorer. Donationerne går direkte til NGO'ens betalingsudbydere, og vi opbevarer eller håndterer ingen betalingsoplysninger.</p>

      <h2>Ansvar for donationernes formål</h2>
      <p>Init-Together ApS har ingen indflydelse på, hvad NGO'en bruger donationerne til, eller hvordan de administrerer de indsamlede midler. NGO'en er fuldt ansvarlig for anvendelsen af donationerne, og Init-Together ApS kan ikke stilles til ansvar for NGO'ens beslutninger vedrørende donationernes anvendelse.</p>

      <h2>Dine rettigheder</h2>
      <p>Du har følgende rettigheder i forhold til dine personoplysninger:</p>
      <ul>
        <li><strong>Ret til indsigt:</strong> Du kan anmode om at se, hvilke oplysninger vi har om dig.</li>
        <li><strong>Ret til rettelse:</strong> Du kan anmode om, at vi retter urigtige oplysninger.</li>
        <li><strong>Ret til sletning:</strong> Du kan til enhver tid bede om at få dine oplysninger slettet. Hvis du ønsker at få slettet data, der er gemt i vores backups, slettes de automatisk efter en måned.</li>
      </ul>
      <p>Du kan kontakte os på <a href="mailto:info@init-together.dk">info@init-together.dk</a> for at udøve dine rettigheder.</p>

      <h2>Cookies</h2>
      <p>Vi anvender kun teknisk nødvendige cookies for at sikre, at vores platform fungerer korrekt. Vi bruger ingen sporings- eller reklamecookies.</p>

      <h2>Donationer</h2>
      <p>Donationer er ikke bindende, og du vælger selv, om du vil betale det beløb, der opgøres ud fra deltagerens præstation. Init-Together ApS har ingen indflydelse på håndtering af donationerne, da de går direkte fra betalingsudbyderne (MobilePay, Stripe) til NGO'ens bankkonto.</p>

      <h2>Ændringer i privatlivspolitikken</h2>
      <p>
        Vi opdaterer muligvis denne privatlivspolitik fra tid til anden for at reflektere ændringer i vores praksis eller gældende lovgivning. Vi opfordrer dig til at gennemgå denne side regelmæssigt for at holde dig opdateret.
      </p>
      <h2>Kontakt os</h2>
      <p>
        Hvis du har spørgsmål eller bekymringer vedrørende vores privatlivspolitik, kan du kontakte os på:
        <br></br>
        <br></br>
        Init-Together ApS <a href="mailto:info@init-together.dk">info@init-together.dk</a>
      </p>

    </>
  );
}

const EnglishVersion = () => {
  return (
    <>
      <h1 className="mb-2">Privacy Policy for <br></br>Init-Together ApS</h1>
      <small className="italic">Updated 24-12-2024</small>
      <h2>Introduction</h2>
      <p>At Init-Together ApS, we respect your privacy and are committed to protecting your personal data. We comply with the General Data Protection Regulation (GDPR) and only process your data in accordance with applicable laws. This privacy policy explains how we collect, use, and protect your data when you use our platform.</p>

      <h2>Who is the data controller, and who is the data processor?</h2>
      <p>NGOs (e.g., Lovespring) are the data controllers for the information processed on the platform, including data about students and sponsors, as they determine the purpose of the data processing.</p>
      <p>Init-Together ApS acts as a data processor and processes personal data on behalf of the NGOs. We store, manage, and protect this data but do not control the purpose for which it is collected or used.</p>

      <h2>Types of data we collect</h2>

      <h3>Students</h3>
      <p>We collect the following information about students, depending on what the school chooses to share with us:</p>
      <ul>
        <li>First name</li>
        <li>Last name</li>
        <li>Email address</li>
        <li>Gender</li>
      </ul>
      <p>Some schools, such as public schools, choose to send anonymized data, where we only receive a start number/runner number. In these cases, the school itself matches the runner number with the student data. If both the first and last name are provided to us, this information will be displayed publicly on the platform.</p>

      <h3>Sponsors</h3>
      <p>We collect the following information about sponsors:</p>
      <ul>
        <li>First name</li>
        <li>Last name</li>
        <li>Email address</li>
        <li>Phone number</li>
      </ul>

      <h2>How we use your data</h2>
      <p>The data we collect is used for the following purposes:</p>
      <ul>
        <li><strong>Students:</strong> The student's first and last name may be displayed on a public profile, which sponsors can support via a shared link. If we only receive anonymized data from the school, only the start number/runner number will be displayed.</li>
        <li><strong>Sponsors:</strong> Your data is used to register your support for a student and to send automatic payment requests via the NGO's MobilePay or Stripe after the race.</li>
      </ul>

      <h2>Payment status and reminders</h2>
      <p>Init-Together ApS does not handle payment information, but we receive status updates from MobilePay and Stripe regarding whether a sponsor has completed the payment. This is solely to send a friendly reminder to sponsors who have not completed the payment, 2 days after the first request was sent. After this reminder, we do not take any further action regarding the payment, as the donations are non-binding.</p>

      <h2>Option to join the NGO as a member</h2>
      <p>When you sign up as a sponsor for a school race, you can choose to become a member of the associated NGO. If you choose this option, after the race, we will forward all your information (first name, last name, email, and phone number) to the NGO so they can register you as a member. This is the same data the NGO already has access to on the platform during the race.</p>

      <h2>Publicly available information</h2>
      <p>If both the first and last names are provided to us, this information will be publicly available via the link that the student shares with potential sponsors. Anonymous students will be identified by their start number/runner number. No other information will be publicly available.</p>

      <h2>Access to data</h2>
      <p>Only the NGO, the event organizer, and Init-Together ApS have access to your data. Your data is not shared with other companies, except for the payment providers (MobilePay, Stripe) who handle the donations.</p>

      <h2>Data retention</h2>
      <p>We retain your data for the following periods:</p>
      <ul>
        <li><strong>Students:</strong> One month after the race ends, your personal data will be anonymized. Your first and last name will be replaced with random names, and all other personal information, such as email and gender, will be deleted.</li>
        <li><strong>Sponsors:</strong> Your data will also be deleted one month after the race. Due to our daily backup procedures, your data may be stored in our backups for up to two months, after which it will be automatically deleted.</li>
      </ul>

      <h2>Servers and security</h2>
      <p><strong>Hosting:</strong> Our servers are hosted by Hetzner in a data center in Finland.</p>
      <p><strong>Backups:</strong> We create daily encrypted backups, which are stored with Backblaze. Backups are automatically deleted after 30 days via a time lock.</p>
      <p><strong>Emails:</strong> Postmark is used to send emails. Your emails are stored with them for up to 41 days, after which they are deleted.</p>

      <h2>Payment services</h2>
      <p>NGO's MobilePay and Stripe: Init-Together ApS does not handle the payments. We use the NGO's MobilePay and Stripe accounts to send automatic payment requests to sponsors. The donations go directly to the NGO's payment providers, and we do not store or process any payment information.</p>

      <h2>Responsibility for the use of donations</h2>
      <p>Init-Together ApS has no control over how the NGO uses the donations or how they manage the funds raised. The NGO is fully responsible for the use of donations, and Init-Together ApS cannot be held responsible for the NGO's decisions regarding the use of donations.</p>

      <h2>Your rights</h2>
      <p>You have the following rights regarding your personal data:</p>
      <ul>
        <li><strong>Right to access:</strong> You can request to see what data we have about you.</li>
        <li><strong>Right to rectification:</strong> You can request that we correct any inaccurate information.</li>
        <li><strong>Right to erasure:</strong> You can request the deletion of your data at any time. If you wish to delete data that is stored in our backups, it will be automatically deleted after one month.</li>
      </ul>
      <p>You can contact us at <a href="mailto:info@init-together.dk">info@init-together.dk</a> to exercise your rights.</p>

      <h2>Cookies</h2>
      <p>We only use technically necessary cookies to ensure that our platform functions correctly. We do not use tracking or advertising cookies.</p>

      <h2>Donations</h2>
      <p>Donations are non-binding, and you decide whether to pay the amount based on the participant's performance. Init-Together ApS has no influence over the handling of donations, as they go directly from the payment providers (MobilePay, Stripe) to the NGO's bank account.</p>

      <h2>Changes to the privacy policy</h2>
      <p>We may update this privacy policy from time to time to reflect changes in our practices or applicable laws. We encourage you to review this page regularly to stay informed.</p>

      <h2>Contact us</h2>
      <p>If you have any questions or concerns regarding our privacy policy, you can contact us at:</p>
      <address>
        Init-Together ApS<br></br>
        <a href="mailto:info@init-together.dk">info@init-together.dk</a>
      </address>
    </>
  );
}

export const PrivacyPolicyContainer = () => {
  const { i18n } = useContext(I18nContext);
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const cb = (lng: string) => {
      setLanguage(lng);
    };
    i18n.on('languageChanged', cb);

    return () => {
      i18n.off('languageChanged', cb);
    }
  }, []);

  return (
    <>
      <TopBar />
      <Container className="pt-10 pb-10">
        <article className="prose">
          {language === 'da' ? <DefaultVersion /> : <EnglishVersion />}
        </article>
      </Container>
    </>
  );
}