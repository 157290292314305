import { ProfileCardStatsList } from '../ProfileCard/ProfileCardStatsList';
import { SponsorList } from '../Various/SponsorList';
import { useMemo, useState } from 'react';
import { Button } from '../UI/Button';
import { useParams } from "react-router-dom";
import { Container } from '../UI/Container';
import { useTranslation } from "react-i18next";
import { FancyAlert } from '../UI/FancyAlert';
import { ParticipantStat, Sponsor } from '../../../types/Participant';
import { Event } from '../../../types/Event';
import { useQuery } from '../../hooks/useQuery';
import dayjs from 'dayjs';
import ShareBox from '../Various/ShareBox';
import { Donate } from '../SVG/Donate';

export const ProfilePage = ({ name, subtitle, imgUrl, stats, sponsors, event, sponsorLink, backLink, isAnon }: {
  name: string,
  subtitle: string,
  imgUrl: string | null,
  stats: ParticipantStat[],
  sponsors: Sponsor[],
  event: Event | null,
  backLink?: string | null,
  sponsorLink?: string | null,
  isAnon?: boolean
}) => {
  const { eventSlug, orgSlug } = useParams();
  const { t } = useTranslation(['events', 'common', 'event_participants']);
  const [isShareBoxToggled, setIsShareBoxToggled] = useState(null as { x: number, y: number } | null);
  const query = useQuery();

  const closedForDonations = useMemo(() => {
    if (!event) {
      return false;
    }

    return dayjs(event.event_end_date).isBefore(dayjs());
  }, [event]);

  const shareBtnClick = (event: any) => {
    event.stopPropagation();
    const cursorX = event.clientX;
    const cursorY = event.clientY;
    setIsShareBoxToggled({ x: cursorX, y: cursorY })
  }

  return (
    <Container>
      <div className="mt-2 mb-10">
        <Button href={`/${orgSlug}/${eventSlug}/deltagere?offset=${query.get('offset') || 0}`} negative>
          &larr; {t('common:back')} <span className="hidden sm:inline">{t('event_participants:to_participants')}</span>
        </Button>
      </div>
      <div className="text-center md:text-left">
        <div className="flex flex-col md:flex-row gap-10 justify-between items-center pb-8 md:pb-0">
          <div className="flex flex-col md:mb-10 md:flex-row items-center justify-center md:justify-start">
            <div className={`rounded-full overflow-hidden h-40 w-40 flex items-center justify-center ${isAnon ? 'bg-white/20' : ''}`}>
              {imgUrl ? <img src={imgUrl} alt={`Billede af ${name}`} className={`${isAnon ? 'blur-md' : ''}`} /> : null}
            </div>
            <div className="mb-3 mt-4 lg:mt-0 md:pl-10">
              <h1 className="text-2xl md:text-5xl">{name}</h1>
              <p className="text-base lg:text-2xl mt-1">{subtitle}</p>
            </div>
          </div>
          <div className="rounded pb-6 pt-5 bg-gray-200/50 px-7 relative overflow-hidden">
            <Donate classes="absolute z-10 -bottom-14 -left-3 opacity-60 text-gray-300 w-[200px] h-[200px]"></Donate>
            <div className="header-font text-2xl relative z-20">Bliv sponsor</div>
            <div className="flex gap-2 mt-4 relative z-20">
              <Button className="" href={sponsorLink || ''}>{t('events:event_sponsor_button.default')}</Button>
              <Button onClick={shareBtnClick} className="flex items-center gap-2 py-[11px] md:py-3 pl-3 pr-3 md:pl-9 md:pr-3 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 md:absolute top-2.5 left-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                </svg>
                <span className="-ml-1 hidden md:inline">Del</span>
              </Button>
              {isShareBoxToggled && <ShareBox onCloseAction={() => setIsShareBoxToggled(null)} shareUrl={sponsorLink || ''} />}
            </div>
          </div>
        </div>


        {isAnon ? <FancyAlert extraClasses="mb-6" type="info" title={t('event_participants:anon_title')} description={t('event_participants:anon_desc')} /> : null}
        <div className="md:flex md:flex-row gap-5">
          <div className="flex flex-col items-end flex-grow justify-end">
            {stats && stats.length ? <div className="mb-6 w-full">
              <div className="text-base font-semibold mb-1">
                {t('event_participants:overview')}
              </div>
              <div className="flex flex-col lg:flex-row lg:gap-10">
                <ProfileCardStatsList items={stats.slice(0, (stats.length / 2))} />
                <ProfileCardStatsList items={stats.slice(stats.length / 2)} />
              </div>
            </div> : null}
            <div className="w-full">
              <div className="text-base font-semibold mb-1">
                {t('event_participants:sponsor_list')}
              </div>
              {sponsors && <SponsorList sponsors={sponsors} />}
            </div>
            {(event && sponsorLink && !closedForDonations) ? <div className="mt-4 text-sm text-center w-full">
              <p>{t('event_participants:would_you_like_to_sponsor')} <strong>{name}</strong> {t('event_participants:in_event')} <strong>{event.name}</strong>?</p>
              <Button className="mt-2" href={sponsorLink}>{t('events:become_sponsor')}</Button>
            </div> : null}
          </div>
        </div>
      </div>
    </Container>
  );
}