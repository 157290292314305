import { useEffect, useMemo, useState } from 'react';
import { EventParticipantsPage } from '../components/Layout/EventParticipantsPage';
import { ErrorPage } from '../components/Layout/ErrorPage';
import { NotFoundPage } from '../components/Layout/NotFoundPage';
import { useEvent } from '../hooks/useEvent';
import { sendRequest } from '../services/http';
import { formatParticipant } from '../services/participants';
import dayjs from 'dayjs';
import { Loading } from '../components/UI/Loading';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { TopBar } from '../components/Nav/TopBar';

export const EventPageParticipantsContainer = () => {
  const { event, eventLoaded, error } = useEvent({ withDocTitle: true });
  const [participants, setParticipants] = useState([]);
  const [participantsLoaded, setParticipantsLoaded] = useState(false);
  const setDocTitle = useDocumentTitle();


  const eventIsUpcomingOrActive = useMemo(() => event ? dayjs().isBefore(event.event_end_date) : false, [event]);

  useEffect(() => {
    const fetchData = () => {
      if (!event) {
        setParticipantsLoaded(true);
        return;
      }
      const cb = (response) => {
        if (response.data.length === 0) {
          setParticipantsLoaded(true);
          return;
        }
        const formattedList = response.data.sort((a, b) => {
          // sort on startnr, cast to number first
          return Number(a.startnr) - Number(b.startnr);
        }).map((participant, i) => {
          const item = formatParticipant(participant, event);

          // if event is not active, remove sponsor link
          if (!eventIsUpcomingOrActive) {
            delete item.sponsorLink;
          }

          return item;
        });
        setParticipants(formattedList);
        setParticipantsLoaded(true);
      }
      sendRequest({
        method: 'GET',
        endpoint: '/event/get/' + event.slug + '/participant/sponsor',
        callback: cb
      });
    }
    fetchData();
    window.Echo?.connect()
    window.Echo?.channel("events").listen(".newEvent", (event) => {
      if (event.update === 'success') {
        fetchData();
      }
    });

    setDocTitle(event?.name);
    return () => {
      window.Echo?.disconnect()
      setParticipantsLoaded(false);
      setParticipants([]);
    }
    // eslint-disable-next-line
  }, [event, eventLoaded]);

  const calcIsActive = useMemo(() => {
    if (!event) {
      return false;
    }
    const now = dayjs();

    return now.isAfter(event.event_start_date) && now.isBefore(event.event_end_date);
  }, [event]);

  if (!eventLoaded) {
    return <Loading />
  }

  if (error) {
    return <ErrorPage />
  }

  if (!event) {
    return <NotFoundPage />
  }

  return (
    <>
      <TopBar />
      <EventParticipantsPage
        mainImage={event.img}
        event={event}
        participants={participants}
        participantsLoaded={participantsLoaded}
        isActive={calcIsActive}
      />
    </>
  );
}