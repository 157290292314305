import dayjs from "dayjs"
import { TopBar } from "../Nav/TopBar"
import { Container } from "../UI/Container"
import { CheckOutForm } from "../Event/CheckOutForm"
import { STEP_CONTACT_INFO } from "../../variables/checkout_form"
import { CheckOutFormBusinessContact } from "../Event/CheckOutFormBusinessContact"
import { SponsorEventPageProps } from "../../../types/components/SponsorEventpage"
import { useMemo } from "react"
import { Event } from "../../../types/Event"
import { Participant } from "../../../types/Participant"
import { useTranslation } from "react-i18next"

const HeaderTitle = ({ event, title }: { event: Event; title: string }) => {
    const { t } = useTranslation(['sponsor']);
    
    return (
        <h1 className="lg:order-1 text-center lg:text-left">
            <span className="mb-3 block text-xl lg:text-2xl opacity-90">{t('sponsor:sponsor_type_page.become_business_sponsor')}</span>
            <span className="font-bold text-2xl lg:text-4xl">{title}</span>
            {event.event_start_date && <span className="block mt-3 opacity-90">d. {dayjs(event.event_start_date).format('DD-MM-YYYY')}</span>}
        </h1>
    );
}

const Header = ({ event, participant }: { event: Event; participant: Participant | null }) => {
    const title = useMemo(() => {
        if (participant !== null) {
            return participant?.name || '';
        }
        return event.name;
    }, [event, participant]);

    return (
        <Container className={`flex flex-col lg:flex-row items-center py-8 gap-4 ${participant ? 'justify-start' : 'justify-between'}`}>
            {participant ? (
                <div className={`mt-5 mb-1 lg:mt-0 lg:mb-0 rounded-full h-40 w-40 flex items-center justify-center overflow-hidden align-top ${participant.is_anonymized_data ? 'bg-white/20' : ''}`}>
                    <img src={participant.main_img_secondary ? `${participant.main_img_secondary}` : 'undefined'} alt={participant.name} className={`bg-white ${participant.is_anonymized_data ? 'blur-md' : ''}`} />
                </div>) : <img alt={'Billede' + title} className="rounded shadow-lg max-h-48 h-auto lg:order-2" src={`${event.img}/800x650`} />}
            <HeaderTitle event={event} title={title} />
        </Container>
    );
}

export const SponsorEventBusinessPage = ({ event, onSubmit, participant }: SponsorEventPageProps) => {

    return (
        <>
            <TopBar />
            <div className="bg-gradient-to-br from-primary-900 to-primary-700 text-white w-full ">
                <Header event={event} participant={participant ? participant : null} />
            </div>
            <Container topSpacing>
                <CheckOutForm
                    event={event}
                    submitForm={onSubmit}
                    canSignUpForMemberShip={false}
                    contentSlots={{ [STEP_CONTACT_INFO]: <CheckOutFormBusinessContact /> }}
                    participant={participant}
                />
            </Container>
        </>
    )
}