import { useState, useEffect } from "react";
import { Event } from "../../types/Event";
import { Participant } from "../../types/Participant";
import { AxiosResponse } from "axios";
import { sendRequest } from "../services/http";
import { formatParticipant } from "../services/participants";

export const useParticipants = (event: Event | null): { participants: Participant[], participantsLoaded: boolean } => {
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [participantsLoaded, setParticipantsLoaded] = useState(false);

    useEffect(() => {
        const cleanUp = () => {
            setParticipantsLoaded(false);
            setParticipants([]);
        }
        if (!event) {
            setParticipantsLoaded(true);
            return cleanUp;
        }

        const cb = (response: AxiosResponse) => {
            if (response.data.length === 0) {
                setParticipantsLoaded(true);
                return;
            }
            const formattedList = response.data
                .sort((a: Participant, b: Participant) => a.name.localeCompare(b.name))
                .map((participant: Participant) => formatParticipant(participant, event));
                
            setParticipants(formattedList);
            setParticipantsLoaded(true);
        }
        sendRequest({
            method: 'GET',
            endpoint: '/event/get/' + event.slug + '/participant/sponsor',
            callback: cb
        });

        return () => {
            setParticipantsLoaded(false);
            setParticipants([]);
        }
    }, [event]);

    return { participants, participantsLoaded };
}
