import { ParticipantStat } from '../../../types/Participant';
import { ProfileCardStat } from './ProfileCardStat';

export const ProfileCardStatsList = (props: {
    items: ParticipantStat[]
    className?: string
}) => {

    if(!props.items || props.items.length === 0) {
        return null;
    }

    return (
        <div className={`flex flex-col w-full ${props.className}`}>
            {props.items.map((item, index) => <ProfileCardStat key={index} label={item.label} value={item.value} />)}
        </div>
    );
}