import { Link } from "react-router-dom"

export const FaqCategoryOne = () => {
    return (
        <div>
            <h1 className="xl:text-3xl mb-2">Før løbet</h1>
            <p>
                Når begivenheden er på plads, og løberne er oprettet, er det tid til at finde sponsorer til løberne.
            </p>
            <ul className="list-disc md:text-lg flex flex-col gap-2 header-font pl-8 mt-5 prose">
                <li>Når løberne er oprettet i vores system, kan løberne finde deres profilside under <Link to="/events">Events</Link> og derefter trykke på det løbeevent/skole de hører til.</li>

                <li>Eleven kan finde sin profil enten ved at kende sit løbenummer eller pr. navn.</li>

                <li>Eleven kan dele sin profil ved at trykke del, her har man valgmulighederne at dele via e-mail, Facebook eller kopiere sit link, så man kan sende det med Messenger, SMS eller lignende.</li>

                <li>Mikrosponsorer kan sponsorere alle løberne ved at trykke på sponsorér. Sponsoren bliver derefter spurgt om at udfylde sine oplysninger, hvor mange penge man vil sponsorer, og om man vil sponsorere pr. km eller et fast beløb.</li>

                <li>Man kan vælge følgende betalingsmetoder:
                    <ul className="text-base pl-4 mt-2">
                        <li>MobilePay</li>
                        <li>Bankoverførsel</li>
                        <li>Betalingskort (til f.eks. udenlandske overførsler)</li>
                    </ul>
                </li>

                <li>Alle sponsorer får automatisk en mail der bekræfter deres hensigt om donation til løbet.</li>

                <li>Sponsorerne er ikke forpligtede til at betale det de har givet hensigtserklæring om og kan altid fortryde. Dette gør sig primært gældende ved sponsorer der har lavet slåfejl.</li>

                <li>Sponsorer har altid mulighed for at kontakte os på <a href="mailto:info@init-together.dk">info@init-together.dk</a> for at ændre beløsstørrelser eller ved andre spørgsmål vedrørende donationer.</li>
            </ul>
        </div>
    )
}