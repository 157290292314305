import { HighlightedParticipant } from "../Event/HighlightedParticipant";
import { ParticipantsTable } from "../Event/ParticipantsTable";
import { useMemo, useEffect, useState } from "react";
import dayjs from "dayjs";
import { CornerRibbon } from "../UI/CornerRibbon";
import { EventStatsBox as IEventStatsBox } from "../../../types/components/EventComponents";
import { Event } from "../../../types/Event";
import { Participant } from "../../../types/Participant";
import { CurrentStandingsStatsBox } from "../Event/CurrentStandingsStatsBox";
import { CurrentStandingsGroupStatsBox } from "../Event/CurrentStandingsGroupStatsBox";
import { formatParticipant } from '../../services/participants';
import { ThinLoadingBar } from "../UI/ThinLoadingBar";

type SortFieldsCycleEnum = "total_meters" | "total_rounds";

export const EventCurrentStandingsPage = (props: {
  event: Event
  participants: Participant[]
  org: string
  mainStats: IEventStatsBox
  typesStats: IEventStatsBox[]
  sortFieldsCycle: SortFieldsCycleEnum[]
  cycleInterval: number
}) => {
  const [sortField, setSortField] = useState<"total_meters" | "total_rounds">(props.sortFieldsCycle[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = props.sortFieldsCycle.indexOf(sortField);
      const nextIndex = currentIndex + 1 < props.sortFieldsCycle.length ? currentIndex + 1 : 0;
      setSortField(props.sortFieldsCycle[nextIndex]);
    }, props.cycleInterval);

    return () => clearInterval(interval);
  }, [sortField, props.sortFieldsCycle, props.cycleInterval]);

  const listItems = useMemo(() => {
    const sortedList = props.participants.sort((a, b) => b[sortField] - a[sortField]);

    return sortedList.map((p, i) => ({ ...formatParticipant(p), rank: i + 1 }));
  }, [props.participants, sortField]);

  const isLive = useMemo(() => {
    if (props.event.event_end_date && props.event.event_start_date) {
      return dayjs().isAfter(dayjs(props.event.event_start_date)) && dayjs().isBefore(dayjs(props.event.event_end_date));
    }
    return false;
  }, [props.event]);

  const eventStartDate = useMemo(() => {
    if (props.event.event_start_date) {
      return dayjs(props.event.event_start_date);
    }
    return null;
  }, [props.event]);

  const highlightedParticipant = useMemo(() => {
    if (listItems.length) {
      return listItems[0];
    }
    return null;
  }, [listItems]);

  const secondaryParticipant = useMemo(() => {
    if (listItems.length > 0) {
      return listItems[1];
    }
    return null;
  }, [listItems]);

  const tertiaryParticipant = useMemo(() => {
    if (listItems.length > 1) {
      return listItems[2];
    }
    return null;
  }, [listItems]);

  const retriggerLoadingBar = useMemo(() => sortField.length + Math.random(), [sortField]);

  return (
    <>
      <div className="fixed w-full h-full bg-gradient-to-tr from-primary-900/10 via-primary/10 to-primary-900/10"></div>
      {isLive && <CornerRibbon>LIVE</CornerRibbon>}
      <div className="w-full pt-[3vh] relative z-10 ">
        <div className="w-full flex-col flex px-2 py-3">
          <div className="text-center max-w-5xl m-auto negative-container">
            <h1 className="text-[21px] lg:text-[35px] tracking-wider text-primary">
              {props.event.name} {eventStartDate ? (<div className="text-2xl mt-1">{`d. ${eventStartDate.format('DD-MM-YYYY kl. HH:mm')}`}</div>) : null}
            </h1>
          </div>
          <div className="w-full flex flex-col xl:flex-row gap-5 lg:gap-8 items-center">
            <div className="w-full xl:order-2 mt-[3vh]">
              <div className="md:flex flex-row justify-between items-end gap-4 negative-container">
                <CurrentStandingsStatsBox totalDistanceCovered={props.mainStats.total_meters} totalDonations={props.mainStats.donations} laps={props.mainStats.rounds} />
                <div className="flex flex-row gap-x-3 md:max-w-md  lg:max-w-3xl flex-wrap-reverse md:justify-end">
                  {props.typesStats.map((stats, i) => <CurrentStandingsGroupStatsBox key={i} totalDistanceCovered={stats.total_meters} totalDonations={stats.donations} laps={stats.rounds} title={stats.name} />)}
                </div>
              </div>
              <ParticipantsTable dynamicCol={sortField} participants={listItems} limitItems={10} />
            </div>
            <div className={`w-full negative-container overflow-hidden justify-center lg:max-w-[800px] flex xl:w-[40%] items-center ${!listItems.length ? 'hidden' : ''}`}>
              {[secondaryParticipant, highlightedParticipant, tertiaryParticipant].map((p, i) => {
                if (!p) {
                  return null;
                }
                return <HighlightedParticipant key={i} name={p.name} main_img={p.big_thumb_img} rank={p.rank || 0} small={i !== 1} highlightedStat={sortField} highlightedStatValue={p[sortField]} />
              })}
            </div>
          </div>
        </div>
      </div>
      {props.sortFieldsCycle.length ? <div className="fixed bottom-0 left-0 right-0"><ThinLoadingBar iterations={1} reload={retriggerLoadingBar} loadTime={props.cycleInterval} /></div> : null}
    </>
  );
}
