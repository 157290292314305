import { Button } from '../UI/Button';
import { ProfileCardImage } from './ProfileCardImage';
import { ProfileCardStatsList } from './ProfileCardStatsList';
import { useTranslation } from "react-i18next";
import { ParticipantStat } from '../../../types/Participant';

export const ProfileCard = ({ name, imgSrc, stats, profileLink, sponsorLink, startnr, anonymized }: {
  name: string,
  imgSrc: string | null,
  stats: ParticipantStat[],
  profileLink?: string,
  sponsorLink?: string | null,
  startnr?: string | null,
  anonymized?: boolean
}) => {
  const { t } = useTranslation(['event_participants']);

  return (
    <div className="text-center md:text-left flex flex-col md:flex-row w-full p-4 md:p-6 lg:p-4 xl:p-6 shadow rounded md:items-end justify-center">
      <ProfileCardImage anonymized={anonymized} className={`m-auto mb-4 md:mr-6 lg:mr-2 xl:mr-6 md:mb-0 max-w-[200px] md:max-w-none md:w-4/12 xl:w-4/12 flex shrink-0 justify-center bg-primary/10 ${anonymized ? ' special-rounded' : ''}`} imgSrc={imgSrc}>
        <div className="py-6 px-1 w-40 mr-auto ml-auto max-w-full flex flex-col items-center">
          {profileLink ?
            <Button className="mb-2 block" href={profileLink} small negative transparency={0.8}>
              {t('event_participants:see_profile')}
            </Button> : null}
          {sponsorLink ? <Button className="block" href={sponsorLink} small negative transparency={0.8}>{t('event_participants:sponsor_this')}</Button> : null}
        </div>
      </ProfileCardImage>
      <div className="flex flex-col justify-end md:w-8/12 xl:w-8/12">
        <div className="md:py-1 pt-0 text-base font-semibold">
          {name}
        </div>
        {startnr ? <div className="md:hidden text-sm">{t('event_participants:start_nr', { value: startnr })}</div> : null}
        <ProfileCardStatsList className="hidden md:block rounded overflow-hidden" items={stats} />
      </div>
    </div>
  );
}