
export const FaqCategoryTwo = () => {
    return (
        <div>
            <h1 className="xl:text-3xl mb-4">Under løbet</h1>
            <div className="prose">
                <h3>Registrering af omgange</h3>
                <p>
                    Under løbet har vi en app der kan bruges på mobileenheder såvel som pc/laptops, til at registrere løbernes præstation.
                    <br /><br />
                    Det foregår ret simpelt ved at taste løbernes "løbenr." (startnummer) ind i en app for at registrere deres omgange. Information og login til denne app. Sendes pr. mail til den ansvarlige for løbet.
                </p>
                <h3>Live visning af løbet</h3>
                <p>
                    På eventets side kan man vælge det vi kalder "Live projektor visning". 
                    <br />
                    Her er det muligt under løbet at følge med i løbernes individuelle præstationer, se total antal løbne km, antal indsamlede kr. mm. 
                    <br />
                    Denne side kan f.eks. vises på storskærme, projektor mm. For de personer der kunne have interesse i at følge med i løbet.
                </p>
            </div>
        </div>
    )
}