import { useTranslation } from "react-i18next";

export const FormFootNote = () => {
    const { t } = useTranslation(['checkout_form']);
    
    return (
        <div className="relative text-xs mt-8 text-slate-500">
            <span className="absolute -left-2 top-0">*</span>
            {t('checkout_form:foot_note')}
        </div>
    );
}
