import { useState } from 'react'

import personalLink from '../../../images/screenshots/personal_link.webp'
import overview from '../../../images/screenshots/overview.webp'
import annonymizeData from '../../../images/screenshots/annonymize_data.webp'
import projectorView from '../../../images/screenshots/projector_view.webp'
import statsGraph from '../../../images/screenshots/stats_graph.webp'
import { Container } from '../../UI/Container'
import clsx from 'clsx'

const features = [
  {
    title: '1. Overblik over løbet',
    description:
      "I får jeres egen løbsside hvor realiserede donationer og resultater live opdateres. Her kan man også se hvilke projekter der samles ind til, og hvor meget der mangler, for at komme i mål.",
    image: overview,
  },
  {
    title: '2. Personlig profil med statistik og overblik.',
    description:
      "Løberen får et personligt link der kan deles på mail, sociale medier mm., hvorved potentielle sponsorer kan oprette sig. Her kan løberen og andre interessenter live følge med i løberens præstationer og donationer.",
    image: personalLink,
  },
  {
    title: '3. Projektorvisning med live-info fra løbet',
    description:
      "En sidevisning der er optimeret til en projektor, hvor I selv vælger hvilke resultater og/eller statistik I vil have vist. Al data live-opdateres selvfølgelig automatisk.",
    image: projectorView,
  },
  {
    title: '4. Anonymisering af data',
    description:
      'En måned efter løbet anonymiserer vi al data, ved at generere nye navne og slette al persondata. Resultaterne kan stadig findes, og derved vil I have mulighed for at slå rekorden fra jeres sidste løb, eller sammenligne jer med andre løb.',
    image: annonymizeData,
  },
  {
    title: '5. Grafer og statistikker',
    description:
      'Init-together platformen muliggør at vise lige netop den statistik, der er vigtig for jer. Dette kunne være hvilken time der er samlet flest penge ind, hvornår der er løbet hurtigst, hvilken pige der har løbet længst osv.',
    image: statsGraph,
  },
]

export default function PrimaryFeatures() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <section
      id="motivation"
      aria-labelledby="features-title"
      className="relative overflow-hidden bg-gradient-to-tr from-primary-900 via-primary-700 to-primary-900 pt-20 pb-28 sm:py-32"
    >
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2
            id="features-title"
            className="text-3xl tracking-tight text-white sm:text-4xl md:text-5xl font-normal"
          >
            Motiverer og skaber sammenhold
          </h2>
          <p className="mt-6 text-lg tracking-tight text-blue-100">
            Igennem live visualisering af resultater og donationer, opleves mere sammenhold og motivation, på jeres løb, på Init-Together platformen.
          </p>
        </div>
        <div id="tabgroup" className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0">

          <>
            <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
              <div id="tablist" className="relative z-10 flex space-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:space-y-1 lg:space-x-0 lg:whitespace-normal overflow-scroll">
                {features.map((feature, featureIndex) => (
                  <div
                    key={feature.title}
                    onClick={() => setSelectedIndex(featureIndex)}
                    className={clsx(
                      'group relative rounded-full cursor-pointer py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6',
                      {
                        'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10':
                          selectedIndex === featureIndex,
                        'hover:bg-white/10 lg:hover:bg-white/5':
                          selectedIndex !== featureIndex,
                      }
                    )}
                  >
                    <h3>
                      <div
                        className={clsx(
                          'font-display text-lg [&:not(:focus-visible)]:focus:outline-none',
                          {
                            'text-primary-600 lg:text-white':
                              selectedIndex === featureIndex,
                            'text-primary-100 hover:text-white lg:text-white':
                              selectedIndex !== featureIndex,
                          }
                        )}
                      >
                        <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                        {feature.title}
                      </div>
                    </h3>
                    <p
                      className={clsx('mt-2 hidden text-sm lg:block', {
                        'text-white': selectedIndex === featureIndex,
                        'text-blue-100 group-hover:text-white':
                          selectedIndex !== featureIndex,
                      })}
                    >
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="lg:col-span-7">
              {features.map((feature, featureIndex) => (
                <div id="tabpanel" key={feature.title} className={clsx({ 'hidden': selectedIndex !== featureIndex })}>
                  <div className="relative sm:px-6 lg:hidden">
                    <div className="absolute -inset-x-4 -top-[6.5rem] -bottom-[4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                    <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                      {feature.description}
                    </p>
                  </div>
                  <div className="relative mt-10 aspect-[1085/730] w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                    <img
                      src={feature.image}
                      alt=""
                      sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                    />
                  </div>
                </div>
              ))}
            </div>
          </>

        </div>
      </Container>
    </section>
  );
}
