import { useEffect, useState } from "react"
import { sendRequest } from "../services/http";
import { Ngo } from "../../types/Event";

export const useEventNgo = (props: {
    eventSlug: string | null
}) => {
    const [eventNgoList, setEventNgoList] = useState<Ngo[]>([]);
    const [eventNgoListResponseError, setEventNgoListResponseError] = useState<Error | null>(null);

    useEffect(() => {
        if (!props.eventSlug) {
            return
        }
        sendRequest({
            method: 'GET',
            endpoint: `event/get/${props.eventSlug}/ngo`,
            callback: (response) => setEventNgoList([response.data]),
            errorCallback: (error) => setEventNgoListResponseError(error),
        });
        return () => setEventNgoList([]);
    }, [props.eventSlug]);

    return { eventNgoList, eventNgoListResponseError }
}

