import { SponsorFormValues } from "../../types/components/SponsorParticipantPage";
import { PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_MOBILEPAY, PAYMENT_METHOD_TRANSFER } from "./constants";

export const STEP_DONATION_AMOUNT = 'donation_amounts';
export const STEP_CONTACT_INFO = 'contact_info';
export const STEP_PAYMENT_OPTIONS = 'payment_options';
export const STEP_SUBMIT = 'submit';

export const DEFAULT_VALUES: SponsorFormValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    payment_type: PAYMENT_METHOD_MOBILEPAY,
    donation_fixed: 0,
    donation_km: 0,
    signed_up_for_membership: false,
    accept_privacy_policy: false,
    where_are_you_from: 'dk',
    is_public_sponsor_name: true,
    is_public_sponsor_amount: true,
  };
  
  export const DEFAULT_PAYMENT_METHODS: string[] = [PAYMENT_METHOD_MOBILEPAY, PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_TRANSFER];
  export const INTERNATIONAL_PAYMENT_METHODS: string[] = [PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_MOBILEPAY];