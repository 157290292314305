import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_WARNING } from '../../variables/constants';

export const Notification = (props: {
  type: string;
  title?: string;
  static?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  text?: string;
  className?: string;
  duration?: number;
}) => {
  let typeClasses = '';
  switch (props.type) {
    case NOTIFICATION_TYPE_SUCCESS:
      typeClasses = 'bg-green-200 text-green-800';
      break;
    case NOTIFICATION_TYPE_ERROR:
      typeClasses = 'bg-red-200 text-red-800';
      break;
    case NOTIFICATION_TYPE_WARNING:
      typeClasses = 'bg-yellow-200 text-yellow-800';
      break;
    default:
      typeClasses = 'bg-blue-200 text-blue-800';
      break;
  }

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  }

  if (props.duration) {
    setTimeout(() => {
      handleClose();
    }, props.duration);
  }

  return (
    <div className={`px-4 py-3 rounded min-h-[40px] ${props.static ? 'relative' : 'fixed left-2 top-2 right-2 z-50 shadow-lg'} ${typeClasses} ${props.className}`} role="alert">
      {props.title ? (<><strong className="mb-2">{props.title}</strong><br /></>) : null}
      {props.text ? <span className="text-sm block leading-none sm:inline">{props.text}</span> : null}
      {props.children}
      {props.onClose ? <span className="absolute top-0 bottom-0 right-0 px-2 py-2" onClick={handleClose}>
        <svg className="fill-current h-6 w-6 text-fff-600/50 hover:scale-125 transition-all origin-center" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
      </span> : null}
    </div>
  );
}