import { EventGraphData } from "../../../types/components/EventComponents";
import { EventStatsGraphsConfig } from "../Event/EventStatsGraphsConfig";
import { Container } from "../UI/Container";
import { DataChart } from '../Various/DataChart';

const FullscreenIcon = () => {
  return (
    <div className="opacity-50 hover:opacity-100 transition-opacity">
      <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="currentColor" viewBox="0 0 24 24"><path d="M21.414 18.586l2.586-2.586v8h-8l2.586-2.586-5.172-5.172 2.828-2.828 5.172 5.172zm-13.656-8l2.828-2.828-5.172-5.172 2.586-2.586h-8v8l2.586-2.586 5.172 5.172zm10.828-8l-2.586-2.586h8v8l-2.586-2.586-5.172 5.172-2.828-2.828 5.172-5.172zm-8 13.656l-2.828-2.828-5.172 5.172-2.586-2.586v8h8l-2.586-2.586 5.172-5.172z" /></svg>
    </div>
  )
}

export const EventStatsPage = ({ graphs, visibleGraphs, hiddenGraphIndexes, setHiddenGraphIndexes, setFullscreenGraphIndex }: {
  graphs: EventGraphData[]
  visibleGraphs: EventGraphData[]
  hiddenGraphIndexes: number[]
  setHiddenGraphIndexes: (indexes: number[]) => void
  setFullscreenGraphIndex: (index: number) => void
}) => {

  if (graphs.length === 0) {
    return <Container>Whoops! No statistics for this page yet.</Container>
  }

  return (
    <>
      <Container>
        <EventStatsGraphsConfig
          graphs={graphs.map(item => item.title ? item.title : '(missing title)')}
          disabledIndexes={hiddenGraphIndexes}
          submitChangedIndexArray={setHiddenGraphIndexes}
        />
        <div className={`grid grid-cols-1 justify-center gap-5 pt-5 ${visibleGraphs.length === 1 ? '' : 'lg:grid-cols-2'}`}>
          {visibleGraphs.map((chart, i) => {
            return (
              <div key={i} className={`mb-10 ${visibleGraphs.length === 1 ? 'max-w-3xl m-auto' : ''}`}>
                <DataChart
                  title={chart.title}
                  data={chart.data}
                  type={chart.type === 'bar' ? 'bar' : 'line'}
                >
                  <div className="relative translate-y-3 px-3 flex flex-row justify-end h-0 z-10">
                    <button title="Fullscreen" className="block" onClick={() => setFullscreenGraphIndex(i)}>
                      <FullscreenIcon />
                    </button>
                  </div>
                </DataChart>
              </div>
            )
          })}
        </div>
      </Container>
    </>
  );
}