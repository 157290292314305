import { useState, useEffect, useMemo } from 'react';
import { sendRequest } from '../services/http';
import { useParams } from "react-router-dom";
import { useDocumentTitle } from './useDocumentTitle';
import { Event } from '../../types/Event';
import { AxiosError, AxiosResponse } from 'axios';
import { EventStatsBox } from '../../types/components/EventComponents';
import { buildImageLink } from '../services/assets';
import { EventType } from '../variables/enum';

interface ReturnType {
  event: Event | null,
  eventLoaded: boolean,
  error: AxiosError | Error | null,
  setUseEventDocTitle: (value: boolean) => void,
  eventSlug?: string
  fetchEventStats: () => void,
  eventStats: EventStatsBox
  eventGroupStats: EventStatsBox[]
}

const defaultStatsObject = {
  total_meters: 0,
  rounds: 0,
  donations: 0,
  sponsor_count: 0
}

export const useEvent = (props?: { withDocTitle?: boolean; givenEventSlug?: string }): ReturnType => {
  const { eventSlug } = useParams();
  const [event, setEvent] = useState<Event | null>(null);
  const [eventLoaded, setEventLoaded] = useState(false);
  const [error, setError] = useState<Error | AxiosError | null>(null);
  const [useEventDocTitle, setUseEventDocTitle] = useState((props && props.withDocTitle) || false);
  const setDocumentTitle = useDocumentTitle();
  const [overallStats, setOverallStats] = useState<EventStatsBox>(defaultStatsObject);
  const [typesStats, setTypesStats] = useState<EventStatsBox[]>([]);

  const selectedEventSlug = useMemo(() => {
    if (props && props.givenEventSlug) {
      return props.givenEventSlug;
    }
    return eventSlug;
  }, [props, eventSlug]);

  const fetchEventStats = () => {
    if (event === null) {
      return;
    }

    sendRequest({
      method: 'GET',
      endpoint: `stat/${event.slug}`,
      callback: (response) => {
        if (response.data.length === 0) {
          return;
        }
        const data = response.data[0];
        setOverallStats({ rounds: data.rounds, total_meters: data.total_meters, donations: data.donations, sponsor_count: data.sponsor_count });
        setTypesStats([...data.types]);
      },
      errorCallback: (error) => {
        console.log(error);
      }
    });
  }

  /**
   * Fetch event data
   */
  useEffect(() => {
    const cleanup = () => {
      setEvent(null);
      setEventLoaded(false);
      setError(null);
    }

    if (!selectedEventSlug) {
      setEventLoaded(true);
      return () => cleanup();
    }
    const cb = (response: AxiosResponse) => {
      const data = response.data;
      if (data.length === 0) {
        setEventLoaded(true);
        return;
      }
      setEvent({
        ...data,
        img: data.cloudflare_img_uuid ? buildImageLink(data.cloudflare_img_uuid) : data.img,
        // event_type: EventType.EVENT_FIXED_LENGTH // TODO remove this when we have the event type in the API
      });
      setEventLoaded(true);
    }
    const errorCb = (error: Error | AxiosError) => {
      console.log(error);
      setError(error);
      setEventLoaded(true);
    }
    sendRequest({
      method: 'GET',
      endpoint: '/event/get/' + selectedEventSlug,
      callback: cb,
      errorCallback: errorCb
    });

    return () => cleanup();
  }, [selectedEventSlug]);

  /**
   * Get event stats
   */
  useEffect(() => {
    const cleanup = () => {
      setOverallStats(defaultStatsObject);
      setTypesStats([]);
    }
    if (event === null) {
      return () => cleanup();
    }
    fetchEventStats();
    return () => cleanup();
  }, [event]);

  /**
   * Set document title if enabled
   */
  useEffect(() => {
    if (useEventDocTitle && event) {
      setDocumentTitle(event.name);
    }
    return () => setUseEventDocTitle(false);
  }, [useEventDocTitle, event]);

  return {
    event,
    eventLoaded,
    error,
    setUseEventDocTitle,
    eventSlug,
    fetchEventStats,
    eventStats: overallStats,
    eventGroupStats: typesStats
  };
}