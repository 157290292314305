import { useState, useEffect, SyntheticEvent } from 'react';
import { DefaultInfoBoxProps } from '../../../types/components/InfoBox';
import fallbackImg from '../../images/flbk-run.jpg';
import { InfoBoxDonations } from '../Various/InfoBoxDonations';


export const EventStageBox = ({ image, title, subtitle, finalGoalAmount, currentGoalAmount, children, focused, btnProps, description, extraClasses, fallbackImage }: DefaultInfoBoxProps) => {
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (currentGoalAmount && finalGoalAmount && (currentGoalAmount > finalGoalAmount) && !focused) {
            setIsFocused(true);
        } else if (typeof focused !== 'undefined' && focused) {
            setIsFocused(focused);
        }
    }, [currentGoalAmount, finalGoalAmount, focused]);

    let containerClassNames = "";
    if (isFocused) {
        containerClassNames += "";
    } else {
        containerClassNames += " ";
    }

    const handleImageError = (event: SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.src = fallbackImage || fallbackImg;
    }

    return (

        <div className={`relative transition-all flex flex-col overflow-hidden rounded-lg shadow-lg ${containerClassNames} ${extraClasses}`}>
            {(focused === true) ? <span className="absolute top-3 left-3 shadow-lg  z-10 px-3 py-1 header-font rounded-full text-sm font-medium bg-green-100 text-green-800">Igangværende</span> : null}
            <div className={`block flex-shrink-0  bg-white ${(!image && focused === true) && 'min-h-[20px]'}`}>
                {image ? <img className="w-full h-64 object-cover" src={`${image}/480x380`} onError={handleImageError} alt={title} /> : null}
            </div>
            <div className="flex text-left flex-col bg-white px-6 py-5">
                <div className="">


                    <div className="mt-1 block">
                        <div className="flex items-center justify-between">
                            <h4 className="text-xl font-semibold text-gray-900">
                                {title}
                            </h4>
                            {subtitle ? <p className="text-sm text-gray-700 flex flex-col gap-0.5">{subtitle}</p> : null}


                        </div>
                        <p className="text-xs text-gray-700 flex flex-col gap-0.5 font-normal">d. 11-01-2023</p>
                        <div className="mt-2 text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: description as any }}></div>
                    </div>
                    {children}
                    <div className="mt-8 flex flex-row items-center justify-evenly gap-4">
                        <div className="flex flex-col gap-1  header-font text-center ">
                            <span className="font-bold leading-none text-2xl  text-center">8</span>
                            <span className="text-sm leading-none">Sponsorer</span>
                        </div>
                        <InfoBoxDonations currentAmount={420} />
                    </div>
                </div>
            </div>
        </div>
    );
}