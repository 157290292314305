import { Button } from "../UI/Button";
import { SponsorLevel } from "../../variables/enum";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const SponsorLevelButton = ({ sponsorLevel, onClick, focused, disabled }: {
    sponsorLevel: SponsorLevel
    onClick?: () => void
    disabled?: boolean
    focused?: boolean
}) => {
    const { t } = useTranslation(['sponsor']);
    
    const dynamicClasses: string = useMemo(() => {
        if (focused) {
            return 'shadow-lg text-primary border-primary/50 border-4';
        }
        return 'text-gray-700';
    }, [focused]);

    const opaqueClasses: string = useMemo(() => {
        if (disabled && !focused) {
            return 'opacity-50 text-gray-500';
        }

        return '';
    }, [disabled, focused]);
    
    return (
        <div className={`h-auto w-full  overflow-hidden rounded-lg bg-white shadow text-center hover:shadow-lg transition-all cursor-pointer hover:text-primary ${dynamicClasses} ${opaqueClasses}`} onClick={onClick}>
            <div className="px-4 pt-5 pb-2 flex flex-col items-center">
                {sponsorLevel === SponsorLevel.EVENT ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-[100px] h-[80px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                    </svg>

                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[100px] h-[70px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                )}
            </div>
            <div className="px-4 pb-7">
                <h3 className="mb-2 text-xl lg:text-2xl leading-none font-medium ">
                    {sponsorLevel === SponsorLevel.EVENT ? t('sponsor:sponsor_type_page.the_event') : t('sponsor:sponsor_type_page.a_participant') }
                </h3>
                <p className="mb-4">
                    {sponsorLevel === SponsorLevel.EVENT ? t('sponsor:sponsor_type_page.the_event_subtext') : t('sponsor:sponsor_type_page.a_participant_subtext')}
                </p>
                {!disabled ? <Button>{ t('sponsor:sponsor_type_page.choose_btn_label') }</Button> : null}
            </div>
        </div>
    );
}