import { useTranslation } from "react-i18next";
import { ParticipantStat } from '../../../types/Participant';
import { Button } from "../UI/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ShareBox from "../Various/ShareBox";

export const ProfileCardListItem = ({ name, imgSrc, first_stat, profileLink, sponsorLink, second_stat, startnr, anonymized }: {
  name: string,
  imgSrc?: string | null,
  first_stat?: ParticipantStat,
  second_stat?: ParticipantStat | null,
  profileLink?: string,
  sponsorLink?: string | null,
  startnr?: string | null,
  anonymized?: boolean
}) => {
  const { t } = useTranslation(['event_participants']);
  const [isShareBoxToggled, setIsShareBoxToggled] = useState(null as { x: number, y: number } | null);
  const navigate = useNavigate();

  const itemClickAction = () => {
    if (profileLink) {
      navigate(profileLink);
    }
  }

  const shareBtnClick = (event: any) => {
    event.stopPropagation();
    const cursorX = event.clientX;
    const cursorY = event.clientY;
    setIsShareBoxToggled({ x: cursorX, y: cursorY })
  }

  return (
    <>
    {isShareBoxToggled && <ShareBox onCloseAction={() => setIsShareBoxToggled(null)} shareUrl={sponsorLink || profileLink || ''} />}
    <div className="block hover:bg-gray-50 overflow-hidden bg-white shadow sm:rounded-md cursor-pointer" onClick={itemClickAction}>
      <div className="flex flex-col md:flex-row gap-6 md:gap-1 items-center px-4 py-4 sm:px-6 group">
        <div className="flex min-w-0 flex-1 items-center">
          {imgSrc ? (<div className="overflow-hidden rounded-full w-12 h-12"><img src={imgSrc} alt={name} className={`max-w-full block ${anonymized ? 'blur-sm' : ''}`} /></div>) : null}
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 header-font">
            <div>
              <p className="truncate text-sm font-medium text-primary ">{name}</p>
              <p className="flex items-center text-sm text-gray-500">
                <span className="truncate">{t('event_participants:start_nr', { value: startnr })}</span>
              </p>
            </div>
            <div className="hidden md:block">
              {first_stat ? <p className="text-sm text-gray-700">
                {first_stat.label}: {first_stat.value}
              </p> : null}
              {second_stat ? <p className="text-sm text-gray-700">
                {second_stat.label}: {second_stat.value}
              </p> : null}
            </div>
          </div>
        </div>
        <div className="flex gap-2 lg:opacity-80 group-hover:opacity-100 items-center">
          {sponsorLink && <Button href={sponsorLink}>{t('event_participants:sponsor_this')}</Button>}
          {profileLink && <Button href={profileLink}>{t('event_participants:see_profile')}</Button>}
          <Button onClick={shareBtnClick} className="flex items-center gap-2 py-[11px] md:py-3 pl-3 pr-3 md:pl-9 md:pr-3 relative">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 md:absolute top-2.5 left-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
            </svg>
            <span className="-ml-1 hidden md:inline">Del</span>
          </Button>
        </div>
      </div>
    </div>
    </>
  );
}