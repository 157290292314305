export const Loading = ({ widthClass, heightClass, className }: {
  widthClass?: string;
  heightClass?: string;
  className?: string;
}) => {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <div className={`spinner-border animate-spin inline-block border-2 rounded-full ${widthClass ? widthClass : 'w-2'} ${heightClass ? heightClass : 'h-8'}`} role="status" title="Vi arbejder på sagen...">
      </div>
    </div>
  );
}
export const LoadingOverlay = () => {
  // TODO make a spinner
  return (
    <div className="fixed flex flex-col justify-center items-center left-0 top-0 w-full h-full z-50 bg-black/80 text-white text-center py-4 text-lg">
      <div className="spinner-border animate-spin inline-block w-2 h-8 border-2 rounded-full mb-2" role="status">
        
      </div>
      <span className="animate-pulse">Øjeblik, vi behandler din forspørgsel...</span>
    </div>
  );
}