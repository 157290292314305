export const CssTriangle = ({ className, color }: {
  className?: string,
  color: string
}) => {
  return (
    <div className={`${className}`}>
      <div className="w-9 overflow-hidden inline-block">
        <div className={` h-4 w-4 bg-${color} rotate-45 transform origin-bottom-left`}></div>
      </div>
    </div>
  );
}