import { useEffect, useMemo, useState } from "react"
import { TopBar } from "../components/Nav/TopBar"
import { Container } from "../components/UI/Container"
import { Event } from "../../types/Event"
import { sendRequest } from "../services/http"
import { InfoBoxGrid } from "../components/Various/InfoBoxGrid"
import { Button } from "../components/UI/Button"
import { Trans, useTranslation } from "react-i18next"

export const AboutPageContainer = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const { t } = useTranslation(['content', 'common']);

  useEffect(() => {
    const cb = (response: any) => {
      // TODO handle errors
      setEvents(response.data);
    }
    sendRequest({
      method: 'GET',
      endpoint: '/event/last3',
      callback: cb
    });
  }, []);

  const infoboxes = useMemo(() => {
    return events.map((event: Event) => {
      return {
        event: event
      }
    })
  }, [events]);

  return (<>
    <TopBar />
    <Container>
      <div className="py-12">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2
            id="results-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            {t('about_page.title')}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            {t('about_page.desc')}
            <br></br><br></br>
          </p>
          <h2
            id="results-title"
            className="font-display text-2xl tracking-tight text-slate-900 sm:text-2xl"
          >
            {t('about_page.title_2')}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            <Trans i18nKey="content:about_page.desc_2">
              Init-together platformen har over de sidste 2 år og 9 løb indsamlet donationer for næsten 2 million kr. i samarbejde med <a href="https://lovespring.dk/" className="mt-4 text-lg tracking-tight text-slate-700 underline">LoveSpring</a>. Donationerne er blevet brugt til at sikre rent drikkevand til nogle af de hårdest ramte områder i Afrika.
            </Trans>
          </p>
          <a href="https://lovespring.dk/" className="mt-4 underline">{t('common:see_more_at', { url: 'www.lovespring.dk' })}</a>
        </div>

      </div>

    </Container>
    {events.length > 0 && (
      <div className="md:text-center py-12 gap-5 flex flex-col">
        <div>
          <h2 className="text-3xl">{t('about_page.latest_x_events', { count: 3 })}</h2>
          <Button href="/" className="mt-7 mb-10">{t('about_page.see_all_events')}</Button>
        </div>
        <InfoBoxGrid items={infoboxes} infoBoxType="event" />
      </div>
    )}

  </>)
}